import { Components, Theme } from '@mui/material/styles';

export default function Tooltip(theme: Theme): Components<Theme> {
  return {
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 0,
        PopperProps: {
          style: {
            zIndex: 1600,
          },
        },
      },
      styleOverrides: {
        tooltip: {
          ...theme.typography.subtitle2,
          zIndex: 1600,
        },
      },
    },
  };
}
