// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyCallofduty: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-callofduty"><g id="Property 1=sport-callofduty"><path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M27.2857 15.4373L16.2496 8.25908L5.00017 15.4373V11.2279C5.24407 10.9887 16.0563 4 16.0563 4L27.2857 11.2279V15.4373ZM16.1354 23.7143C18.8356 23.7143 21.433 23.14 23.6672 21.7408L16.2495 16.9159L8.64878 21.7666C10.876 23.1477 13.4621 23.7143 16.1354 23.7143ZM27.2855 21.5479V24.2544C24.1045 26.6815 20.2092 28 16.1354 28C12.0815 28 8.18016 26.6832 5 24.2656V24.0941V19.8856C5.24389 19.6464 16.0561 12.6569 16.0561 12.6569L27.2855 19.8856V21.5479Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyCallofduty = React.memo(BetbyCallofduty);
MemoBetbyCallofduty.displayName = 'BetbyCallofduty';
export default MemoBetbyCallofduty;