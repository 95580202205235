import request, { requestWithZod } from '.';
export enum PromotionTypeEnum {
  CASINO = 'casino',
  SPORT = 'sport',
}

export enum ActivityStatusEnum {
  NOT_VALID = -1,
  WAIT = 0,
  WAIT_SETTLE = 1,
  ONGOING = 2,
  OUTDATED = 3,
  CANCELED = 4,
  FINISHED = 5,
}

export interface TUserActivity {
  // id: number;
  activityId: string;
  activityTitle: string;
  activityType: number;
  userId: string;
  currentInProgressIndex: number;
  /**
   * 当前活动的状态
   * 参考UserActivityStatusEnum
   * NOT_VALID(-1), 不可选
   * WAIT(0),
   * WAIT_SETTLE(1), 此状态特别用户free spin activity, 表示用户已获得了free spin的机会但还没有将对应的free spin全部用完
   * ONGOING(2),
   * OUTDATED(3),
   * CANCELED(4),  * reload bonus专用, 用户取消了bonus
   * FINISHED(5);
   */
  status: ActivityStatusEnum;
  thumbUrl?: string;
  startTime?: number;
  endTime: number;
  /**
   * 当前是用于thursday second free spin activity, 当valid为false时会返回倒计时（秒级别）
   */
  valid: boolean;
  countdownTime: number;
  /**
   * free spin 活动中当前冻结的不可取的金额, 需要wager amount * 5倍才能取出
   */
  frozenAmount: string;
  /**
   * 用户已下注的金额
   */
  currentWagerAmount: string;
  /**
   * 用户需要下注的总金额
   */
  targetWagerAmount: string;
  /**
   * free spin活动中当enable时一个跳转到的gameId,目前先只考虑hub88下的游戏
   */
  freeBetTargetGameId: string;
  /**
   * deposit bonus中用户充值的金额
   */
  depositAmount: string;
  /**
   * deposit bonus奖励的总金额
   */
  totalBonusAmount: string;
  /**
   * 用户当前可领取的deposit bonus金额
   */
  availableBonusAmount: string;
  /**
   * 用户已领取过的deposit bonus金额
   */
  claimedAmount: string;
  /**
   * deposit bonus用户已领取过的次数
   */
  claimedTimes: number;
  /**
   * deposit bonus 具体进度
   */
  recordId: string;
  freeSpinCount: number;
  sequences: {
    startWagerAmount: string;
    endWagerAmount: string;
    /**
     * 0: 不满足claim, 1: 满足claim但未claim, 2: 已claim
     */
    status: number;
  }[];
}

/**
 * https://moonclub.atlassian.net/wiki/spaces/BW/pages/161644545
 */

export interface IPromotionCardParams {
  id?: string;
  title: string;
  desc: string;
  type: PromotionTypeEnum | string;
  status: 1 | 2; // 1表示登录时banner返回 2表示未登录banner返回
  displayImageIndex: number;
  cardImage: string;
  images?: Array<string>;
  startTime: string;
  endTime: string;
  detailContent?: string;
  featured: boolean;
  slug: string;
  games: Array<{
    gameId: string;
  }>;
  isBonus?: boolean; // 是否似乎freespin或者 reload
}
interface IPromotionQueryResponse {
  hasNext: boolean;
  nextId?: number;
  prevId?: number;
  promotionDTOList: Array<IPromotionCardParams>;
}

export interface IAcvitityItem {
  id: string;
  description: string;
  title: string;
  thumbUrl: string;
  endTime: string;
  type: ActivityTypeEnum;
  selected?: boolean;
}

export interface IPromotionQueryReqDto {
  page: number;
  pageSize?: number;
  activity?: string | boolean;
  searchTitle?: string;
}

/**
 * public enum ActivityTypeEnum {
    NORMAL_ACTIVITY(0),
    THURSDAY(1),
    SATURDAY(2),
    THURSDAY_DEPOSIT_ACTIVITY(3),
    THURSDAY_FIRST_FREE_SPIN(4),
    THURSDAY_SECOND_FREE_SPIN(5)
}
 */
export enum ActivityTypeEnum {
  NORMAL_ACTIVITY = 0,
  THURSDAY = 1,
  SATURDAY = 2,
  RELOAD = 3,
  THURSDAY_FIRST_FREE_SPIN = 4,
  THUDSDAY_SECOND_FREE_SPIN = 5,
  WELCOME_BONUS = 6,
}

export const getPromotionList = (data: IPromotionQueryReqDto) => {
  return request({
    method: 'post',
    url: '/api/mc-api/promotion/select',
    data,
  }).then((res) => {
    return res.data;
  });
};

export const getPromotionDetail = (data: { slug: string }) => {
  return request({
    method: 'post',
    url: '/api/mc-api/promotion/detail',
    data,
  }).then((res) => {
    return res.data;
  });
};

export const getAvailableActivities = (): Promise<{
  selected: boolean;
  activities: Array<IAcvitityItem>;
}> => {
  return request({
    method: 'post',
    url: '/api/mc-api/activity/available',
  }).then((res) => {
    return res.data;
  });
};

export const selectActivitiy = (data: { activityId: string }) => {
  return request({
    method: 'post',
    url: '/api/mc-api/activity/select',
    data,
  }).then((res) => {
    return res.data;
  });
};

export const cancelActivitiy = (data: { activityId: string }) => {
  return request({
    method: 'post',
    url: '/api/mc-api/activity/unselect',
    data,
  }).then((res) => {
    return res.data;
  });
};

/** https://moonclub.atlassian.net/wiki/spaces/BW/pages/161644545#%E7%94%A8%E6%88%B7%E5%BD%93%E5%89%8D%E5%9C%A8%E9%98%9F%E5%88%97%E4%B8%AD%E7%9A%84%E6%B4%BB%E5%8A%A8
 * 用户当前在队列中的活动

 * @param data 此接口只需用户的uid，request body 可以为{}

 * @returns 
 */
export const queryActivityQueue = () => {
  return request<{ activities: TUserActivity[] }>({
    method: 'post',
    url: '/api/mc-api/activity/queue',
    data: {},
  }).then((res) => {
    return res.data;
  });
};

export const enableSubActivity = (recordId: string) => {
  return request({
    method: 'post',
    url: '/api/mc-api/activity/enable',
    data: {
      recordId,
    },
  }).then((res) => {
    return res.data;
  });
};

export const cancelSubActivity = (recordId: string) => {
  return request({
    method: 'post',
    url: '/api/mc-api/activity/cancel',
    data: {
      recordId,
    },
  }).then((res) => {
    return res.data;
  });
};

export const claimSubActivity = (recordId: string) => {
  return request({
    method: 'post',
    url: '/api/mc-api/activity/claim',
    data: {
      recordId,
    },
  }).then((res) => {
    return res.data;
  });
};
