import { saveLoginHistory } from '@/apis/profile';
import APIFactory from '@/apis';
import * as Sentry from '@sentry/nextjs';
import request from '@/apis';

// Generated by https://quicktype.io

export interface TIPInfoResponse {
  timezone: string;
  ip: string;
  countryName: string;
  city: string;
  countryCode: string;
  country: string;
}

export const fetchGeoInfo = async (
  recordLoginHistory = true,
): Promise<{
  browser?: string;
  location?: string;
  timezone?: string;
  ip?: string;
  countryName?: string;
  city?: string;
  countryCode?: string;
}> => {
  let ipInfo: TIPInfoResponse;
  try {
    ipInfo = (
      await request({
        method: 'POST',
        url: '/api/mc-api/address/get-country-from-ip',
      })
    ).data as unknown as TIPInfoResponse;
    console.log('ipInfo', ipInfo);
  } catch (e) {
    console.error(e);
    Sentry.captureMessage('The call to fetchGeoInfo API failed.', e);
    return;
  }

  if (ipInfo?.countryCode) {
    Sentry.captureMessage('Get Ip/Country Info Is Empty!!!!', {});
  }

  const data = {
    browser: navigator.userAgent,
    location: `${ipInfo?.city}, ${ipInfo?.countryName}`,
    timezone: ipInfo?.timezone,
    ip: ipInfo?.ip,
    countryName: ipInfo?.countryName,
    city: ipInfo?.city,
    countryCode: ipInfo?.countryCode || ipInfo?.country,
  };

  if (recordLoginHistory) {
    await saveLoginHistory(data);
  }

  return data;
};
