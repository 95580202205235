// utils/staticAsset.ts
const getDeploymentPrefix = (): string => {
  const deployEnv = process.env.NEXT_PUBLIC_DEPLOY_ENV;

  switch (deployEnv) {
    case 'development':
      return '';
    case 'staging':
      return '/staging';
    case 'beta':
      return '/beta';
    case 'production':
    default:
      return '';
  }
};

export const getStaticAsset = (assetPath: string): string => {
  const deploymentPrefix = getDeploymentPrefix();
  return `${deploymentPrefix}${assetPath}`;
};

export const getCurrencyLogo = (currency: string): string => {
  return getStaticAsset(`/assets/images/currency_logos/${currency}.svg`);
};
