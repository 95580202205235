import { CSSObject, Theme } from '@mui/material/styles';

export const appSystemLayoutPaddingMixins = (theme: Theme): CSSObject => ({
  padding: theme.spacing(4, 2, 6, 2),
  [theme.breakpoints.down('xl')]: {
    padding: theme.spacing(3, 2, 6, 2),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 2, 5, 2),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1, 2, 4, 2),
  },
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(0, 2, 3, 2),
  },
});
