import { GLOBAL_MODALS, globalModals } from "@/state/globalSettings";
import { useAtom } from "jotai";
import { useRouter } from "next/router";
import { useEffect } from "react";

enum ModalEnums {
  LOGIN_REGISTRATION = 'sign',
  WALLET = 'wallet',
}

enum MDATA_AUTH {
  LOGIN = 'login',
  REGISTER = 'register',
}

enum MDATA_WALLET {
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
}

type ModalQueryParams =
  | { modal: ModalEnums.LOGIN_REGISTRATION; mdata: MDATA_AUTH }
  | { modal: ModalEnums.WALLET; mdata: MDATA_WALLET };


const isModalQueryParams = (params: any): params is ModalQueryParams => {
    if (params.modal === ModalEnums.LOGIN_REGISTRATION) {
      return Object.values(MDATA_AUTH).includes(params.mdata);
    }
    if (params.modal === ModalEnums.WALLET) {
      return Object.values(MDATA_WALLET).includes(params.mdata);
    }
    return false;
  };
  
const useModalControlFromQueryString = () => {
    const router = useRouter();
    const { modal, mdata } = router.query;
    const [, setModals] = useAtom(globalModals);
    const showModal = (modalType: ModalEnums, mdata: string) => {
      if(modalType === ModalEnums.LOGIN_REGISTRATION) {
        switch(mdata) {
          case MDATA_AUTH.LOGIN:
            setModals({
              [GLOBAL_MODALS.LOGIN]: true,
            });
            break;
          case MDATA_AUTH.REGISTER:
            setModals({
              [GLOBAL_MODALS.REGISTER]: true,
            });
            break;
        }
      } else if(modalType === ModalEnums.WALLET) {
        // TODO, require login firstly
        switch(mdata) {
          case MDATA_WALLET.DEPOSIT:
            setModals({
              [GLOBAL_MODALS.WALLET]: true,
            });
            break;
          case MDATA_WALLET.WITHDRAWAL:
            setModals({
              [GLOBAL_MODALS.WALLET]: true,
            });
            break;
        }
      }
    };

    useEffect(() => {
      if (isModalQueryParams({ modal, mdata })) {
        showModal(modal as ModalEnums, mdata as string);
      }
    }, [modal, mdata]);
  };

  export default useModalControlFromQueryString