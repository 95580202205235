// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonCryptoTokenLtc: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-crypto-token-ltc"><path d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z" fill="#1C3F9B" /><path fillRule="evenodd" clipRule="evenodd" d="M32.918 54.1278L25.9735 77.4541H25.9736L25.9735 77.4544H67.6321L70.9839 66.4412H42.7436L47.8019 49.8752L55.1824 47.7665L57.0978 41.5417L49.6617 43.7843L58.5342 14.7268H44.6481L34.6495 48.3118L26.9312 50.6395L25.0159 56.3855L32.918 54.1278Z" fill="#FFFDFA" /></SvgBase>);
};
const MemoMoonCryptoTokenLtc = React.memo(MoonCryptoTokenLtc);
MemoMoonCryptoTokenLtc.displayName = 'MoonCryptoTokenLtc';
export default MemoMoonCryptoTokenLtc;