import { EThirdPartyLoginType, thirdPartyLogin } from '@/apis/login';
import React from 'react';
import dynamic from 'next/dynamic';
import Loading from '@/components/ui/Loading';
import { isTelegramMiniApp } from '@/utils/checkRuntime';
import { useSDKContext, useLaunchParams } from '@tma.js/sdk-react';
import useUserLoginLogout from '@/hooks/biz/useUserLoginLogout';
import { useAtom } from 'jotai';
import { Box, Grid } from '@mui/material';
import TgUserHead from '@/components/system/Auth/TelegramMiniAppAutoLogin/TgUserHead';
import { GLOBAL_MODALS, globalModals } from '@/state/globalSettings';
import CatchError from '@/components/system/CacthError';
import { useMount } from 'ahooks';
import { useQuery } from '@tanstack/react-query';
import busEvents from '@/helper/busEvents';

const BindEmailModal = dynamic(() => import('../BindEmailModal'), {
  ssr: false,
  loading: () => <Loading size={'50px'} />,
});

export const TelegramMiniAppAutoLogin = () => {
  const { login } = useUserLoginLogout();
  const launchParams = useLaunchParams();
  const { initData: telegramInitDataObject } = launchParams;
  const { initResult } = useSDKContext();

  const { initDataRaw } = initResult || {};
  const [modals, setModals] = useAtom(globalModals);

  // it's just for mini app, when app mounted should logout
  const tg_local_app_name = process.env.NEXT_PUBLIC_MOON_TG_LOCAL_APP_NAME;
  const enableThirdPartyLogin = isTelegramMiniApp && !!initDataRaw;
  const { refetch } = useQuery({
    enabled: enableThirdPartyLogin,
    queryKey: ['thirdPartyLogin'],
    queryFn: () => {
      return thirdPartyLogin({
        platform: EThirdPartyLoginType.TELEGRAM,
        appName: tg_local_app_name,
        needAuthData: initDataRaw,
      });
    },
    onSuccess: (data) => {
      if (data?.data?.name) {
        login({
          ...data?.data,
          telegramAccount: telegramInitDataObject,
          thirdPartyAuthCode:initDataRaw,
          thirdPartyLoginType:EThirdPartyLoginType.TELEGRAM
        });
      }
    },
  });

  useMount(() => {
    busEvents.on('autoLoginToTelegram', () => {
      refetch();
    });
  });

  return (
    <Box>
      <Grid display={'flex'} gap={2} alignItems={'center'}>
        <TgUserHead />
      </Grid>
    </Box>
  );
};

export default CatchError(TelegramMiniAppAutoLogin);
