import { useEffect, useRef } from 'react';
import NProgress from 'nprogress';
import { useRouter } from 'next/router';
import busEvents from '@/helper/busEvents';
import { useMount } from 'ahooks';

let noProgress = false;

const scrollMapping = {};

const useNProgress = () => {
  const router = useRouter();
  const currentUrlRef = useRef(router.asPath.split('#')[0].split('?')[0]);
  useMount(() => {
    const handleRouteStart = (url, options) => {
      currentUrlRef.current = url;
      if (!noProgress) {
        NProgress.start();
      }
    };
    const handleRouteDone = (url, options) => {
      // if (scrollMapping[url]) {
      //   setTimeout(() => {
      //     document.documentElement.scrollTop = scrollMapping[url];
      //   }, 500);
      // }
      NProgress.done();
    };

    router.events.on('routeChangeStart', handleRouteStart);
    router.events.on('routeChangeComplete', handleRouteDone);
    router.events.on('routeChangeError', (e) => {
      NProgress.done();
    });

    busEvents.on('noProgressBar', () => {
      noProgress = true;
      setTimeout(() => {
        noProgress = false;
      }, 500);
    });

    // document.addEventListener('scroll', (e) => {
    //   const scrollElement = document.documentElement;
    //   scrollMapping[currentUrlRef.current] = scrollElement.scrollTop;
    // });
  });
};

export default useNProgress;
