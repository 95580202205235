import WrappedModal from '@/components/ui/WrappedModal';
import { userLoginGeoInfoState } from '@/state/userInfo';
import { Box, Modal, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';
import MoonLogo from '../MoonLogo';
import { BLOCKED_COUNTRIES } from '@/constants/biz';
import React, { useEffect } from 'react';
import { isLocalDev } from '@/utils/checkRuntime';

const ModalComp = ({ countryName }: { countryName: string }) => {
  const [t] = useTranslation();

  const ref = React.useRef(null);

  useEffect(() => {
    if (!ref.current) return;
    const timer = setInterval(() => {
      const el = document.querySelector('.country_block_modal_content');
      if (!el) {
        clearInterval(timer);
        location.reload();
      }
    }, 500);
    return () => clearInterval(timer);
  }, []);

  return (
    <Stack
      gap={2}
      alignItems={'center'}
      ref={ref}
      className="country_block_modal_content"
    >
      <Box>
        {t(
          `We are sorry, but we are unable to accept players from the {{countryName}} at this time.`,
          {
            countryName: countryName || 'region',
          },
        )}
      </Box>
    </Stack>
  );
};

const BlockedCountryModal = () => {
  const [t] = useTranslation();
  const { country, countryName } = useAtomValue(userLoginGeoInfoState);

  if (isLocalDev) return null;

  if (BLOCKED_COUNTRIES.includes(country)) {
    return (
      <WrappedModal
        open
        title={t('Region unavailable')}
        sxs={{
          closeSX: {
            display: 'none',
          },
        }}
        onChange={(open) => {
          console.log('~log~', open);
        }}
      >
        <ModalComp countryName={countryName} />
      </WrappedModal>
    );
  }
};

export default BlockedCountryModal;
