import { Components, Theme } from '@mui/material/styles';

export default function Pagination(theme: Theme): Components<Theme> {
  return {
    MuiPagination: {
      styleOverrides: {
        root: {
          '& .MuiPagination-ul': {
            justifyContent: 'center',
          },
        },
      },
    },
  };
}
