// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyPolitics: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-politics"><g id="Property 1=sport-politics"><path id="Vector" d="M11 18C11 14.81 11 12.81 11 12C11 10.7849 11.7254 10 13 10H14L16 15L18 10C17.8169 10 18.1503 10 19 10C20.2746 10 21 10.7849 21 12V18H26C26.5523 18 27 18.4477 27 19C27 19.5523 26.5523 20 26 20V28H24V20H8V28H6V20C5.44772 20 5 19.5523 5 19C5 18.4477 5.44772 18 6 18H11ZM16 8C14.8954 8 14 7.10457 14 6C14 4.89543 14.8954 4 16 4C17.1046 4 18 4.89543 18 6C18 7.10457 17.1046 8 16 8Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyPolitics = React.memo(BetbyPolitics);
MemoBetbyPolitics.displayName = 'BetbyPolitics';
export default MemoBetbyPolitics;