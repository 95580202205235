// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonSocialGithub: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-social-github"><path fillRule="evenodd" clipRule="evenodd" d="M11.9694 2C6.46483 2 2 6.46483 2 11.9694C2 16.3731 4.87462 20.104 8.78899 21.4495C9.27829 21.5107 9.46177 21.2049 9.46177 20.9602C9.46177 20.7156 9.46177 20.104 9.46177 19.2477C6.70948 19.8593 6.09786 17.9021 6.09786 17.9021C5.66972 16.7401 4.99694 16.4343 4.99694 16.4343C4.07951 15.8226 5.0581 15.8226 5.0581 15.8226C6.0367 15.8838 6.58716 16.8624 6.58716 16.8624C7.50459 18.3914 8.91132 17.9633 9.46177 17.7187C9.52294 17.0459 9.82875 16.6177 10.0734 16.3731C7.87156 16.1284 5.5474 15.2722 5.5474 11.419C5.5474 10.318 5.91437 9.46177 6.58716 8.72783C6.52599 8.54434 6.15902 7.50459 6.70948 6.15902C6.70948 6.15902 7.56575 5.91437 9.46177 7.19878C10.2569 6.95413 11.1131 6.89297 11.9694 6.89297C12.8257 6.89297 13.682 7.01529 14.4771 7.19878C16.3731 5.91437 17.2294 6.15902 17.2294 6.15902C17.7798 7.50459 17.4128 8.54434 17.3517 8.78899C17.9633 9.46177 18.3914 10.3792 18.3914 11.4801C18.3914 15.3333 16.0673 16.1284 13.8654 16.3731C14.2324 16.6789 14.5382 17.2905 14.5382 18.208C14.5382 19.5535 14.5382 20.5933 14.5382 20.9602C14.5382 21.2049 14.7217 21.5107 15.211 21.4495C19.1865 20.104 22 16.3731 22 11.9694C21.9388 6.46483 17.474 2 11.9694 2Z" fill="#F0F0F0" /></SvgBase>);
};
const MemoMoonSocialGithub = React.memo(MoonSocialGithub);
MemoMoonSocialGithub.displayName = 'MoonSocialGithub';
export default MemoMoonSocialGithub;