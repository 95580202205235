// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonCryptoTokenDot: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-crypto-token-dot"><circle cx="48" cy="48" r="48" fill="#FFFDFA" /><path d="M47.9999 19.5954C36.2541 19.5954 26.6541 29.1389 26.6541 40.9413C26.6541 43.313 27.0493 45.6283 27.7835 47.8872C28.2917 49.4119 29.9858 50.2589 31.567 49.7507C33.0917 49.2425 33.9388 47.5483 33.4305 45.9672C32.8093 44.2166 32.527 42.353 32.5835 40.4895C32.8093 32.5272 39.247 25.9766 47.2093 25.5813C56.0752 25.1295 63.4164 32.1883 63.4164 40.9413C63.4164 49.1295 56.9788 55.8495 48.9035 56.3013C48.9035 56.3013 45.9105 56.4707 44.4423 56.6966C43.7082 56.8095 43.1435 56.9225 42.7482 56.9789C42.5788 57.0354 42.4093 56.866 42.4658 56.6966L42.9741 54.2119L45.7411 41.4495C46.0799 39.8683 45.0635 38.2872 43.4823 37.9483C41.9011 37.6095 40.3199 38.626 39.9811 40.2072C39.9811 40.2072 33.3176 71.266 33.2611 71.6048C32.9223 73.186 33.9388 74.7672 35.5199 75.106C37.1011 75.4448 38.6823 74.4283 39.0211 72.8472C39.0776 72.5084 39.9811 68.386 39.9811 68.386C40.6588 65.2236 43.2564 62.9083 46.3058 62.5131C46.9835 62.4001 49.6376 62.2307 49.6376 62.2307C60.6493 61.3836 69.3458 52.1789 69.3458 40.9413C69.3458 29.1389 59.7458 19.5954 47.9999 19.5954Z" fill="#08060B" /><path d="M49.5247 68.7247C47.6047 68.3294 45.6847 69.5153 45.2894 71.4918C44.8941 73.4118 46.08 75.3318 48.0564 75.7271C49.9764 76.1224 51.8964 74.9365 52.2917 72.96C52.687 70.9835 51.5011 69.12 49.5247 68.7247Z" fill="#E6007A" /></SvgBase>);
};
const MemoMoonCryptoTokenDot = React.memo(MoonCryptoTokenDot);
MemoMoonCryptoTokenDot.displayName = 'MoonCryptoTokenDot';
export default MemoMoonCryptoTokenDot;