// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonVerification: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-verification"><path d="M20 3C20.5304 3 21.0391 3.21071 21.4142 3.58579C21.7893 3.96086 22 4.46957 22 5V19C22 19.5304 21.7893 20.0391 21.4142 20.4142C21.0391 20.7893 20.5304 21 20 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H20ZM17 15H7C6.73478 15 6.48043 15.1054 6.29289 15.2929C6.10536 15.4804 6 15.7348 6 16C6 16.2652 6.10536 16.5196 6.29289 16.7071C6.48043 16.8946 6.73478 17 7 17H17C17.2652 17 17.5196 16.8946 17.7071 16.7071C17.8946 16.5196 18 16.2652 18 16C18 15.7348 17.8946 15.4804 17.7071 15.2929C17.5196 15.1054 17.2652 15 17 15ZM10 7H8C7.49542 6.99984 7.00943 7.19041 6.63945 7.5335C6.26947 7.87659 6.04284 8.34685 6.005 8.85L6 9V11C5.99984 11.5046 6.19041 11.9906 6.5335 12.3605C6.87659 12.7305 7.34684 12.9572 7.85 12.995L8 13H10C10.5046 13.0002 10.9906 12.8096 11.3605 12.4665C11.7305 12.1234 11.9572 11.6532 11.995 11.15L12 11V9C12.0002 8.49542 11.8096 8.00943 11.4665 7.63945C11.1234 7.26947 10.6532 7.04284 10.15 7.005L10 7ZM17 11H14C13.7451 11.0003 13.5 11.0979 13.3146 11.2728C13.1293 11.4478 13.0178 11.687 13.0028 11.9414C12.9879 12.1958 13.0707 12.4464 13.2343 12.6418C13.3979 12.8373 13.6299 12.9629 13.883 12.993L14 13H17C17.2549 12.9997 17.5 12.9021 17.6854 12.7272C17.8707 12.5522 17.9822 12.313 17.9972 12.0586C18.0121 11.8042 17.9293 11.5536 17.7657 11.3582C17.6021 11.1627 17.3701 11.0371 17.117 11.007L17 11ZM10 9V11H8V9H10ZM17 7H14C13.7348 7 13.4804 7.10536 13.2929 7.29289C13.1054 7.48043 13 7.73478 13 8C13 8.26522 13.1054 8.51957 13.2929 8.70711C13.4804 8.89464 13.7348 9 14 9H17C17.2652 9 17.5196 8.89464 17.7071 8.70711C17.8946 8.51957 18 8.26522 18 8C18 7.73478 17.8946 7.48043 17.7071 7.29289C17.5196 7.10536 17.2652 7 17 7Z" fill="white" fillOpacity="1" /></SvgBase>);
};
const MemoMoonVerification = React.memo(MoonVerification);
MemoMoonVerification.displayName = 'MoonVerification';
export default MemoMoonVerification;