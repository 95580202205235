// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyRainbowSix: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-rainbow-six"><g id="Property 1=sport-rainbow_six"><path id="Vector" d="M10.0017 7.91123C10.453 3.54511 16.0728 4.01472 16.0753 4.01472C21.9878 3.78114 22 7.91123 22 7.91123V10.9985H16.968L16.9948 8.56625C15.9362 7.62464 15.1142 8.53977 15.1142 8.53977L15.1411 12.6193C15.2728 11.8077 17.8412 11.8607 17.8412 11.8607C21.5756 11.8607 22 13.9775 22 13.9775V24.3304C21.3097 28.6171 15.8289 27.9644 15.8289 27.9644C9.73589 28.0969 10.0017 24.2004 10.0017 24.2004V7.91123ZM13.933 17.4932V25.0249H13.7778V25.43H14.1841V25.578H15.4032V25.4131H15.5658V21.4955C15.5658 21.4955 16.0066 20.9255 17.0262 21.0516V19.0044C17.0262 19.0044 17.9128 18.5556 19.5556 18.5556V16.683C19.5556 16.683 18.9448 15.8267 17.5262 16.7825C17.5262 16.7825 15.9057 17.6557 15.5633 16.6757H14.226V16.8067H13.9256V17.0833H13.7803V17.3889H13.928L13.933 17.4932ZM15.7778 19.6571C16.3986 19.104 16.6827 19.5256 16.6805 19.5279L16.779 19.4349C16.7469 19.2717 16.4834 19.2264 16.4834 19.2264L16.4766 18.9725H16.7331L16.8889 19.2423V20.7339H15.7778V19.6571Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyRainbowSix = React.memo(BetbyRainbowSix);
MemoBetbyRainbowSix.displayName = 'BetbyRainbowSix';
export default MemoBetbyRainbowSix;