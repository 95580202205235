import React, {
  forwardRef,
  PropsWithChildren,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Tooltip, TooltipProps, Typography } from '@mui/material';
import { isEqual, throttle } from 'lodash-es';
import { styled, SxProps, Theme } from '@mui/material/styles';

const StyledTypography = styled(Typography)(({ theme }) => ({
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: 'inherit',
}));

const ForwardStyledTypography = forwardRef(
  (props: PropsWithChildren & { sx: SxProps }, ref: RefObject<any>) => (
    <StyledTypography {...props} ref={ref} />
  ),
);

const TruncatedTextTooltip = React.memo(
  (props: Omit<TooltipProps & { textSx?: SxProps<Theme> }, 'children'>) => {
    const { title: text, textSx, ref, ...restTooltipProps } = props;
    const [isTruncated, setIsTruncated] = useState(false);
    const textRef = useRef<typeof Typography>(null);
    const observer = useRef(null);

    const handleResize = throttle(() => {
      if (textRef.current) {
        const isOverflowing =
          (textRef.current as unknown as HTMLSpanElement)?.offsetWidth <
          (textRef.current as unknown as HTMLSpanElement)?.scrollWidth;
        setIsTruncated(isOverflowing);
      }
    }, 200);

    useEffect(() => {
      if (textRef.current) {
        observer.current = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting) {
              const isOverflowing =
                (textRef.current as unknown as HTMLSpanElement)?.offsetWidth <
                (textRef.current as unknown as HTMLSpanElement)?.scrollWidth;
              setIsTruncated(isOverflowing);
            }
          },
          { threshold: 1 },
        );
        observer.current.observe(textRef.current);
        handleResize();
      }

      window.addEventListener('resize', handleResize);

      return () => {
        if (observer.current && textRef.current) {
          observer.current.unobserve(textRef.current);
        }
        window.removeEventListener('resize', handleResize);
      };
    }, [text]);

    return isTruncated ? (
      <Tooltip title={text} {...restTooltipProps}>
        <ForwardStyledTypography ref={textRef as any} sx={textSx}>
          {text}
        </ForwardStyledTypography>
      </Tooltip>
    ) : (
      <ForwardStyledTypography ref={textRef as any} sx={textSx}>
        {text}
      </ForwardStyledTypography>
    );
  },
  isEqual,
);

TruncatedTextTooltip.displayName = 'TruncatedTextTooltip';

export default TruncatedTextTooltip;
