// @ts-nocheck
import {
  APP_FIXED_HEADER_HEIGHT,
  APP_FIXED_MOBILE_BOTTOM_BAR_HEIGHT,
} from '@/constants/global';
import { isServer } from '@tanstack/react-query';
import { betslipZIndex } from '@/constants/zIndex';

export const BRAND_ID = process.env.NEXT_PUBLIC_BETBY_BRAND_ID;

export interface IBTRendererProps {
  jwtToken: string;
  locale: string;
  onLogin?: () => void;
  onRegister?: () => void;
  onSessionRefresh?: () => void;
  onTokenExpired?: () => Promise<string>;
  onRecharge?: () => void;
  isMobile?: boolean;
}

export const initializeBTRenderer = ({
  jwtToken,
  locale,
  onLogin,
  onRegister,
  onSessionRefresh,
  onTokenExpired,
  onRecharge,
  isMobile,
}: IBTRendererProps) => {
  if (isServer) return;
  if (!window?.BTRenderer) {
    console.error('BTRenderer is not loaded');
    return;
  }

  const params = {
    brand_id: BRAND_ID,
    token: !jwtToken ? null : jwtToken,
    themeName: 'moonclub',
    lang: locale,
    target: document.getElementById('betby'),
    betSlipOffsetTop: APP_FIXED_HEADER_HEIGHT + 5,
    betSlipOffsetBottom: isMobile ? APP_FIXED_MOBILE_BOTTOM_BAR_HEIGHT : 0,
    betslipZIndex: betslipZIndex,
    // cssUrls: [
    //   'https://link.to.your.fonts.ﬁle.css',
    //   'https://link.to.some.extra.fonts.css',
    // ],
    stickyTop: APP_FIXED_HEADER_HEIGHT,
    onRouteChange: function (...parms) {
      console.log('onRouteChange', parms);
    },
    onLogin: (...params) => {
      onLogin();
    },
    onRegister,
    onRecharge,
    onTokenExpired: () => onTokenExpired?.(),
    onSessionRefresh: () => onSessionRefresh?.(),
    onBetSlipStateChange: function () {},
  };
  return new BTRenderer().initialize(params);
};

export default initializeBTRenderer;
