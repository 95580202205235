import { Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { JSXElementConstructor } from 'react';
import { APP_FIXED_HEADER_HEIGHT } from '@/constants/global';

export const AppToolBar = styled<
  JSXElementConstructor<{
    children?: React.ReactNode;
  }>
>(Toolbar)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1),
  justifyContent: 'space-between',
  transition: theme.transitions.create('background'),
  background: theme.palette.grey[1000],
  gap: theme.spacing(1),
  overflowX: 'auto',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-end',
    height: `${APP_FIXED_HEADER_HEIGHT}px`,
    paddingRight: '28px',
    paddingLeft: '28px',
  },
}));
