// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const CasinoOriginals: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="15" height="20" viewBox="0 0 15 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-casino-originals"><path fillRule="evenodd" clipRule="evenodd" d="M12.2 5.10098C13.3 6.60098 14.7 8.40098 14.9 11.201C14.9 11.201 15 11.801 15 12.001C15 15.901 11.7 19.201 7.5 19.201C3.4 19.201 0 16.001 0 12.001V11.501C0.1 7.40098 2.2 6.30098 2.3 6.30098C2.4 6.20098 2.6 6.20098 2.7 6.30098C2.8 6.40098 2.9 6.50098 2.9 6.60098C2.9 6.70098 3.3 9.10098 5 9.50098C5 8.40098 5 6.80098 5.4 5.50098C6 3.40098 7.5 1.30098 9.3 0.100976C9.4 0.000976466 9.6 0.000976562 9.8 0.000976562C9.9 0.100977 10 0.200976 10 0.400976C10 2.30098 11 3.60098 12.1 5.00098C12.2 5.00098 12.2 5.00098 12.2 5.10098ZM4.5 15.201C4.9 17.001 6.1 18.401 8.2 17.901C11.2 17.201 11 13.701 9.8 11.801C8.6 9.80098 8.5 8.20098 8.5 7.70098C7.8 8.60098 6.6 10.901 6.9 12.401C7.2 13.601 6.2 13.901 5.3 12.101C4.9 12.301 4.2 13.801 4.5 15.201Z" fill="currentColor" fillOpacity="0.7" /></SvgBase>);
};
const MemoCasinoOriginals = React.memo(CasinoOriginals);
MemoCasinoOriginals.displayName = 'CasinoOriginals';
export default MemoCasinoOriginals;