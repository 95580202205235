// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonCryptoTokenEth2: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="96" height="97" viewBox="0 0 96 97" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-crypto-token-eth2"><rect y="0.110565" width="96" height="96.5307" rx="48" fill="#627EEA" /><path d="M47.959 17.0023V39.7967L67.1182 48.4065L47.959 17.0023Z" fill="white" fillOpacity="0.602" /><path d="M47.9581 17.0023L28.7988 48.4065L47.9581 39.7967V17.0023Z" fill="white" /><path d="M47.959 63.1771V78.6657L67.1294 51.9935L47.959 63.1771Z" fill="white" fillOpacity="0.602" /><path d="M47.9581 78.6657V63.1771L28.7988 51.9935L47.9581 78.6657Z" fill="white" /><path d="M47.959 59.5909L67.1182 48.4072L47.959 39.7975V59.5909Z" fill="white" fillOpacity="0.2" /><path d="M28.7988 48.4072L47.9581 59.5909V39.7975L28.7988 48.4072Z" fill="white" fillOpacity="0.602" /></SvgBase>);
};
const MemoMoonCryptoTokenEth2 = React.memo(MoonCryptoTokenEth2);
MemoMoonCryptoTokenEth2.displayName = 'MoonCryptoTokenEth2';
export default MemoMoonCryptoTokenEth2;