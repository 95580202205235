// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyBikes: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-bikes"><g id="Property 1=sport-bikes"><path id="Vector" d="M26.6 15.8L27.2 14.5C27.5 13.9 27.7 13.2 27.7 12.5C27.7 11.7 27.4 11 26.9 10.4L24.7999 8C24.2999 7.5 23.6 7.3 22.9 7.4C22 7.7 21.4 8.6 21.6 9.5L18.5 8.5C17.4 8.2 16.1 8.4 15 9L13 10L8.69995 9.2C8.49995 9.2 8.29995 9.1 8.09995 9.1C6.39995 9.1 4.99995 10.5 4.99995 12.2C4.99995 12.6 5.29995 13 5.69995 13.1L11.3 14.5C14.1 15.2 16 17.6 16.2 20.4V20.6H12.3C12.4 20.3 12.4 20 12.4 19.7C12.4 17.2 10.3 15.1 7.79995 15.1C5.29995 15.1 3.19995 17.2 3.19995 19.7C3.19995 22.2 5.29995 24.3 7.79995 24.3C9.29995 24.3 10.6 23.6 11.5 22.5H17.2C17.2 22.5 17.2 22.5 17.3 22.5C17.8 22.5 18.2 22 18.2 21.5L18 20.4C17.8 16.8 15.3 13.7 11.7 12.8L6.99995 11.5C7.19995 11.1 7.59995 10.9 8.09995 10.9C8.19995 10.9 8.29995 10.9 8.39995 10.9L13 11.9C13.2 11.9 13.4 11.9 13.6 11.8L15.9 10.6C16.6 10.2 17.3 10.2 18.1 10.4L22.7999 11.9C23.4999 12.1 24.2 11.5 24 10.8L23.6 9.3L25.7 11.7C25.9 11.9 26 12.2 26 12.6C26 13 25.9 13.4 25.7 13.8L25 15.3C24.8 15.3 24.6 15.3 24.4 15.3C21.9 15.3 19.7999 17.4 19.7999 19.9C19.7999 22.4 21.9 24.5 24.4 24.5C26.9 24.5 29 22.4 29 19.9C28.9 18.1 28 16.6 26.6 15.8ZM7.69995 22.5C6.19995 22.5 4.89995 21.3 4.89995 19.7C4.89995 18.2 6.09995 16.9 7.69995 16.9C9.29995 16.9 10.5 18.1 10.5 19.7C10.5 21.3 9.19995 22.5 7.69995 22.5ZM24.2999 22.5C22.7999 22.5 21.5 21.3 21.5 19.7C21.5 18.2 22.6999 16.9 24.2999 16.9C25.7999 16.9 27.1 18.1 27.1 19.7C27.1 21.3 25.7999 22.5 24.2999 22.5Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyBikes = React.memo(BetbyBikes);
MemoBetbyBikes.displayName = 'BetbyBikes';
export default MemoBetbyBikes;