import AppBarTools from '@/components/system/AppBar/ToolBar';
import Menus from '@/components/system/AppMenus';
import AuthModal from '@/components/system/Auth/AuthModal';
import CatchError from '@/components/system/CacthError';
import { APP_FIXED_HEADER_HEIGHT } from '@/constants/global';
import {
  appSidebarMobileOpenState,
  appSidebarOpenState,
} from '@/state/globalSettings';
import {
  isLoggedIn,
  isTemporaryLoginWithoutToken,
  userInfoState,userInfoMetaData
} from '@/state/userInfo';

import useTheme from '@/theme/useTheme';
import { isTelegramMiniApp } from '@/utils/checkRuntime';
import {
  ConstructionOutlined,
  NavigateBefore,
  NavigateNext,
} from '@mui/icons-material';
import { Box, Divider, IconButton } from '@mui/material';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import MoonLogo from '@/components/biz/MoonLogo';
import AppSideBarDrawerHeader from '@/components/system/AppBar/SideBarHeader';
import { useAtom, useAtomValue, atom, getDefaultStore } from 'jotai';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import RewardsDropdown from './SideBarHeader/rewards';
import { AppHeaderBar, AppMain, Drawer, ProfileBox } from './styles';
import { EThirdPartyLoginType } from '@/apis/login';
import TelegramMiniAppAutoLogin from '@/components/system/Auth/TelegramMiniAppAutoLogin';
import { COOKIE_KEY_APP_SIDE_BAR_OPEN_STATUS } from '@/constants/cookieStorageKeys';
import Scrollbar from '@/components/ui/Scrollbar';
import { cookie } from '@/utils/cookies';
import PromotionsBanner from './promotionsBanner';
import {
  GLOBAL_MODALS,
  globalModals,
  modalsState,
} from '@/state/globalSettings';
import Loading from '@/components/ui/Loading';
import { store } from '@/state/store';


const WalletWidget = dynamic(
  () => import('@/components/features/wallet/WalletWidget'),
  {
    ssr: false,
  },
);

const WalletWidgetPlaceholder = dynamic(
  () => import('@/components/features/wallet/WalletWidgetPlaceholder'),
  {
    ssr: false,
  },
);

const BindEmailModal = dynamic(
  () => import('@/components/system/Auth/BindEmailModal'),
  {
    ssr: false,
    loading: () => <Loading size={'50px'} />,
  },
);
 
export interface AppBarProps extends MuiAppBarProps {
  appSidebarOpened?: boolean;
  children?: React.ReactNode;
}

export function AppDrawerBar(props: AppBarProps) {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [appSidebarOpen, setAppSidebarOpen] = useAtom(appSidebarOpenState,{store:store});
  const [mobileOpen, setMobileOpen] = useState(false);

  const { pathname } = useRouter();
  const isSportsPage = pathname === '/sports';
  const isTemporaryLogin = useAtomValue(isTemporaryLoginWithoutToken,{store:store});
  const { userMetaData } = useAtomValue(userInfoMetaData,{store:store});

  const loggedIn = useAtomValue(isLoggedIn,{store:store});
  const [modals, setModals] = useAtom(globalModals,{store:store});

  const handleMobileDrawerToggle = () => {
    if (!isMobile) return;

    setMobileOpen(!mobileOpen);
  };

  const [sidebarMobileOpenState, setSidebarMobileOpenState] = useAtom(
    appSidebarMobileOpenState,
  );

  const toggleDrawerOpen = () => {
    setAppSidebarOpen(!appSidebarOpen);
    cookie.set(COOKIE_KEY_APP_SIDE_BAR_OPEN_STATUS, !appSidebarOpen);
  };

  const handleHeaderIconClick = () => {
    !isMobile ? toggleDrawerOpen() : setSidebarMobileOpenState(false);
  };

  
  // useEffect(() => {
  //   if (isUndefined(appSidebarOpened)) return;
  //   setAppSidebarOpen(appSidebarOpened);
  // }, [appSidebarOpened, setAppSidebarOpen]);

  // const shouldShowBGBanner = route === '/' || route === '/casino';

  // const homepageAppMainSX: SxProps =
  //   isHomepage && !loggedIn
  //     ? {
  //         backgroundImage: `url(${getStaticAsset(
  //           '/assets/images/banner/homepage_banner.jpg',
  //         )})`,
  //         backgroundSize: '100% auto',
  //         [theme.breakpoints.up('lg')]: {
  //           backgroundSize: '1280px auto',
  //         },
  //         backgroundRepeat: 'no-repeat',
  //         backgroundPosition: 'top center',
  //         [theme.breakpoints.down('sm')]: {
  //           backgroundSize: 'auto 433px',
  //         },
  //       }
  //     : {};
  // const backgroundColor = isRushPage
  //   ? theme.palette.bg.main
  //   : theme.palette.grey[900];

  return (
    <>
      <AppHeaderBar position="fixed" open={Boolean(appSidebarOpen)}>
        {/*<ToggleColorMode />*/}
        {isMobile ? null : (
          <Box
            sx={(theme) => ({
              position: 'absolute',
              top: '20px',
              left: '2px',
              transform: 'translateX(-50%)',
              zIndex: 100,
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
            })}
            onClick={toggleDrawerOpen}
          >
            <IconButton
              sx={{
                height: '28px',
                width: '28px',
                background: theme.palette.bg.secondary,
                color: theme.palette.primary.contrastText,
                '&:hover': {
                  background: theme.palette.bg.hover,
                },
              }}
            >
              {appSidebarOpen ? (
                <NavigateBefore
                  style={{
                    fontSize: '28px',
                  }}
                />
              ) : (
                <NavigateNext
                  style={{
                    fontSize: '28px',
                  }}
                />
              )}
            </IconButton>
          </Box>
        )}
        <AppBarTools>
          {/* <div>loggedIn:{loggedIn.toString()}</div>
          <div>isTemporaryLogin:{isTemporaryLogin.toString()}</div>
          <div>BIND_EMAIL:{modals[GLOBAL_MODALS.BIND_EMAIL].toString()}</div> */}
          {isMobile && <MoonLogo width={100} height={12} />}
          {isTemporaryLogin ? (
            <WalletWidgetPlaceholder />
          ) : loggedIn ? (
            <WalletWidget />
          ) : null}
          <ProfileBox
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {isTelegramMiniApp ? <TelegramMiniAppAutoLogin /> : <AuthModal />}
          </ProfileBox>
        </AppBarTools>
      </AppHeaderBar>
      {/*App Main Content*/}
      {/*  sidebar drawer */}
      <Drawer
        variant="permanent"
        open={appSidebarOpen}
        sx={(theme) => ({
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            background: theme.palette.bg.main,
          },
        })}
      >
        <AppSideBarDrawerHeader isMobile={false} />
        <Box
          sx={{
            height: '42px',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <RewardsDropdown />
        </Box>
        <Divider sx={{ mb: 2, mt: 2 }} />
        <PromotionsBanner />
        <Menus open={appSidebarOpen} />
      </Drawer>
      <AppMain open={appSidebarOpen} isSportsPage={isSportsPage}>
        {/*{loggedIn ? null : <HomepageBG />}*/}
        <Divider
          sx={{
            marginTop: APP_FIXED_HEADER_HEIGHT + 'px',
            borderBottom: 'none',
          }}
        />
        {/*<AppBarTools />*/}
        {props.children}
      </AppMain>
      {/*  sidebar drawer */}
      <Drawer
        variant="permanent"
        open={appSidebarOpen}
        sx={(theme) => ({
          background: theme.palette.primary.main,
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            overflow: 'hidden',
          },
        })}
      >
        <AppSideBarDrawerHeader isMobile={false} />
        <Scrollbar
          autoHide={true}
          offsetY={appSidebarOpen ? -28 : -18}
          style={{
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'inline-flex',
              width: '100%',
              height: '48px',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: appSidebarOpen ? 1 : 0,
              flexShrink: 0,
              border: theme.palette.border.primary,
              borderRadius: theme.spacing(1),
              boxShadow: theme.palette.boxShadow.glow,
            }}
          >
            <RewardsDropdown />
          </Box>
          <Divider sx={{ mb: 2, mt: 2 }} />
          <PromotionsBanner />
          <Menus open={appSidebarOpen} />
        </Scrollbar>
      </Drawer>
      <BindEmailModal
        thirdPartyLoginType={userMetaData?.thirdPartyLoginType}
        open={modals[GLOBAL_MODALS.BIND_EMAIL]}
        onClose={() => {
          setModals({
            [GLOBAL_MODALS.BIND_EMAIL]: false,
          });
        }}
        tgUserString={userMetaData?.thirdPartyAuthCode}
      />
    </>
  );
}

export default CatchError(AppDrawerBar);
