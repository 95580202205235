import { styled } from '@mui/material/styles';

import { Box, ListItem, ListItemIcon } from '@mui/material';

export const MenuGroupItem = styled(ListItem)<{ open?: boolean }>(
  ({ theme, open }) => ({
    // marginBottom: theme.spacing(0.5),
    margin: theme.spacing(1.25, 0),
    padding: theme.spacing(1, 0.5),
    borderRadius: theme.shape.borderRadius,
    '&.Mui-selected': {
      background: 'transparent',
      '.MuiTypography-root, .MuiListItemIcon-root': {
        color: theme.palette.primary.main,
      },
    },
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.secondary.main,
      '.MuiTypography-root': {
        color: theme.palette.grey[50],
      },
    },
    '&:hover > .MuiListItemText-root': {
      display: 'inline-block',
    },
    justifyContent: open ? 'flex-start' : 'center',
  }),
);

export const CListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open?: boolean }>(({ theme, open }) => ({
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(1.25, 0),
  padding: theme.spacing(1, 0.5),
  '&.Mui-selected': {
    '.MuiTypography-root, .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
    background: theme.palette.secondary.main,
  },
  '&:hover': {
    cursor: 'pointer',
    background: theme.palette.secondary.main,
    '.MuiTypography-root': {
      color: theme.palette.grey[50],
    },
  },
  justifyContent: open ? 'flex-start' : 'center',
}));

export const CListItemIcon = styled(ListItemIcon)<{ open?: boolean }>(
  ({ theme, open }) => ({
    minWidth: '14px',
    color: theme.palette.text.secondary,
    marginRight: open ? theme.spacing(1) : 0,
  }),
);

export const SportIcon = styled(Box)(({ theme }) => ({
  width: '20px',
  height: '20px',
  '& .crown-bit-icon': {
    verticalAlign: 'baseline',
    width: '100% !important',
    height: '100% !important',
  },
}));

export const CasinoIcon = styled(Box)(({ theme }) => ({
  width: '20px',
  height: '20px',
  '& .crown-bit-icon': {
    verticalAlign: 'baseline',
    width: '100% !important',
    height: '100% !important',
  },
}));
