// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonCryptoTokenEos: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-crypto-token-eos"><path d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z" fill="#FFFDFA" /><path d="M48.4138 82C48.069 82 47.8965 82 47.7241 81.8264L26.6897 68.6347C26.5173 68.4611 26.3448 68.2876 26.1724 68.114C26.1724 67.9404 26.1724 67.9404 26 67.7668C26 67.5932 26 67.4197 26 67.2461C26 67.2461 26 67.2461 26 67.0725L32.8966 35.4819C32.8966 35.3083 32.8965 35.3083 33.0689 35.1347C33.0689 35.1347 33.069 34.9611 33.2414 34.9611L47.3794 15.5207C47.7242 15.1736 48.0689 15 48.5862 15C49.1034 15 49.4483 15.1736 49.7931 15.5207L63.9311 34.9611C64.1035 35.1347 64.1034 35.3083 64.1034 35.4819L71 67.0725C71 67.0725 71 67.0725 71 67.2461C71 67.4197 71 67.5932 71 67.7668C71 67.9404 71 67.9404 70.8276 68.114C70.6552 68.2876 70.4828 68.4611 70.3104 68.6347L49.2759 81.8264C48.9311 82 48.7586 82 48.4138 82ZM53.7586 68.8083L51 77.3134L64.6208 68.8083H53.7586ZM32.3793 68.8083L46 77.3134L43.2414 68.8083H32.3793ZM46.1725 68.8083L48.4138 75.9249L50.6552 68.8083H46.1725ZM54.6207 65.8575H67.0345L59.1035 51.6244L54.6207 65.8575ZM45.3104 65.8575H51.6897L57.2069 48.3264L48.4138 32.8783L39.6207 48.3264L45.3104 65.8575ZM29.7931 65.8575H42.207L37.7241 51.6244L29.7931 65.8575ZM34.4483 41.3834L30.6552 58.5674L36.5172 48.1529L34.4483 41.3834ZM60.3104 47.9793L66.1724 58.3938L62.3793 41.2099L60.3104 47.9793ZM35.8276 36.0026L38.5862 44.5078L47.0345 29.4068V20.5544L35.8276 36.0026ZM49.9656 29.5803L58.4138 44.6814L61.1725 36.1762L49.9656 20.728V29.5803Z" fill="#08060B" /></SvgBase>);
};
const MemoMoonCryptoTokenEos = React.memo(MoonCryptoTokenEos);
MemoMoonCryptoTokenEos.displayName = 'MoonCryptoTokenEos';
export default MemoMoonCryptoTokenEos;