import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { SxProps } from '@mui/system';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useChangeQueryString } from '@/components/ui/WrappedTab/helper';
import { useMount, useUnmount } from 'ahooks';
import requestIdleCallback from '@/utils/requestIdleCallback';
import scrollBodyToPosition from '@/utils/dom/scrollBodyToPosition';
import { Stack } from '@mui/material';
import Link from 'next/link';

interface TabPanelProps<T extends string | number> {
  children?: React.ReactNode;
  currentTab: any;
  value: any;
}

function TabPanel<T extends string | number>(props: TabPanelProps<T>) {
  const { children, value, currentTab, ...other } = props;

  const [rendered, setRendered] = useState(value === currentTab);

  useEffect(() => {
    if (value === currentTab) {
      setRendered(true);
    }
  }, [value, currentTab]);

  if (!rendered && value !== currentTab) return null;

  return (
    <div
      role="tabpanel"
      hidden={value !== currentTab}
      id={`simple-tabpanel-${currentTab}`}
      aria-labelledby={`simple-tab-${currentTab}`}
      style={{
        display: value === currentTab ? undefined : 'none',
      }}
    >
      {children}
    </div>
  );
}

function a11yProps(index: string | number, label: string) {
  return {
    id: `${label}-tab-${index}`,
    'aria-controls': `${label}-tabpanel-${index}`,
  };
}

export type IWrappedTabChild = {
  title: React.ReactNode;
  tab?: {
    sx?: SxProps;
  };
  name?: string;
  content?: React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
};

interface WrappedTabsProps<T extends string | number> {
  initialTab?: T;
  sxs?: {
    wrapperSX?: SxProps;
    tabsWrapperSX?: SxProps;
  };
  label?: string;
  basePath?: `/${string}`;
  children: Array<IWrappedTabChild>;
  currentTab?: T;
  onChange?: (event: React.ChangeEvent<any>, newValue: T) => void;
  removeQueryStringOnFirstTab?: boolean;
  tabRowPrefix?: React.ReactNode;
  scrollToTopDistanceWhenChangeTab?: number;
  slots?: {
    beforeContent?: (tab: any) => React.ReactNode;
    content?: React.ReactNode;
  };
}

const WrappedTabs = <T extends string | number>({
  initialTab,
  sxs: { wrapperSX = {}, tabsWrapperSX = {} } = {},
  label,
  basePath,
  children = [],
  onChange,
  currentTab: propsTab,
  removeQueryStringOnFirstTab = true,
  tabRowPrefix,
  scrollToTopDistanceWhenChangeTab,
  slots = {},
}: WrappedTabsProps<T>) => {
  const { query, replace, locale } = useRouter();

  const queryMatch = basePath
    ? query[basePath.replace('/', '')]?.[0] || 0
    : query[label];

  const { handleURL: handleQueryString } = useChangeQueryString({
    label,
    basePath,
  });

  const ref = React.useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    if (!ref.current) return;
    if (scrollToTopDistanceWhenChangeTab === undefined) return;
    scrollBodyToPosition(ref.current, scrollToTopDistanceWhenChangeTab);
    // Get the element's position relative to the top of the page
  };

  const getInitialTab = () => {
    if (propsTab) {
      return propsTab;
    }
    /**
     * string match
     */
    if (isNaN(Number(queryMatch))) {
      const match = children?.find((child, index) => child.name === queryMatch);
      return match?.name ?? children?.[0]?.name ?? initialTab ?? 0;
    } else {
      return Number(queryMatch) <= children.length - 1 ? Number(queryMatch) : 0;
    }
  };

  const [currentTab, setCurrentTab] = useState(getInitialTab);

  useEffect(() => {
    if (propsTab !== undefined) {
      setCurrentTab(propsTab);
    }
  }, [propsTab]);

  /**
   * sync external tab change(like query string change and props change)
   */
  useEffect(() => {
    const match = queryMatch;
    if (match && children.find((child) => child.name === match)) {
      if (onChange) {
        onChange(null, match as any);
      }
      setCurrentTab(match as any);
      if (scrollToTopDistanceWhenChangeTab) {
        scrollToTop();
      }
    }
  }, [queryMatch]);

  useEffect(() => {
    if (!removeQueryStringOnFirstTab) {
      handleQueryString(String(currentTab));
    }
    return () => {
      /**
       * remove query string when tab component is unmounted
       * if basePath is provided
       * don't active this feature when removeQueryStringOnFirstTab is true
       */
      label && handleQueryString(undefined);
    };
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: T) => {
    setCurrentTab(newValue);
    if (label || basePath) {
      const isFirstTab = newValue === children?.[0]?.name || newValue === 0;

      if (isFirstTab && removeQueryStringOnFirstTab) {
        handleQueryString(undefined);
      } else {
        /**
         * wait for the next frame to update query string
         */

        handleQueryString(String(newValue));
      }
    }
    onChange?.(event, newValue);
    // scrollToTop();
  };

  if (!children.length) {
    return null;
  }

  const ariaLabel = label ?? (basePath ? basePath.replace('/', '') : '');

  // const Wrapper = basePath ? Link : React.Fragment;

  return (
    <Stack sx={wrapperSX} ref={ref} gap={1}>
      <Stack
        sx={{
          ...tabsWrapperSX,
          overflowX: 'scroll',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        direction={['row', 'row']}
        alignItems={['flex-start']}
        mt={1}
      >
        {tabRowPrefix}
        <Tabs
          value={currentTab}
          onChange={handleChange}
          aria-label={ariaLabel}
          sx={{
            ['.MuiTabs-flexContainer']: {
              gap: 0.5,
            },
          }}
        >
          {children
            .filter((child) => !child.hidden)
            .map((child, index) => (
              <Tab
                key={`${child?.name + index}`}
                label={child.title}
                sx={child?.tab?.sx}
                {...a11yProps(index, ariaLabel)}
                disabled={child.disabled}
                value={child.name ?? index}
                onClick={(e) => {
                  setTimeout(() => {
                    (e.target as HTMLButtonElement).scrollIntoView({
                      inline: 'center',
                      block: 'nearest',
                      behavior: 'smooth',
                    });
                  }, 500);
                }}
              />
            ))}
        </Tabs>
      </Stack>
      {slots.beforeContent && slots.beforeContent(currentTab)}
      {slots.content ||
        children.map((child, index) =>
          child.content ? (
            <TabPanel<T>
              value={child.name ?? index}
              currentTab={currentTab}
              key={child.name ?? index}
            >
              {child.content}
            </TabPanel>
          ) : null,
        )}
    </Stack>
  );
};

export default WrappedTabs;
