// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonArrowDropUp: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-arrow-drop-up"><g id="arrow_drop_up" clipPath="url(#clip0_423_97404)"><path id="Vector" d="M8.70999 12.29L11.3 9.69997C11.69 9.30997 12.32 9.30997 12.71 9.69997L15.3 12.29C15.93 12.92 15.48 14 14.59 14H9.40999C8.51999 14 8.07999 12.92 8.70999 12.29Z" fill="white" fillOpacity="0.56" /></g><defs><clipPath id="clip0_423_97404"><rect width="24" height="24" fill="white" /></clipPath></defs></SvgBase>);
};
const MemoMoonArrowDropUp = React.memo(MoonArrowDropUp);
MemoMoonArrowDropUp.displayName = 'MoonArrowDropUp';
export default MemoMoonArrowDropUp;