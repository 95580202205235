import { TGameListItem } from '@/apis/hub88';
import { atom } from 'jotai';
import { atomWithImmer } from 'jotai-immer';
import { sortGameList } from './helper';

export enum GLOBAL_MODALS {
  LOGIN,
  REGISTER,
  RESET_PASSWORD,
  TWO_FACTOR_AUTH,
  WALLET,
  REWARDS_GAME_LIST,
  BIND_EMAIL,
}

export const modalsState = atomWithImmer<Record<GLOBAL_MODALS, boolean>>({
  [GLOBAL_MODALS.LOGIN]: false,
  [GLOBAL_MODALS.REGISTER]: false,
  [GLOBAL_MODALS.RESET_PASSWORD]: false,
  [GLOBAL_MODALS.TWO_FACTOR_AUTH]: false,
  [GLOBAL_MODALS.WALLET]: false,
  [GLOBAL_MODALS.REWARDS_GAME_LIST]: false,
  [GLOBAL_MODALS.BIND_EMAIL]: false,
});

export const appSidebarOpenState = atomWithImmer<boolean>(false);

export const appSidebarMobileOpenState = atomWithImmer<boolean>(false);
export const appRewardsMobileOpenState = atomWithImmer<boolean>(false);

export const appSidebarMobileSearchDrawerOpened = atomWithImmer<boolean>(false);

export const appGameList = atomWithImmer<{
  loading: boolean;
  list: TGameListItem[];
}>({
  loading: true,
  list: [],
});

export const globalModals = atom(
  (get) => get(modalsState),
  (
    get,
    set,
    params: Partial<Record<GLOBAL_MODALS, boolean>> & {
      excludingOthers?: boolean;
    } = {},
  ) => {

    if (params.excludingOthers === undefined) {
      params.excludingOthers = true;
    }

    const prevSettings = get(modalsState);

    if (params.excludingOthers) {
      Object.keys(prevSettings).forEach((key) => {
        params[key] = !!params[key];
      });
    }
    delete params.excludingOthers;
    set(modalsState, {
      ...prevSettings,
      ...params,
    });
  },
);

export const gameListSelector = atom(
  (get) => {
    const unsortedList = get(appGameList);
    // if (isServer) {
    //   return {
    //     loading: false,
    //     list: [],
    //   };
    // }
    if (unsortedList.list.length) {
      return {
        ...unsortedList,
        list: sortGameList([...unsortedList.list]),
      };
    } else {
      return {
        loading: false,
        list: [],
      };
    }
  },
  (get, set, newValue: TGameListItem[]) => {
    const prevList = get(appGameList);
    set(appGameList, {
      loading: false,
      list: Array.from(
        new Map(
          [...prevList.list, ...newValue].map((item) => [item.gameId, item]),
        ).values(),
      ),
    });
  },
);

export const gameMapSelector = atom((get) => {
  const { list } = get(gameListSelector);
  return list.reduce(
    (acc, cur) => {
      acc[cur.gameId] = cur;
      return acc;
    },
    {} as Record<number, TGameListItem>,
  );
});
