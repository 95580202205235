import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Box } from '@mui/material';

class ErrorCatcher extends React.PureComponent<any, { error: boolean }> {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  componentDidCatch(e) {
    console.error('get catch', e);
    this.setState({ error: true });
  }

  render() {
    const PlaceHolder = this.props.placeHolder;
    return this.state.error ? <PlaceHolder /> : this.props.children;
  }
}

const StyledText = styled(Box)`
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  padding: 16px 0;
  margin-bottom: 8px;

  > button {
    font-size: 12px;
    padding: 4px 12px;
  }
`;

export const CatchError =
  (
    Component,
    PlaceHolder = () => (
      <StyledText>
        Oops! Something went wrong. <br />
        <Button onClick={() => location.reload()} size={'small'}>
          <span>Try Again</span>
        </Button>
      </StyledText>
    ),
  ) =>
  <T,>(props) => {
    return (
      <ErrorCatcher placeHolder={PlaceHolder}>
        <Component<T> {...props} />
      </ErrorCatcher>
    );
  };

export default CatchError;
