// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyFormulaE: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-formula-e"><g id="Property 1=sport-formula_e"><path id="Vector" d="M22 12.7628C25.4354 13.5424 28 16.6147 28 20.2859C28 24.5464 24.5462 28.0002 20.2857 28.0002C16.0252 28.0002 12.5714 24.5464 12.5714 20.2859C12.5714 19.9962 12.5874 19.7102 12.6185 19.4288H6.57141V26.2859C6.57141 26.7593 6.18765 27.1431 5.71427 27.1431C5.24088 27.1431 4.85712 26.7593 4.85712 26.2859V5.71456C4.85712 5.24117 5.24088 4.85742 5.71427 4.85742C6.18765 4.85742 6.57141 5.24117 6.57141 5.71456H21.1429C21.6162 5.71456 22 6.09832 22 6.5717V12.7628ZM16.8571 13.3735V10.8574H20.2857V7.42885H16.8571V10.8574H13.4286V7.42885H9.99998V10.8574H6.57141V14.2859H9.99998V17.7145H13.0104C13.1281 17.3814 13.2681 17.0587 13.4286 16.7483V14.2859H15.4366C15.8733 13.9326 16.3493 13.6259 16.8571 13.3735ZM9.99998 10.8574H13.4286V14.2859H9.99998V10.8574ZM20.2857 26.2859C23.5994 26.2859 26.2857 23.5997 26.2857 20.2859C26.2857 16.9722 23.5994 14.2859 20.2857 14.2859C16.972 14.2859 14.2857 16.9722 14.2857 20.2859C14.2857 23.5997 16.972 26.2859 20.2857 26.2859ZM23.2891 23.3888C23.2891 23.758 22.9898 24.0574 22.6206 24.0574H18.5714C18.098 24.0574 17.7143 23.6736 17.7143 23.2002V17.7145C17.7143 17.2411 18.098 16.8574 18.5714 16.8574H22.4869C22.8561 16.8574 23.1554 17.1567 23.1554 17.5259C23.1554 17.8952 22.8561 18.1945 22.4869 18.1945H19.3703V19.7579H22.0651C22.423 19.7579 22.7131 20.0481 22.7131 20.4059C22.7131 20.7638 22.423 21.0539 22.0651 21.0539H19.3703V22.7202H22.6206C22.9898 22.7202 23.2891 23.0196 23.2891 23.3888Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyFormulaE = React.memo(BetbyFormulaE);
MemoBetbyFormulaE.displayName = 'BetbyFormulaE';
export default MemoBetbyFormulaE;