// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonSocialWechat: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-social-wechat"><path d="M23.2286 14.6831C23.2286 11.5947 20.2416 9.07544 16.5636 9.07544C12.8857 9.07544 9.91912 11.5947 9.91912 14.6831C9.91912 17.7714 12.9061 20.2907 16.5841 20.2907C17.2955 20.2907 17.966 20.2098 18.5956 20.0266C18.7788 19.9857 18.9815 20.0061 19.1647 20.088L20.8107 20.9817C20.9121 21.043 21.0544 20.9407 21.0135 20.8189L20.688 19.4984C20.6471 19.2956 20.7084 19.092 20.8907 18.9702C22.3144 17.9342 23.2286 16.41 23.2286 14.6831ZM14.1867 13.9521C13.6789 13.9521 13.2725 13.5457 13.2725 13.038C13.2725 12.5302 13.6789 12.1238 14.1867 12.1238C14.6944 12.1238 15.1008 12.5302 15.1008 13.038C15.1008 13.5457 14.6944 13.9521 14.1867 13.9521ZM18.6774 13.9521C18.1696 13.9521 17.7632 13.5457 17.7632 13.038C17.7632 12.5302 18.1696 12.1238 18.6774 12.1238C19.1851 12.1238 19.5915 12.5302 19.5915 13.038C19.5915 13.5457 19.1647 13.9521 18.6774 13.9521Z" fill="#F0F0F0" /><path d="M9.37148 14.7035C9.37148 11.2701 12.6226 8.48581 16.6456 8.48581H16.9506C16.2997 5.37698 13.0086 3 9.04601 3C4.59616 3 1 5.96655 1 9.6445C1 11.6764 2.1178 13.5048 3.86519 14.7239C3.98702 14.8048 4.02793 14.9471 4.00747 15.0894L3.39833 17.2432C3.35743 17.3855 3.52016 17.5277 3.66244 17.4459L6.03943 16.024C6.20216 15.9226 6.38446 15.9022 6.56765 15.9626C7.35998 16.1654 8.19322 16.2881 9.0469 16.2881C9.23009 16.2881 9.41239 16.2881 9.61603 16.2677C9.4524 15.7599 9.37148 15.2317 9.37148 14.7035ZM11.7485 6.51522C12.338 6.51522 12.8254 7.00253 12.8254 7.59211C12.8254 8.18168 12.338 8.66899 11.7485 8.66899C11.1589 8.66899 10.6716 8.18168 10.6716 7.59211C10.6716 7.00253 11.1598 6.51522 11.7485 6.51522ZM6.34356 8.66899C5.75398 8.66899 5.26666 8.18168 5.26666 7.59211C5.26666 7.00253 5.75398 6.51522 6.34356 6.51522C6.93314 6.51522 7.42045 7.00253 7.42045 7.59211C7.42045 8.18168 6.95359 8.66899 6.34356 8.66899Z" fill="#F0F0F0" /></SvgBase>);
};
const MemoMoonSocialWechat = React.memo(MoonSocialWechat);
MemoMoonSocialWechat.displayName = 'MoonSocialWechat';
export default MemoMoonSocialWechat;