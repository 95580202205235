import { Components, Theme } from '@mui/material/styles';

export default function Popover(theme: Theme): Components<Theme> {
  return {
    MuiPopover: {
      styleOverrides: {
        root: {
          '& .MuiPopover-paper': {
            backgroundImage: 'none',
            backgroundColor: '#2E2E2E',
          },
        },
      },
    },
  };
}
