import useShouldUseRewardsBanner from '@/hooks/biz/welcomepack/useShouldUseRewardsBanner';
import uiState from '@/state/ui';
import useTheme from '@/theme/useTheme';
import { getStaticAsset } from '@/utils/staticAsset';
import { useAtomValue } from 'jotai';
import Image from 'next/image';
import React, { CSSProperties } from 'react';
import LocalizedLink from '../../ui/LocalizedLink';
import { LogoBox } from './styles';

export const monoLogos = {
  imgLogo: getStaticAsset('/assets/images/logo/moonclub.svg'),
  textLogo: getStaticAsset('/assets/images/logo/moonclub.svg'),
  imgWithTextLogo: getStaticAsset('/assets/images/logo/moonclub.svg'),
};

export interface MoonLogoProps {
  width?: number;
  height?: number;
  onClick?: () => void;
  forceNoBrightnessChange?: boolean;
  enableStyleFilter?: boolean;
  sx?: CSSProperties;
}

export const MoonLogo: React.FC<MoonLogoProps> = (props) => {
  const {
    onClick,
    forceNoBrightnessChange = false,
    height = 18,
    width = 115,
    enableStyleFilter = false,
    sx,
  } = props;
  const { theme } = useTheme();

  const { isHeaderTransparent } = useAtomValue(uiState);

  const canGetReward = useShouldUseRewardsBanner();

  const filter =
    enableStyleFilter &&
    isHeaderTransparent &&
    canGetReward &&
    !forceNoBrightnessChange
      ? 'brightness(0)'
      : 'brightness(1)';

  return (
    <LocalizedLink
      href={'/'}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <LogoBox
        onClick={onClick}
        sx={{
          filter,
          ...sx,
        }}
      >
        <Image
          width={width}
          height={height}
          alt="logo"
          src={monoLogos.imgLogo}
        />
      </LogoBox>
    </LocalizedLink>
  );
};

export default MoonLogo;
