// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbySkeleton: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-skeleton"><g id="Property 1=sport-skeleton"><path id="Vector" d="M19.7767 17.4136L4.66383 6.8315C3.9597 6.33846 3.78857 5.36796 4.28161 4.66383C4.77465 3.9597 5.74515 3.78857 6.44928 4.28161L13.5081 9.22422C14.3203 8.36024 15.6648 8.20099 16.6646 8.9011L22.8905 13.2605C23.9878 14.0288 24.2545 15.5412 23.4862 16.6386L23.2928 16.9147C22.5245 18.012 21.0121 18.2787 19.9148 17.5103L19.7767 17.4136ZM4.57735 14.2886C4.10793 13.9599 3.99384 13.3129 4.32254 12.8435C4.65123 12.3741 5.29823 12.26 5.76765 12.5887C12.6629 17.4168 12.6629 17.4168 23.4981 25.0036C23.8492 25.2493 24.8662 25.1117 25.062 24.832C25.3907 24.3626 26.0377 24.2485 26.5071 24.5772C26.9765 24.9059 27.0906 25.5529 26.7619 26.0223C25.8865 27.2726 23.569 27.5862 22.308 26.7037L4.57735 14.2886ZM25.9248 21.0772C24.7787 21.0772 23.8495 20.1481 23.8495 19.002C23.8495 17.8559 24.7787 16.9268 25.9248 16.9268C27.0709 16.9268 28 17.8559 28 19.002C28 20.1481 27.0709 21.0772 25.9248 21.0772Z" fill="white" /></g></SvgBase>);
};
const MemoBetbySkeleton = React.memo(BetbySkeleton);
MemoBetbySkeleton.displayName = 'BetbySkeleton';
export default MemoBetbySkeleton;