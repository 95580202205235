// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyDota2: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-dota2"><g id="Property 1=sport-dota2"><path id="Vector" d="M4 24.6226C4 24.7022 4.03161 24.7785 4.08787 24.8348L7.16514 27.912C7.2214 27.9683 7.29771 27.9999 7.37728 27.9999H13.0348C13.302 27.9999 13.4359 27.6768 13.2469 27.4878L4.51213 18.753C4.32314 18.564 4 18.6979 4 18.9651V24.6226Z" fill="white" /><path id="Vector_2" d="M22.4885 27.8943C22.5455 27.9613 22.629 27.9999 22.7169 27.9999H26.6555C26.8048 27.9999 26.9313 27.8901 26.9525 27.7423L27.9749 20.5852C27.9904 20.4768 27.9457 20.3687 27.8582 20.3029L6.37686 4.15638C6.25743 4.0666 6.09013 4.07841 5.98448 4.18406L4.19572 5.97281C4.08516 6.08337 4.07806 6.2603 4.17938 6.37937L16.9105 21.3396C16.9407 21.3751 16.9787 21.403 17.0216 21.4213L19.0496 22.2837C19.1365 22.3207 19.201 22.3964 19.2235 22.4881L19.7504 24.628C19.7616 24.6732 19.7831 24.7152 19.8132 24.7507L22.4885 27.8943Z" fill="white" /><path id="Vector_3" d="M18.9652 4C18.698 4 18.5641 4.32314 18.7531 4.51213L27.4879 13.2469C27.6769 13.4359 28 13.302 28 13.0348V7.37728C28 7.29771 27.9684 7.2214 27.9121 7.16514L24.8349 4.08787C24.7786 4.03161 24.7023 4 24.6227 4H18.9652Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyDota2 = React.memo(BetbyDota2);
MemoBetbyDota2.displayName = 'BetbyDota2';
export default MemoBetbyDota2;