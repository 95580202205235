import { TGameListItem } from '@/apis/hub88';

export const sortGameList = (list: TGameListItem[]) => {
  return list.sort((a, b) => {
    if (a.name === 'Gates of Olympus' && b.provider !== 'SELF') {
      return -1;
    }
    if (a.provider === 'SELF') {
      return -2;
    } else {
      return 3;
    }
  });
};
