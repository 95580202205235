// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonMLogo: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-m-logo"><rect x="0.5" y="0.5" width="55" height="55" rx="7.5" stroke="#E6BC47" strokeOpacity="0.1" /><path d="M42.2521 22.4937L39.3994 19.0903C39.3519 19.0335 39.2816 19.0005 39.2076 19.0005H32.7741C32.7092 19.0005 32.646 19.0232 32.5957 19.0643C31.8423 19.6832 31.2342 20.1826 30.6375 20.6723C30.5316 20.7593 30.3754 20.7447 30.2873 20.6398C29.8922 20.1685 29.4879 19.6864 28.9933 19.0957C28.9425 19.0351 28.868 19 28.789 19H24.6343H14.8379C14.7179 19 14.6141 19.0854 14.592 19.2038L14.0044 22.2997C13.9753 22.4537 14.0936 22.5964 14.2504 22.5964H17.2036C17.563 22.5964 17.8338 22.9234 17.7668 23.2764L15.246 36.5573C15.2038 36.78 15.3746 36.9865 15.6011 36.9865H18.5629C18.7364 36.9865 18.8851 36.8632 18.918 36.693L21.0957 25.3627C21.113 25.2719 21.0795 25.1794 21.0076 25.1211L18.8608 23.3791C18.5386 23.1175 18.7235 22.5959 19.1386 22.5959H21.6281H27.9151C28.0718 22.5959 28.1902 22.7386 28.161 22.8926L25.5677 36.5568C25.5256 36.7795 25.6964 36.9859 25.9228 36.9859H28.9744C29.1479 36.9859 29.2966 36.8627 29.3295 36.6924L31.5066 25.3643C31.5245 25.2724 31.4893 25.1784 31.4158 25.1205L29.2139 23.3823C28.8858 23.1234 29.069 22.5953 29.4868 22.5953H32.039H38.326C38.4828 22.5953 38.6011 22.738 38.5719 22.8921L35.9787 36.5562C35.9365 36.7789 36.1073 36.9854 36.3338 36.9854H39.3092C39.4832 36.9854 39.6324 36.8616 39.6643 36.6908L42.3051 22.6991C42.3202 22.6278 42.3008 22.5516 42.2521 22.4937Z" fill="#FCD535" /></SvgBase>);
};
const MemoMoonMLogo = React.memo(MoonMLogo);
MemoMoonMLogo.displayName = 'MoonMLogo';
export default MemoMoonMLogo;