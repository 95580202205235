import { isRightToLeft } from '@/utils/i18/rtl';
import { useTranslation } from 'next-i18next';

export const renderMetaTitle = (title: string, brandName = 'MoonClub') => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t, i18n, ready } = useTranslation();
  const rightToLeft = isRightToLeft(i18n.language);

  const formattedTitle = !rightToLeft
    ? `${title} | ${brandName}`
    : `${brandName} | ${title}`;

  return (
    <>
      <title key="title">{formattedTitle} </title>
      <meta key="meta:og:title" property="og:title" content={formattedTitle} />
    </>
  );
};

export default renderMetaTitle;
