import uiState from '@/state/ui';
import { useAtom } from 'jotai';
import { useState } from 'react';

export const useZendeskStatus = () => {
  const [{ isZenDeskOpen }, setUIState] = useAtom(uiState);

  const setOpen = (open: boolean) => {
    setUIState((prev) => {
      prev.isZenDeskOpen = open;
      return prev;
    });
  };

  try {
    if (typeof window !== 'undefined' && typeof window?.zE !== 'undefined') {
      window?.zE('messenger:on', 'close', () => {
        setOpen(false);
      });
    }
  } catch (e) {
    console.error(e);
  }

  const toggleZendesk = () => {
    if (typeof window?.zE === 'undefined') return;

    try {
      if (isZenDeskOpen) {
        window?.zE('messenger', 'close');
        setOpen(false);
      } else {
        window?.zE('messenger', 'open');
        setOpen(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const closeZendesk = () => {
    try {
      if (typeof window?.zE === 'undefined') return;
      window?.zE('messenger', 'close');
      setOpen(false);
    } catch (e) {
      console.error(e);
    }
  };

  const openZendesk = () => {
    if (typeof window?.zE === 'undefined') return;
    window?.zE('messenger', 'open');
    setOpen(true);
  };

  return { toggleZendesk, closeZendesk, openZendesk };
};
