// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const CasinoBlackjack: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="18" height="21" viewBox="0 0 18 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-casino-blackjack"><path d="M6.53455 0.0771452C5.90759 -0.137194 5.2425 0.116514 4.88242 0.641425H8.19515L6.53455 0.0771452Z" fill="currentColor" fillOpacity="0.7" /><path d="M16.9938 3.6466L16.7099 3.55037V9.36814L17.9257 5.56253C18.1799 4.76641 17.7605 3.90906 16.9895 3.6466H16.9938Z" fill="currentColor" fillOpacity="0.7" /><path d="M1.00623 17.3553L1.29006 17.4516V11.6338L0.0742596 15.4394C-0.179914 16.2355 0.239473 17.0929 1.01047 17.3553H1.00623Z" fill="currentColor" fillOpacity="0.7" /><path d="M11.466 20.9248C12.0929 21.1391 12.758 20.8854 13.1181 20.3605H9.80538L11.466 20.9248Z" fill="currentColor" fillOpacity="0.7" /><path d="M14.6006 1.31496H3.58643C2.77308 1.31496 2.11646 1.99298 2.11646 2.83283V17.994C2.11646 18.8339 2.77308 19.5119 3.58643 19.5119H14.6006C15.4098 19.5119 16.0706 18.8339 16.0706 17.994V2.83283C16.0706 1.99735 15.414 1.31496 14.6006 1.31496ZM4.56924 6.52034C4.3193 6.52034 4.09054 6.4766 3.88297 6.38474C3.67539 6.29288 3.51018 6.1529 3.38733 5.96043C3.26448 5.76797 3.20094 5.51426 3.20517 5.20369H4.26847V5.22118C4.2727 5.31304 4.28965 5.39615 4.3193 5.45739C4.34895 5.52738 4.39132 5.57987 4.44639 5.61487C4.50146 5.64986 4.565 5.66736 4.64549 5.66736C4.72598 5.66736 4.78952 5.64986 4.84459 5.61487C4.89966 5.57987 4.93779 5.52738 4.96744 5.45739C4.9971 5.3874 5.00981 5.29992 5.00981 5.19494V2.4304H6.05615V5.21681C6.05192 5.47052 5.98838 5.6936 5.86552 5.88607C5.73844 6.08291 5.56475 6.24039 5.34447 6.34974C5.12418 6.4591 4.86577 6.51596 4.57771 6.51596L4.56924 6.52034ZM9.64848 12.6006L10.1441 14.9627H8.04718L8.54282 12.6006C7.31008 13.2217 5.42496 12.6443 5.42496 10.6103C5.42496 8.23942 9.09777 5.8642 9.09777 5.8642C9.09777 5.8642 12.7706 8.23942 12.7706 10.6103C12.7706 12.6443 10.8855 13.2217 9.65271 12.6006H9.64848Z" fill="currentColor" fillOpacity="0.7" /></SvgBase>);
};
const MemoCasinoBlackjack = React.memo(CasinoBlackjack);
MemoCasinoBlackjack.displayName = 'CasinoBlackjack';
export default MemoCasinoBlackjack;