import Router from 'next/router';
import configs, { isLocalOrTesting, isServer } from '@/configs';

const isEnabled = !!configs.env.GOOGLE_TAG_MANAGER_ID;

function sendPageview({
  currency,
  language,
  pageURL,
  section,
  subSection,
  siteTheme,
  firstSessionDate,
  lastSessionDate,
}) {
  if (isEnabled) {
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: 'virtualPageview',
      pageURL,
      section,
      subSection,
      language,
      currency,
      siteTheme,
      firstSessionDate,
      lastSessionDate,
    });
  }
}

const queryThenExec = (criteria, callback) => {
  // let queried = 0;
  let timer;
  const exec = (count) => {
    // console.log(count);
    if (count >= 20) {
      return clearTimeout(timer);
    }
    if (criteria()) {
      clearTimeout(timer);
      callback();
    } else {
      timer = setTimeout(exec, 200, count + 1);
    }
  };
  timer = setTimeout(exec, 200, 0);
};

export type EventDataType = {
  category: string;
  action: string;
  label?: string;
  event?: 'techTrack' | 'feature';
  json_data?: Record<string, string | number | boolean>;
  keepLabel?: boolean;
  extraData?: Record<string, string | number | boolean>;
  sensorsOnly?: boolean;
};

function getNextRoute() {
  if (isServer) return;
  let project = '';
  const nextRoute = Router.router?.route;
  return `${project}${nextRoute}`;
}

function getClientType() {
  if (isServer) return;
  let client = '';
  const nextData = window['__NEXT_DATA__'];
  const pageShareData =
    nextData.props.pageShareData ||
    nextData.props.pageProps?.pageSharedData ||
    {};
  const { deviceInfo } = pageShareData;
  if (deviceInfo) {
    const { isDesktop, isMobile, isTablet } = deviceInfo;
    client = isDesktop ? 'D' : isTablet ? 'T' : isMobile ? 'M' : 'D';
  }
  return client;
}

function sendEvent({
  category,
  action,
  label,
  event,
  json_data,
  keepLabel = false,
  extraData = {},
  sensorsOnly = false,
}: EventDataType) {
  const logged = { category, action, label: label || json_data, ...extraData };
  /**
   * ENABLE log in production:
   * sensorsDataAnalytic201505.enableLocalLog()
   */
  if (isLocalOrTesting && isEnabled) {
    console.log('debug sendEvent', logged);
  }
  if (isEnabled) {
    const recordEvent = () => {
      /**
       * rewrite label
       */
      let dataLabel = label;
      if (json_data && !keepLabel) {
        dataLabel = Object.entries(json_data)
          .map(([k, v]) => `${k}=${v}`)
          .join(';');
      }
      const gtmData = {
        event: 'customEvent',
        eventCategory: category,
        eventAction: action,
        eventLabel: dataLabel,
        ...(json_data
          ? {
              eventJsonData: JSON.stringify(json_data),
            }
          : {}),
      };
      const sensorsData = {
        category,
        name: action,
        data: dataLabel,
        $url: isServer ? 'server' : window.location.href,
        route: getNextRoute(),
        client_type: getClientType(),
        ...(json_data
          ? {
              jsonData: JSON.stringify(json_data),
            }
          : {}),
        ...extraData,
      };

      !sensorsOnly && window.dataLayer?.push(gtmData);
      window.sensors?.track(event || 'feature', sensorsData);
    };

    if (!window.dataLayer || !window.sensors) {
      queryThenExec(() => window.dataLayer && window.sensors, recordEvent);
    } else {
      recordEvent();
    }
  } else {
    console.log('debug sendEvent', logged);
  }
}

function getInitCode() {
  const gtm_id = configs.env.GOOGLE_TAG_MANAGER_ID || '';
  // We need to set appType here before the snippet, so that the legacy pageview does not get triggered.
  return (
    gtm_id &&
    `
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ "appType": "nextjs" });
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtm_id}');
  `
  );
}

function sendPerformanceMetric({ timingLabel, timingValue, timingVar }) {
  // @ts-ignore
  isEnabled &&
    window.dataLayer.push({
      event: 'customTiming',
      timingCategory: 'Performance Metrics',
      timingVar,
      timingValue,
      timingLabel,
    });
}

export function categoryFactory(categoryName: string) {
  return (
    datas: Omit<EventDataType, 'category'> &
      Partial<Pick<EventDataType, 'category'>>,
  ) => {
    return sendEvent({ category: categoryName, ...datas });
  };
}

export const setGtagConfig = (configParams: Record<string, string>) => {
  const gtm_id = configs.env.GOOGLE_TAG_MANAGER_ID || '';
  // @ts-ignore
  if (window?.gtag && gtm_id) {
    // @ts-ignore
    window.gtag('config', gtm_id, ...configParams);
  }
};

// https://developers.google.com/analytics/devguides/collection/ga4/user-id?hl=zh-cn&client_type=gtag
export const setGtagUserId = (userId: string) => {
  setGtagConfig({ user_id: userId });
};

export default {
  getInitCode,
  setGtagConfig,
  setGtagUserId,
  sendEvent,
  sendPageview,
  sendPerformanceMetric,
  isEnabled,
  categoryFactory,
};

export const gtmCategories = {
  casinoPage: 'casinoPage',
  sportsPage: 'sportsPage',
  homePage: 'homePage',
};

export const gtmSendEvent = sendEvent;
