import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { isLocalDev } from '@/utils/checkRuntime';
import type { Interceptor } from './types';

const Reset = '\x1b[0m';
const FgRed = '\x1b[31m';
const FgCyan = '\x1b[36m';

const logger = {
  info: ({ args }: { args?: any[] }) => {
    // @ts-ignore
    console.info(`${FgCyan}[INFO]${Reset}`, ...args);
  },
  error: ({ args }: { args?: any[] }) => {
    if (isLocalDev) {
      // @ts-ignore
      console.error(`${FgRed}[ERROR]${Reset}`, ...args);
    } else {
      // @ts-ignore
      console.error(
        `${FgRed}[ERROR]${Reset}`,
        ...args.map((arg) => JSON.stringify(arg)),
      );
    }
  },
};

function msg({ config }: { config: any }) {
  if (!config) return 'no config';
  return `${config.method.padEnd(4, ' ')} ${axios.getUri(config)} ${
    config.data || ''
  }`;
}

let records: any[] = [];
// @ts-ignore
let st;

// @ts-ignore
function groupLog(fn) {
  records.push(fn);
  // @ts-ignore
  clearTimeout(st);
  st = setTimeout(() => {
    if (records.length) {
      console.groupCollapsed(`API LOG(${records.length})`);
      records.forEach((r) => r());
      records = [];
      console.groupEnd();
    }
  }, 1500);
}

// platform
export const reqLog: Interceptor<InternalAxiosRequestConfig> = [
  (config) => {
    return config;
  },
  (error) => {
    groupLog(() =>
      logger.error({ args: ['[req/error]', msg({ config: error.config })] }),
    );
  },
];

export const resLog: Interceptor<AxiosResponse> = [
  (response) => {
    if (
      ['local', 'beta', 'staging'].includes(
        process.env.NEXT_PUBLIC_MOON_ENV || 'local',
      )
    ) {
      groupLog(() =>
        logger.info({ args: ['[res/ok]', msg({ config: response.config })] }),
      );
    }
    // log for api business error
    if (response.data) {
      const { status = {} } = response.data;

      if (status.error_code && status.error_code !== '0') {
        groupLog(() =>
          logger.error({
            args: [
              '[res/ok_err]',
              msg({ config: response.config }),
              response.data,
            ],
          }),
        );
      }
    }

    return response;
  },
  (error) => {
    const err = error.response
      ? { data: error.response.data, status: error.response.status }
      : error.message;
    // debug 'socket hang up' with curl
    // if (isServer && err === 'socket hang up') {
    //     try {
    //         // @ts-ignore
    //         require('child_process').exec(`curl -v -o /dev/null ${axios.getUri(error.config)}`, (error, stdout, stderr) => console.log('debug socket hang up curl: \n', error, stdout, stderr),);
    //     } catch (e) {
    //     }
    // }
    groupLog(() =>
      logger.error({
        args: ['[res/error]', msg({ config: error.config }), err],
      }),
    );
    return Promise.reject(error);
  },
];
