import React from 'react';
import { Box } from '@mui/material';
import { PropsOf } from '@emotion/react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import useTheme from '@/theme/useTheme';

const Dropdown: React.FC<
  {
    triggerElement: (props: {
      onClick: () => void;
      open: boolean;
      anchorRef: any;
    }) => React.ReactNode;

    children: ({
      open,
      handleClose,
    }: {
      open: boolean;
      handleClose: (event?: Event | React.SyntheticEvent) => void;
    }) => React.ReactNode;
  } & Partial<Omit<PropsOf<typeof Popper>, 'children'>>
> = ({ triggerElement, children, ...rest }) => {
  const [open, setOpen] = React.useState(false);

  // const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const { theme } = useTheme();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event?: Event | React.SyntheticEvent) => {
    if (!event) {
      return setOpen(false);
    }
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  // function handleListKeyDown(event: React.KeyboardEvent) {
  //   if (event.key === 'Tab') {
  //     event.preventDefault();
  //     setOpen(false);
  //   } else if (event.key === 'Escape') {
  //     setOpen(false);
  //   }
  // }

  // const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const id = anchorEl ? 'simple-popover' : undefined;
  // const renderProps = {
  //   handleClose,
  //   open: !!anchorEl,
  //   handleClick,
  // };

  return (
    <>
      {triggerElement({ open, anchorRef, onClick: handleToggle })}

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom"
        transition
        style={{
          zIndex: theme.zIndex.tooltip,
        }}
        {...rest}
        // disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'top',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Box>
                  {children({
                    open,
                    handleClose,
                  })}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default Dropdown;
