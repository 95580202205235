// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonCryptoTokenBnb: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-crypto-token-bnb"><circle cx="48" cy="48" r="48" fill="#F0B90B" /><path d="M30.0991 48L22.7551 55.395L15.3601 48L22.7551 40.605L30.0991 48ZM48.0001 30.099L60.6481 42.747L68.0431 35.352L55.3951 22.755L48.0001 15.36L40.6051 22.755L28.0081 35.352L35.4031 42.747L48.0001 30.099ZM73.2451 40.605L65.9011 48L73.2961 55.395L80.6401 48L73.2451 40.605ZM48.0001 65.901L35.3521 53.253L28.0081 60.648L40.6561 73.296L48.0001 80.64L55.3951 73.245L68.0431 60.597L60.6481 53.253L48.0001 65.901ZM48.0001 55.395L55.3951 48L48.0001 40.605L40.6051 48L48.0001 55.395Z" fill="#FFFDFA" /><circle cx="48" cy="48" r="48" fill="#F0B90B" /><path d="M30.9008 25.9057L47.8088 16.0637L64.7169 25.9057L58.5007 29.5416L47.8088 23.3355L37.117 29.5416L30.9008 25.9057ZM64.7169 38.3179L58.5007 34.682L47.8088 40.8881L37.117 34.682L30.9008 38.3179V45.5897L41.5926 51.7958V64.2079L47.8088 67.8438L54.0251 64.2079V51.7958L64.7169 45.5897V38.3179ZM64.7169 58.0018V50.7301L58.5007 54.366V61.6377L64.7169 58.0018ZM69.1305 60.572L58.4386 66.7781V74.0499L75.3467 64.2079V44.524L69.1305 48.1599V60.572ZM62.9143 32.1118L69.1305 35.7477V43.0195L75.3467 39.3836V32.1118L69.1305 28.4759L62.9143 32.1118ZM41.5926 69.411V76.6828L47.8088 80.3187L54.0251 76.6828V69.411L47.8088 73.0469L41.5926 69.411ZM30.9008 58.0018L37.117 61.6377V54.366L30.9008 50.7301V58.0018ZM41.5926 32.1118L47.8088 35.7477L54.0251 32.1118L47.8088 28.4759L41.5926 32.1118ZM26.4872 35.7477L32.7034 32.1118L26.4872 28.4759L20.271 32.1118V39.3836L26.4872 43.0195V35.7477ZM26.4872 48.1599L20.271 44.524V64.2079L37.1791 74.0499V66.7781L26.4872 60.572V48.1599Z" fill="white" /></SvgBase>);
};
const MemoMoonCryptoTokenBnb = React.memo(MoonCryptoTokenBnb);
MemoMoonCryptoTokenBnb.displayName = 'MoonCryptoTokenBnb';
export default MemoMoonCryptoTokenBnb;