// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonCryptoTokenTrx: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-crypto-token-trx"><path d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z" fill="#EB0029" /><path d="M68.7714 28.2573L19.2 19.2L45.2927 84.48L81.6 40.4461L68.7714 28.2573ZM67.9968 32.256L75.5972 39.4344L54.8778 43.1922L67.9968 32.256ZM50.3273 42.4214L28.4946 24.4031L64.2209 30.9552L50.3273 42.4214ZM48.7782 45.6011L45.2443 74.8927L25.9773 26.812L48.7782 45.6011ZM52.0701 47.1909L75.0162 43.0477L48.6814 74.9891L52.0701 47.1909Z" fill="white" /></SvgBase>);
};
const MemoMoonCryptoTokenTrx = React.memo(MoonCryptoTokenTrx);
MemoMoonCryptoTokenTrx.displayName = 'MoonCryptoTokenTrx';
export default MemoMoonCryptoTokenTrx;