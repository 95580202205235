import { DEFAULT_PAGE_SIZE } from '@/constants/config';
import { TUserStatus } from './login';
import request from './index';

// Generated by https://quicktype.io

interface IChangeUserProfileResponse {
  name: string;
  email: string;
  status: TUserStatus;
  verified2FA: boolean;
  hiddenIdentity: 0 | 1;
}

export const getUserProfile = () => {
  return request<IChangeUserProfileResponse>({
    method: 'post',
    url: '/api/mc-api/user/profile/detail',
    data: {},
  });
};

export const saveLoginHistory = (data: {
  browser: string;
  location: string;
  timezone: string;
  ip: string;
}) => {
  return request({
    method: 'post',
    url: '/api/mc-api/user/profile/save-login-history',
    data,
  });
};

export const getLoginHistory = ({
  currentPage,
  pageSize = DEFAULT_PAGE_SIZE,
}: {
  currentPage: number;
  pageSize?: number;
}) => {
  return request<{
    list: {
      browser: string;
      location: string;
      timezone: string;
      ip: string;
      createdTime: string;
    }[];
    currentPage: number;
    pageSize: number;
    total: string;
  }>({
    method: 'post',
    url: '/api/mc-api/user/profile/login-history',
    data: { currentPage, pageSize },
  });
};

export const changePassword = (data: {
  // oldPwd: string;
  newPwd: string;
  twoFaCode?: string;
  code?: string;
}) => {
  return request({
    method: 'post',
    url: '/api/mc-api/user/profile/change-pwd',
    data,
  });
};

export const requestChangeEmail = (data: {
  email: string;
  twoFaCode: string;
  newEmailCode: string;
  oldEmailCode: string;
  mode?: number;
}) => {
  return request({
    method: 'post',
    url: '/api/mc-api/user/profile/change-email',
    data,
  });
};

export const requestChangeUsername = (data: { name: string }) => {
  return request({
    method: 'post',
    url: '/api/mc-api/user/profile/save',
    data,
  });
};

type IVerificationData = {
  backIdentity: string;
  birthday: string;
  city: string;
  country: string;
  firstName: string;
  frontIdentity: string;
  lastName: string;
  occupation: string;
  postalCode: string;
  residenceAddress: string;
};

export const saveVerification = (data: IVerificationData) => {
  return request({
    method: 'post',
    url: '/api/mc-api/user/profile/submit-verification',
    data,
  });
};

export const getVerification = () => {
  return request<
    IVerificationData & {
      activeStatus: number;
      email: string;
      name: string;
      status: number;
      verified2FA: boolean;
    }
  >({
    method: 'post',
    url: '/api/mc-api/user/profile/detail-verification',
    data: {},
  });
};

export const toggleHiddenNickname = (hiddenIdentity: 0 | 1) => {
  return request<IChangeUserProfileResponse>({
    method: 'post',
    url: '/api/mc-api/user/profile/switch-hidden-identity',
    data: {
      hiddenIdentity,
    },
  });
};
