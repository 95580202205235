// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonAstronaut: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" sx={propSx}  {...restProps} iconName="i-moon-astronaut"><title>{"Astronout"}</title><g id="Astronout"><path d="M55,11V27.54a3.82,3.82,0,0,0-1.09-.43,21.84,21.84,0,0,0-6.35-13.67C34.27.18,11.65,8.63,10.08,27.11A4,4,0,0,0,7,31v5a4,4,0,0,0,3.13,3.9A14.79,14.79,0,0,0,15,49.17V52a5,5,0,0,0,5,5H44a5,5,0,0,0,5-5V49.17a14.79,14.79,0,0,0,4.87-9.27A4,4,0,0,0,57,36V11A1,1,0,0,0,55,11ZM15,27.09a6,6,0,0,1,4-5.65,39.53,39.53,0,0,1,7.44-2c.1,0-.23-.24,2.6,2.59h6l2.59-2.59c1.34,0,5.74,1.41,7.45,2a6,6,0,0,1,4,5.65v12.8a6,6,0,0,1-4,5.67,37.67,37.67,0,0,1-25.94,0,6,6,0,0,1-4-5.67Z" /></g></SvgBase>);
};
const MemoMoonAstronaut = React.memo(MoonAstronaut);
MemoMoonAstronaut.displayName = 'MoonAstronaut';
export default MemoMoonAstronaut;