// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyMusic: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-music"><g id="Property 1=sport-music"><path id="Vector" d="M24.7145 15.9996V5.87485C24.7144 5.81856 24.6889 5.76853 24.6523 5.74166C24.6126 5.71329 24.5737 5.70765 24.5326 5.72246L13.6827 9.56767C13.6228 9.58942 13.5815 9.64872 13.5815 9.72V23.6412H13.5709C13.5713 23.6655 13.5715 23.6899 13.5715 23.7142C13.5715 26.0812 11.6527 28 9.28575 28C6.9188 28 5 26.0812 5 23.7142C5 21.3473 6.9188 19.4285 9.28575 19.4285C10.255 19.4285 11.1492 19.7503 11.8672 20.2928V9.72C11.8672 8.92928 12.3582 8.22479 13.1038 7.95408L23.9559 4.10809C24.5268 3.90248 25.1549 3.99361 25.6567 4.35246C26.1414 4.70683 26.4283 5.27016 26.4288 5.87416V19.5834H26.426C26.3445 21.8786 24.4581 23.7142 22.143 23.7142C19.7761 23.7142 17.8573 21.7955 17.8573 19.4285C17.8573 17.0615 19.7761 15.1427 22.143 15.1427C23.1078 15.1427 23.9982 15.4616 24.7145 15.9996ZM9.28575 26.2857C10.7059 26.2857 11.8572 25.1344 11.8572 23.7142C11.8572 22.2941 10.7059 21.1428 9.28575 21.1428C7.86558 21.1428 6.7143 22.2941 6.7143 23.7142C6.7143 25.1344 7.86558 26.2857 9.28575 26.2857ZM22.143 21.9999C23.5632 21.9999 24.7145 20.8487 24.7145 19.4285C24.7145 18.0083 23.5632 16.857 22.143 16.857C20.7228 16.857 19.5716 18.0083 19.5716 19.4285C19.5716 20.8487 20.7228 21.9999 22.143 21.9999Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyMusic = React.memo(BetbyMusic);
MemoBetbyMusic.displayName = 'BetbyMusic';
export default MemoBetbyMusic;