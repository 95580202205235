import request, { requestWithZod } from '@/apis/index';
import { z } from 'zod';
import { SAnchoredData, SWithdrawPreCheckSchema } from '@/constants/schemas';
import { SCurrencyInfoSchema } from '@/types/api';

const userWalletInfoSchema = z.object({
  accounts: z
    .array(
      z.object({
        id: z.string(),
        uid: z.string(),
        coin: z.string(),
        decimals: z.number(),
        logo: z.string(),
        balance: z.string(),
        frozenBalance: z.string(),
        version: z.string(),
        status: z.number(),
        availableBalance: z.string().optional(),
        chainNetworks: z
          .array(
            z.object({
              address: z.string(),
              addressType: z.string(),
              chain: z.string(),
              coinFullName: z.string(),
              coinKey: z.string(),
              coinName: z.string(),
              coinType: z.string(),
              contract: z.string(),
              decimals: z.number(),
              feeCoin: z.string(),
              feeDecimal: z.number(),
              feeUnit: z.string(),
              id: z.string(),
              minTransferAmount: z.string(),
              network: z.string(),
              symbol: z.string(),
            }),
          )
          .default([]),
      }),
    )
    .default([]),
});

export const getUserWalletInfo = () => {
  return requestWithZod({
    method: 'post',
    url: '/api/mc-api/account/coin',
    schema: userWalletInfoSchema,
  }).then((d) => {
    return d;
  });
};

interface IWithdrawParams {
  withdrawAddress: string;
  amount: string;
  /**
   * 2FA code
   */
  twofactor: string;
  /**
   * email verification code
   */
  emailCode: string;
  /**
   * IUserWalletInfo.coin
   */
  coin: string;
  /**
   * IUserWalletInfo.chainNetworks.coinKey
   */
  coinKey: string;
  chain: string;
  network: string;
}

export const walletWithdraw = (params: IWithdrawParams) => {
  return request<{
    address: string;
    balance: number;
    frozenBalance: number;
  }>({
    method: 'post',
    url: '/api/mc-api/payment/withdraw',
    data: params,
  });
};

const SWalletWithdrawalFee = z.object({
  feeUnit: z.string(),
  coinKeyFee: z.string(),
  fee: z.string(),
  balance: z.string(),
  availableBalance: z.string(),
});

export const walletEstimateFee = (params: {
  coinKey: string;
  destAddress: string;
  value: string;
}) => {
  return requestWithZod({
    method: 'post',
    url: '/api/mc-api/payment/fee',
    data: params,
    schema: SWalletWithdrawalFee,
  });
};

export const walletPreCheckWithdraw = () => {
  return requestWithZod({
    method: 'post',
    url: '/api/mc-api/payment/withdrawalPreCheck',
    schema: SWithdrawPreCheckSchema,
  });
};

export interface ITransaction {
  addedTime: number;
  address: string;
  amount: string;
  creditAmount: string;
  cryptoCurrency: CryptoCurrency;
  feeCoin: FeeCoin;
  id: number;
  network: Network;
  status: 'COMPLETED' | 'FAILED' | 'PENDING' | 'SUCCEED';
  txFee: string;
  txKey: string;
  txhash: string;
}

interface CryptoCurrency {
  decimals: number;
  fullName: string;
  logoUrl: string;
  name: string;
  symbol: string;
}

interface FeeCoin {
  decimals: number;
  fullName: string;
  logoUrl: string;
  name: string;
  symbol: string;
}

interface Network {
  chain: string;
  explorerUrl: string;
  name: string;
  symbol: string;
}

export const getWalletTransactions = (params: {
  /**
   * in seconds
   */
  beginTime?: number;
  /**
   * in seconds
   */
  endTime?: number;
  /**
   * IUserWalletInfo.coin
   */
  coin?: string;
  /**
   * 'deposits' | 'withdraws'
   */
  tranType?: string;
  /**
   * default to 100
   */
  pageSize: number;
  /**
   * to prev page,
   * e.g. BE returns [10, 9, 8, 7, 6],
   * minId should be 10
   */
  /**
   * to next page,
   * e.g. BE returns [10, 9, 8, 7, 6],
   * minId should be 6
   */
  nextId?: string;
  prevId?: string;
  page?: number;
}) => {
  return requestWithZod<{
    transactions: ITransaction[];
  }>({
    method: 'post',
    url: '/api/mc-api/payment/transaction',
    data: params,
    // @ts-ignore
    schema: SAnchoredData(
      z.object({
        id: z.string(),
        cryptoCurrency: SCurrencyInfoSchema,
        network: z.object({
          chain: z.string(),
          name: z.string(),
          symbol: z.string(),
          explorerUrl: z.string(),
        }),
        address: z.string(),
        txKey: z.string(),
        txhash: z.string(),
        amount: z.string(),
        txFee: z.string(),
        feeCoin: SCurrencyInfoSchema.optional(),
        addedTime: z.number().or(z.string()),
        status: z.string(),
        creditAmount: z.string(),
      }),
      'transactions',
    ),
  });
};

export const SActivityTransactionHistorySchema = z.object({
  id: z.string(),
  timestamp: z.string(),
  currency: z.string(),
  amount: z.string(),
  desc: z.string(),
});

export const getActivityHistory = ({
  pageSize = 50,
  prevId,
  nextId,
  page,
  tranType,
  // currency = 'USDT',
}) => {
  return requestWithZod({
    method: 'post',
    url: '/api/mc-api/payment/transaction/other',
    data: {
      pageSize,
      prevId,
      nextId,
      page,
      tranType,
    },
    schema: SAnchoredData(SActivityTransactionHistorySchema, 'transactions'),
  });
};

export const SRewardsTx = z.object({
  id: z.string(),
  bonusTime: z.string(),
  currency: z.string(),
  amount: z.string(),
  type: z.string(),
});

export const getRewardsTx = ({
  pageSize = 10,
  prevId,
  nextId,
  page,
  type,
  // currency = 'USDT',
}) => {
  return requestWithZod({
    method: 'post',
    url: '/api/mc-api/reward/tx',
    data: {
      pageSize,
      prevId,
      nextId,
      page,
      type,
    },
    schema: SAnchoredData(SRewardsTx),
  });
};
