import { useTranslation } from 'next-i18next';
import { useAtom, useAtomValue } from 'jotai';
import { isLoggedIn } from '@/state/userInfo';
import { isString } from 'lodash-es';
import { CListItem, CListItemIcon } from '@/components/system/AppMenus/styles';
import { Box, ListItemText, Tooltip, Typography } from '@mui/material';
import TruncatedTextTooltip from '@/components/ui/TruncatedTextTooltip';
import Zoom from '@mui/material/Zoom';
import LocalizedLink from '@/components/ui/LocalizedLink';
import React from 'react';
import { IAppMenuItem } from '@/components/system/AppMenus/index';
import { useRouter } from 'next/router';
import { GLOBAL_MODALS, globalModals } from '@/state/globalSettings';

const AppMenuItem = ({
  item,
  open,
  onMenuItemClick,
}: {
  item: IAppMenuItem;
  open: boolean;
  onMenuItemClick?: (item: IAppMenuItem) => void;
}) => {
  const { t } = useTranslation();
  const loggedIn = useAtomValue(isLoggedIn);
  const { asPath } = useRouter();
  const checkSelected = asPath.includes(item.href);
  const [modals, setModals] = useAtom(globalModals);

  const handleMenuItemClick = () => {
    onMenuItemClick?.(item);

    if (item.requireAuth && !loggedIn) {
      setModals({
        [GLOBAL_MODALS.LOGIN]: true,
      });
      return;
    }
  };

  if (isString(item.title)) {
    const withItemTitle = (
      <CListItem
        selected={checkSelected}
        disabled={item.requireAuth && !loggedIn}
        open={Boolean(open)}
        onClick={handleMenuItemClick}
      >
        {item.icon && (
          <CListItemIcon open={Boolean(open)}>{item.icon}</CListItemIcon>
        )}
        {(open && (
          <ListItemText
            primary={
              <TruncatedTextTooltip
                title={
                  open ? (
                    <Typography
                      component="span"
                      fontWeight={'500'}
                      color={(theme) => theme.palette.grey['400']}
                      fontSize={'14px'}
                    >
                      <span>
                        {isString(item.title)
                          ? t(item.title as string)
                          : item.title}
                      </span>
                    </Typography>
                  ) : (
                    ''
                  )
                }
                arrow
                placement={'right'}
              />
            }
            primaryTypographyProps={{ noWrap: true }}
          />
        )) ||
          ''}
      </CListItem>
    );

    return (
      <Tooltip
        title={item.title}
        disableHoverListener={open}
        placement={'right'}
        arrow
        TransitionComponent={Zoom}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -14],
              },
            },
          ],
        }}
      >
        <Box>
          {item.href ? (
            <LocalizedLink
              scroll={false}
              onClick={handleMenuItemClick}
              href={item.href}
              disabled={!item.href || (item.requireAuth && !loggedIn)}
              sx={(theme) => ({
                '&.active .MuiListItem-root': {
                  background: theme.palette.primary.main,
                  '.MuiListItemIcon-root, .MuiTypography-root': {
                    color: theme.palette.secondary.main,
                  },
                },
              })}
            >
              {withItemTitle}
            </LocalizedLink>
          ) : (
            withItemTitle
          )}
        </Box>
      </Tooltip>
    );
  }

  const isLanguage = React.isValidElement(item.title);

  return (
    <CListItem
      disabled={item.disabled}
      open={Boolean(open)}
      onClick={() => {
        item?.onClick?.();
        handleMenuItemClick?.();
      }}
      sx={{
        ['&:hover']: {
          background: isLanguage ? 'none' : undefined,
        },
      }}
    >
      {/*For language switcher component*/}
      {React.isValidElement(item.title)
        ? React.cloneElement<{
            open?: boolean;
            usedInTheMenuNavigation?: boolean;
          }>(item.title, {
            open,
            usedInTheMenuNavigation: true,
          })
        : item.title}
    </CListItem>
  );
};

export default AppMenuItem;
