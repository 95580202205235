// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonMailOutline: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-mail-outline"><g clipPath="url(#clip0_706_100248)"><path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19 18H5C4.45 18 4 17.55 4 17V8L10.94 12.34C11.59 12.75 12.41 12.75 13.06 12.34L20 8V17C20 17.55 19.55 18 19 18ZM12 11L4 6H20L12 11Z" fill="#F0F0F0" /></g><defs><clipPath id="clip0_706_100248"><rect width="24" height="24" fill="white" /></clipPath></defs></SvgBase>);
};
const MemoMoonMailOutline = React.memo(MoonMailOutline);
MemoMoonMailOutline.displayName = 'MoonMailOutline';
export default MemoMoonMailOutline;