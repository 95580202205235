import { UTCStringTemplate } from '@/types/common';
import {
  TBonusInfo,
  TPlayerPendingClaim,
  TPlayerSelectedPack,
  TUserInfo,
} from '@/types/user';
import { z } from 'zod';
import request, { requestWithZod } from './index';

export const getBonusConfigInfo = () => {
  return request<{
    leftBonusTimes?: number;
    finishTask: TPlayerSelectedPack[];
    finishedTask: TPlayerSelectedPack[];
    bonusRuleInfo: TBonusInfo[];
    onGoingTask: TPlayerSelectedPack;
    userInfo: TUserInfo & {
      registerTime: UTCStringTemplate;
    };
  }>({
    method: 'post',
    url: '/api/mc-api/user/bonus-pack/query',
    data: {},
  });
};

// export const getUserAllAllowSelectedPacks = () => {
//   return request<{
//     bonusRuleInfo: IBonusInfo[];
//   }>({
//     method: 'post',
//     url: '/api/mc-api/user/bonus-pack/allow-selected',
//     data: {
//       status: [1, 3],
//     },
//   });
// };

// export const getUserCurrentlySelectedPack = () => {
//   return request<IPlayerSelectedPack>({
//     method: 'post',
//     url: '/api/mc-api/user/bonus-pack/player-selected',
//     data: {},
//   });
// };

export const getUserAllPendingClaim = () => {
  return request<{
    claimPendingList: TPlayerPendingClaim[];
    claimFinishedList: TPlayerPendingClaim[];
    claimExpireList: TPlayerPendingClaim[];
  }>({
    method: 'post',
    url: '/api/mc-api/user/bonus-pack/query-claims',
    data: {},
  });
};

export const userChooseBonusPack = (data: { packId: string }) => {
  return request({
    method: 'post',
    url: '/api/mc-api/user/bonus-pack/accept',
    data,
  });
};

export const claimReward = (data: { id: string }) => {
  return request({
    method: 'post',
    url: '/api/mc-api/user/bonus-pack/claim',
    data,
  });
};

export const queryEligibleGames = ({ packId }: { packId: string }) => {
  return requestWithZod({
    method: 'post',
    url: '/api/mc-api/user/bonus-pack/eligible-games',
    data: {
      packId,
    },
    schema: z.object({
      eligibleGames: z.array(
        z.object({
          id: z.string(),
          gameId: z.string(),
          name: z.string(),
          status: z.number(),
        }),
      ),
    }),
  });
};

export const SRewardItem = z
  .object({
    availableAmount: z.number().default(0),
    bonus: z.string(),
    claimedAmount: z.number().default(0),
    coin: z.string().optional(),
    crashGamePercent: z.string().optional(),
    expireAmount: z.number().default(0),
    index: z.number().optional(),
    contribute: z.number().optional(),
    maxBonus: z.string().optional(),
    minDeposit: z.string().optional(),
    otherGamesPercent: z.string().optional(),
    percentage: z.number().optional(),
    rouletteGamesPercent: z.string().optional(),
    slotsGamesPercent: z.string().optional(),
    pendingAmount: z.number(),
    totalAmount: z.number().default(0),
    unclaimedAmount: z.number().optional().default(0),
    bonusTime: z.string().optional(),
    expireTime: z.string().optional(),
    betExpireTime: z.string().optional(),
    completedCount: z.number().optional(),
    currentInProgressIndex: z.number().optional(),
    totalPossibleCompletionCount: z.number().optional(),

    betTotal: z.string().optional(),
    bonusAmount: z.string().optional(),
    depositAmount: z.string().optional(),
    multiplier: z.string().optional(),
    withdrawAbleTotal: z.string().optional(),
    withdrawTotal: z.string().optional(),
    progress: z.string().optional(),
    grandSum: z
      .object({
        bonusSum: z.string(),
        depositSum: z.string(),
        withdrawAbleSum: z.string(),
        withdrawSum: z.string(),
        availableFromPreviousDepositBonus: z.string().optional().default('0'),
      })
      .optional(),
  })
  .refine(
    (data) => {
      if (
        (data.bonus === 'WEEKLY' || data.bonus === 'MONTHLY') &&
        !data.bonusTime &&
        data.availableAmount > 0
      ) {
        return false; // Return false if bonus is 'WEEKLY' and bonusTime is not provided
      }
      return true;
    },
    {
      // Custom error message
      message: "bonusTime is mandatory when bonus is 'WEEKLY' or 'MONTHLY'",
    },
  );

const SDepositBonusDetailSchema = z.object({
  finishTask: z.array(
    z.object({
      status: z.string(),
      depositAmount: z.number(),
      bonusAmount: z.number(),
      betTotal: z.number(),
      withdrawAbleTotal: z.number(),
      withdrawTotal: z.number(),
      startTime: z.string(),
      expireTime: z.string(),
    }),
  ),
  onGoingTask: z.object({
    status: z.string(),
    depositId: z.string().optional(),
    depositAmount: z.number(),
    bonusAmount: z.number(),
    betTotal: z.number(),
    withdrawAbleTotal: z.number(),
    withdrawTotal: z.number(),
    startTime: z.string(),
    expireTime: z.string(),
  }),
  leftBonusTimes: z.number(),
  registerTime: z.string(),
});

export const queryDailyRewardsDetail = (coin = 'USDT') => {
  return requestWithZod({
    method: 'post',
    url: '/api/mc-api/reward/detail',
    data: {
      coin,
    },
    schema: z.object({
      rewards: z.array(SRewardItem),
      totalEarned: z.number(),
    }),
  });
};

export const queryDepositBonusDetails = () => {
  return requestWithZod({
    method: 'post',
    url: '/api/mc-api/user/bonus-pack/query',
    schema: SDepositBonusDetailSchema,
  });
};

export const queryDailyRewardsList = (coin = 'USDT') => {
  return requestWithZod({
    method: 'post',
    url: '/api/mc-api/reward/list',
    data: {
      coin,
    },
    schema: z.object({
      rewards: z.array(SRewardItem),
      totalReward: z.number().or(z.string()),
    }),
  });
};

export const claimDailyReward = (bonus: string, coin = 'USDT') => {
  return requestWithZod({
    method: 'post',
    url: '/api/mc-api/reward/claim',
    data: {
      coin,
      bonus,
    },
    schema: z.object({
      bonus: z.string(),
      claimedAmount: z.number(),
      coin: z.string(),
    }),
  });
};

export const queryExpiredList = (params: any) => {
  return requestWithZod({
    method: 'post',
    url: '/api/mc-api/reward/expired-list',
    data: params,
    schema: z.object({
      rewards: z.array(
        z.object({
          bonus: z.string(),
          coin: z.string(),
          claimedAmount: z.number(),
          availableAmount: z.number(),
          pendingAmount: z.number(),
          expireAmount: z.number(),
          totalAmount: z.number(),
          bonusTime: z.string(),
          expireTime: z.string(),
          id: z.string(),
        }),
      ),
      hasNext: z.boolean(),
      nextId: z.string().optional(),
      prevId: z.string().optional(),
    }),
  });
};

export const queryDailyRewardsComposition = (coin = 'USDT') => {
  return requestWithZod({
    method: 'post',
    url: '/api/mc-api/reward/progress',
    data: {
      coin,
    },
    schema: z.object({
      progresses: z.array(
        z.object({
          bonus: z.string(),
          day: z.number(),
          totalDays: z.number(),
          amount: z.number(),
        }),
      ),
    }),
  });
};

export const cancelWelcomeBonus = () => {
  return request({
    method: 'post',
    url: '/api/mc-api/user/bonus-pack/cancel',
    data: {},
  });
};
