import TruncatedTextTooltip from '@/components/ui/TruncatedTextTooltip';
import { isLoggedIn } from '@/state/userInfo';
import { MoonArrowDropDown, MoonArrowDropUp } from '@crownbit/icons';
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { matchPath, PathPattern } from 'react-router';
import { useAtomValue, useAtom } from 'jotai';
import menusConfig from './menusConfig';
import { CListItemIcon, MenuGroupItem } from './styles';
import AppMenuItem from '@/components/system/AppMenus/AppMenuItem';
import LocalizedLink from '@/components/ui/LocalizedLink';
import { GLOBAL_MODALS, globalModals } from '@/state/globalSettings';
import { usePrevious } from 'ahooks';
import { useTheme } from '@mui/material/styles';

export interface MenusProps {
  menus?: Array<IAppMenuItem | AppMenuGroup>;
  open: boolean;
}

export interface IAppMenuItem {
  title?: React.ReactNode;
  icon?: React.ReactNode;
  hidden?: boolean;
  href?: string;
  isBetByHref?: boolean;
  disabled?: boolean;
  requireAuth?: boolean;
  onClick?: () => void;
}

export interface AppMenuGroup {
  groupTitle: string;
  hidden?: boolean;
  icon?: React.ReactNode;
  menus: Array<IAppMenuItem | AppMenuGroup | 'divider'>;
  pathPattern?: PathPattern | string;
  requireAuth?: boolean;
  closeMenuPanelWhenClick?: boolean;
  onClick?: () => void;
  href?: string;
}

const AppMenuGroup = ({
  group,
  open,
  expandedSubMenus = false,
  onMenuItemClick,
}: {
  group: AppMenuGroup;
  open: boolean;
  expandedSubMenus?: boolean;
  onMenuItemClick?: (item: IAppMenuItem) => void;
}) => {
  const { t } = useTranslation();
  const loggedIn = useAtomValue(isLoggedIn);
  const router = useRouter();
  const { pathname = '/', route } = router;
  const isActive =
    group?.pathPattern && !!matchPath(group?.pathPattern, pathname || '');
  const [modals, setModals] = useAtom(globalModals);
  const [expanded, setExpanded] = useState(expandedSubMenus || isActive);
  const hasSubMenus = group?.menus?.length > 0;
  const groupSubMenus = group?.menus?.filter((item) => {
    // @ts-ignore
    return !item?.hidden;
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  /**
   * When the user clicks on the casino/sports menu, the sub menus should be expanded,
   * keep the state of the casino/sports menu when open other pages
   */
  const prePathname = usePrevious(pathname);
  const sportsOrCasino = ['/sports', '/casino'];
  const isPathSportOrCasino = (path) => sportsOrCasino.includes(path);
  const closeSubMenus =
    isPathSportOrCasino(prePathname) &&
    isPathSportOrCasino(pathname) &&
    prePathname !== pathname;

  useEffect(() => {
    if (closeSubMenus && !isActive && !expandedSubMenus) {
      setExpanded(false);
    }
  }, [isActive, expandedSubMenus, closeSubMenus]);

  const handleExpandClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.isPropagationStopped();
    e.stopPropagation();
    setExpanded(!expanded);
  };

  const onClickMenuGroupItem = async (menuGroupItem: AppMenuGroup) => {
    // Can't be removed, when clicking casino/sports on the group menu you need to expand all the sub menus
    setExpanded(true);

    group?.closeMenuPanelWhenClick && onMenuItemClick?.(group);
    group?.onClick?.();

    if (menuGroupItem.requireAuth && !loggedIn) {
      setModals({
        [GLOBAL_MODALS.LOGIN]: true,
      });
      return;
    }

    if (menuGroupItem.href) {
      return await router.push(menuGroupItem.href);
    }
  };

  const groupMenuItemWithHref = (
    <MenuGroupItem
      disabled={group.requireAuth && !loggedIn}
      open={open}
      selected={isActive}
      onClick={() => onClickMenuGroupItem(group)}
    >
      {group.icon && (
        <CListItemIcon
          sx={{
            marginRight: open ? 1 : 0,
          }}
        >
          {group.icon}
        </CListItemIcon>
      )}
      {(open && (
        <ListItemText
          primary={
            <TruncatedTextTooltip
              title={
                open ? (
                  <Typography
                    component={'span'}
                    fontSize={'14px'}
                    fontWeight={'500'}
                    color={(theme) => theme.palette.text.primary}
                  >
                    {t(group.groupTitle)}
                  </Typography>
                ) : (
                  ''
                )
              }
              arrow
              placement={'right'}
            />
          }
          primaryTypographyProps={{ noWrap: true }}
        />
      )) ||
        ''}
      {open && hasSubMenus ? (
        <Box
          sx={(theme) => ({
            color: theme.palette.text.secondary,
          })}
        >
          <IconButton
            size="small"
            onClick={handleExpandClick}
            sx={{
              padding: 0,
            }}
          >
            {expanded ? (
              <MoonArrowDropUp width={'20xp'} />
            ) : (
              <MoonArrowDropDown width={'20px'} />
            )}
          </IconButton>
        </Box>
      ) : (
        ''
      )}
    </MenuGroupItem>
  );

  return (
    <>
      <Tooltip
        title={group.groupTitle}
        disableHoverListener={isMobile}
        TransitionComponent={Zoom}
        placement={'right'}
        arrow
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -14],
              },
            },
          ],
        }}
      >
        {group.href ? (
          <LocalizedLink
            href={group.href}
            disabled={!group.href || (group.requireAuth && !loggedIn)}
          >
            {groupMenuItemWithHref}
          </LocalizedLink>
        ) : (
          groupMenuItemWithHref
        )}
      </Tooltip>
      {hasSubMenus ? (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {groupSubMenus?.map((menu, index) => {
              if (menu === 'divider') {
                return (
                  <Divider
                    key={index}
                    sx={(theme) => ({
                      margin: theme.spacing(1, 0),
                      borderColor: theme.palette.grey['08'],
                    })}
                  />
                );
              }

              if ('groupTitle' in menu) {
                return (
                  <AppMenuGroup
                    key={index}
                    group={menu}
                    open={open}
                    expandedSubMenus={expanded}
                    onMenuItemClick={onMenuItemClick}
                  />
                );
              }

              return (
                <React.Fragment key={index}>
                  <AppMenuItem
                    open={open}
                    item={menu}
                    onMenuItemClick={onMenuItemClick}
                  />
                </React.Fragment>
              );
            })}
          </List>
        </Collapse>
      ) : (
        ''
      )}
    </>
  );
};

export const AppMenus = ({
  open,
  onMenuItemClick,
}: {
  open: boolean;
  onMenuItemClick?: (menuItem: IAppMenuItem) => void;
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  return (
    <>
      <List
        sx={{
          paddingTop: 0,
        }}
      >
        {menusConfig(router, t)
          .filter((item) => !item?.hidden)
          .map((item, index) => {
            if ('groupTitle' in item) {
              return (
                <AppMenuGroup
                  key={index}
                  group={item}
                  open={open}
                  onMenuItemClick={onMenuItemClick}
                />
              );
            }

            return (
              <React.Fragment key={index}>
                <AppMenuItem
                  open={open}
                  item={item}
                  onMenuItemClick={onMenuItemClick}
                />
              </React.Fragment>
            );
          })}
      </List>
    </>
  );
};

export default AppMenus;
