import { getUserProfile } from '@/apis/profile';
import { getBonusConfigInfo, getUserAllPendingClaim } from '@/apis/rewards';

import {
  isTemporaryLoginWithoutToken,
  isLoggedIn,
  updateUserBonusInfo,
  updateUserInfoMeta,
  userWalletInfoState,
} from '@/state/userInfo';
import { useCallback, useState } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import { getUserWalletInfo } from '@/apis/wallet';
import { useInfiniteQuery } from '@tanstack/react-query';
import { fetchFavouriteGames, fetchGameVisitList } from '@/apis/casino/games';
import queryKeysFactory from '@/reactQueryKeys/keysFactory';
import { useTranslation } from 'next-i18next';
import { useAtomValue } from 'jotai/index';
import { store } from '@/state/store';

export enum UPDATE_USER_INFO_MODE {
  DETAIL_USER_INFO,
  WALLET,
  BONUS,
  BONUS_PENDING_CLAIM,
  CASINO_USER_FAVORITES,
  CASINO_USER_RECENT_PLAYED,
}

const useUpdateUserInfoAsync = () => {
  const [userInfo, setUserInfo] = useAtom(updateUserInfoMeta,{store:store});

  const isTemporaryLogin = useAtomValue(isTemporaryLoginWithoutToken,{store:store});

  const loggedIn = useAtomValue(isLoggedIn,{store:store});

  const setBonusInfo = useSetAtom(updateUserBonusInfo,{store:store});

  const setWalletInfo = useSetAtom(userWalletInfoState,{store:store});

  const [updateFavorites, setUpdateFavorites] = useState(false);

  const [updateRecentPlayed, setUpdateRecentPlayed] = useState(false);

  const [t] = useTranslation();

  useInfiniteQuery({
    queryKey: queryKeysFactory.casino.queryFavoriteGames() as any,
    queryFn: ({ pageParam }) => {
      return fetchFavouriteGames(pageParam);
    },
    getNextPageParam: (data, allPages) => {
      if (data?.nextId) {
        return {
          nextId: data?.nextId,
        };
      } else {
        return undefined;
      }
    },
    enabled: isLoggedIn && updateFavorites,
  });

  useInfiniteQuery({
    queryKey: queryKeysFactory.casino.queryUserFootprint() as any,
    queryFn: ({ pageParam }) => {
      return fetchGameVisitList(pageParam);
    },
    getNextPageParam: (data, allPages) => {
      if (data?.nextId) {
        return {
          nextId: data.nextId,
        };
      } else {
        return undefined;
      }
    },
    enabled: isLoggedIn && updateFavorites,
  });

  const updateAsync = useCallback(
    (
      updateMode:
        | UPDATE_USER_INFO_MODE[]
        | UPDATE_USER_INFO_MODE = UPDATE_USER_INFO_MODE.DETAIL_USER_INFO,
      extraData?: any,
    ) => {
      if (!loggedIn && !isTemporaryLogin) {
        setUserInfo({
            userMetaData: undefined,
            isUserLoading: false,
        });
        return;
      }
      if (!Array.isArray(updateMode)) {
        updateMode = [updateMode];
      }
      if (updateMode.includes(UPDATE_USER_INFO_MODE.DETAIL_USER_INFO)) {
        if (extraData) {
          setUserInfo({
            userMetaData: extraData,
            isUserLoading: false,
          });
        } else {
          getUserProfile().then((data) => {
            if (!data.data) return;
            setUserInfo({
              userMetaData: data.data,
              isUserLoading: false,
            });
          });
        }
      }
      if (updateMode.includes(UPDATE_USER_INFO_MODE.BONUS)) {
        getBonusConfigInfo().then((data) => {
          setBonusInfo({
            allBonusPacks: data?.data?.bonusRuleInfo ?? [],
            userInfo: {
              ...data?.data?.userInfo,
              // registerTime: new Date().getTime() - 9999999999999,
            },
            currentSelectedPack: data?.data?.onGoingTask,
            finishTask: data?.data?.finishTask,
            leftBonusTimes: data?.data?.leftBonusTimes,
            loaded: true,
          });
        });
      }
      if (updateMode.includes(UPDATE_USER_INFO_MODE.BONUS_PENDING_CLAIM)) {
        getUserAllPendingClaim().then((data) => {
          setBonusInfo({
            rewards: {
              playerPendingClaimRewards: data?.data?.claimPendingList ?? [],
              playerClaimedRewards: data?.data?.claimFinishedList ?? [],
              playerExpiredRewards: data?.data?.claimExpireList ?? [],
            },
          });
        });
      }
      if (updateMode.includes(UPDATE_USER_INFO_MODE.WALLET)) {
        let retryTimes = 0;
        const getWallets = () => {
          retryTimes++;
          if (retryTimes > 5) {
            clearInterval(timer);
            // return toast.error(
            //   t('Failed to get wallet info, please try again later.'),
            // );
          }
          getUserWalletInfo().then(({ data }) => {
            if (data?.accounts) {
              setWalletInfo({
                wallets: data?.accounts as any,
              });
            }
            clearInterval(timer);
          });
        };

        getWallets();

        const timer = setInterval(() => {
          getWallets();
        }, 1000 * 10);
      }
      if (updateMode.includes(UPDATE_USER_INFO_MODE.CASINO_USER_FAVORITES)) {
        setUpdateFavorites(true);
      }
      if (
        updateMode.includes(UPDATE_USER_INFO_MODE.CASINO_USER_RECENT_PLAYED)
      ) {
        setUpdateRecentPlayed(true);
      }
    },
    [loggedIn, setBonusInfo, setUserInfo, setWalletInfo],
  );

  return [userInfo, updateAsync] as const;
};

export default useUpdateUserInfoAsync;
