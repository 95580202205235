import { THEME_MODE_ENU } from '@/constants/global';
import { queryClient } from '@/reactQueryKeys/queryClient';
import useTheme from '@/theme/useTheme';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import { useMediaQuery } from '@mui/material';
import { Toaster } from 'react-hot-toast';

/**
 * this context is for convenient use in components; you can just use useTracked to change the state
 */

const GlobalConfigContextWrapper = ({
  children,
  themeMode,
}: {
  children: React.ReactNode;
  themeMode: THEME_MODE_ENU;
}) => {
  const { colorMode, theme } = useTheme(themeMode);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <QueryClientProvider client={queryClient}>
      {/*<WebSocketContext.Provider value={null}>*/}
      <ThemeProvider theme={theme}>
        {children}
        <Toaster
          position={isMobile ? 'bottom-center' : 'top-right'}
          gutter={8}
          containerStyle={{
            bottom: isMobile ? '82px' : 'unset',
          }}
          toastOptions={{
            duration: 3000,
            style: {
              background: '#0C130D',
              color: '#CCE8CD',
            },
          }}
        />
      </ThemeProvider>
      {/*</WebSocketContext.Provider>*/}
    </QueryClientProvider>
  );
};

export default GlobalConfigContextWrapper;

// export const useGlobalSettersContext = () => {
//   return useContext(GlobalSettersContext);
// };
