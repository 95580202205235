// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonCryptoTokenDaii: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-crypto-token-daii"><circle cx="48" cy="48" r="48" fill="#F4B731" /><path fillRule="evenodd" clipRule="evenodd" d="M26.9275 21H48.7463C62.0181 21 72.0782 28.1335 75.8209 38.5135H82.6175V44.7874H77.2521C77.3571 45.7789 77.4107 46.7889 77.4107 47.8151V47.9692C77.4107 49.1245 77.343 50.2603 77.2103 51.3726H82.6175V57.6464H75.6915C71.8502 67.8821 61.8681 74.9475 48.7463 74.9475H26.9275V57.6464H19.3457V51.3726H26.9275V44.7874H19.3457V38.5135H26.9275V21ZM33.0272 57.6464V69.3194H48.7463C58.4465 69.3194 65.6534 64.6442 69.0081 57.6464H33.0272ZM70.8767 51.3726H33.0272V44.7874H70.8864C71.0268 45.8238 71.0984 46.8861 71.0984 47.9692V48.1233C71.0984 49.2314 71.0235 50.3162 70.8767 51.3726ZM48.7463 26.6188C58.4912 26.6188 65.7196 31.4174 69.0541 38.5135H33.0272V26.6188H48.7463Z" fill="white" /></SvgBase>);
};
const MemoMoonCryptoTokenDaii = React.memo(MoonCryptoTokenDaii);
MemoMoonCryptoTokenDaii.displayName = 'MoonCryptoTokenDaii';
export default MemoMoonCryptoTokenDaii;