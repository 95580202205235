import { atomWithImmer } from 'jotai-immer';
import { atom } from 'jotai';

export const gameSettingState = atomWithImmer<{
  animating: boolean;
  volume: number;
  theaterMode: boolean;
  maxBetMode: boolean;
}>({
  animating: false,
  theaterMode: false,
  volume: 0.5,
  maxBetMode: false,
});
export const isTheaterModeOn = atom((get) => get(gameSettingState).theaterMode);
