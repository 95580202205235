// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonCryptoTokenTrx2: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-crypto-token-trx2"><g clipPath="url(#clip0_476_98175)"><path d="M48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0Z" fill="white" /><path d="M78 41.2808C75 38.5108 70.85 34.2808 67.47 31.2808L67.27 31.1409C66.9372 30.8736 66.562 30.6641 66.16 30.5209C58.01 29.0009 20.08 21.9108 19.34 22.0008C19.1326 22.0299 18.9345 22.1051 18.76 22.2208L18.57 22.3708C18.336 22.6085 18.1584 22.8955 18.05 23.2108L18 23.3408V24.0508V24.1608C22.27 36.0508 39.13 75.0009 42.45 84.1409C42.65 84.7608 43.03 85.9408 43.74 86.0008H43.9C44.28 86.0008 45.9 83.8609 45.9 83.8609C45.9 83.8609 74.86 48.7408 77.79 45.0008C78.1693 44.5402 78.5041 44.0446 78.79 43.5208C78.863 43.111 78.8286 42.6893 78.6901 42.2968C78.5517 41.9042 78.3139 41.5542 78 41.2808ZM53.33 45.3708L65.69 35.1208L72.94 41.8008L53.33 45.3708ZM48.53 44.7008L27.25 27.2608L61.68 33.6108L48.53 44.7008ZM50.45 49.2708L72.23 45.7608L47.33 75.7608L50.45 49.2708ZM24.36 29.0008L46.75 48.0008L43.51 75.7808L24.36 29.0008Z" fill="#FF060A" /></g><defs><clipPath id="clip0_476_98175"><rect width="96" height="96" fill="white" /></clipPath></defs></SvgBase>);
};
const MemoMoonCryptoTokenTrx2 = React.memo(MoonCryptoTokenTrx2);
MemoMoonCryptoTokenTrx2.displayName = 'MoonCryptoTokenTrx2';
export default MemoMoonCryptoTokenTrx2;