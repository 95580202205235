// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyFigureskating: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-figureskating"><g id="Property 1=sport-figureskating"><path id="Vector" d="M22.1477 14.6816L23.4421 15.976L25.7048 13.7133C26.2298 13.1883 27.0811 13.1883 27.6062 13.7133C28.1313 14.2384 28.1313 15.0897 27.6062 15.6148L24.4371 18.7839C24.1633 19.0577 23.8008 19.1887 23.4421 19.1769C23.0834 19.1887 22.7209 19.0577 22.4471 18.7839L19.278 15.6148C19.2266 15.5633 19.1802 15.5088 19.1388 15.4517H15.6382V24.447H15.7597C16.2548 24.447 16.6561 24.8483 16.6561 25.3434H18.0006C18.4956 25.3434 18.897 25.7447 18.897 26.2397C18.897 26.7348 18.4956 27.1361 18.0006 27.1361H12.6225C12.1275 27.1361 11.7261 26.7348 11.7261 26.2397C11.7261 25.7447 12.1275 25.3434 12.6225 25.3434H13.0374C12.9804 25.1943 12.9492 25.0325 12.9492 24.8634V13.7677L7.62545 8.44398C7.6121 8.43063 7.5991 8.41708 7.58643 8.40332C7.22442 8.58263 6.7737 8.49895 6.5025 8.17575L5.47254 9.04C5.09331 9.3582 4.52793 9.30874 4.20973 8.92952C3.89152 8.55029 3.94099 7.98491 4.32021 7.66671L8.44008 4.20973C8.8193 3.89152 9.38468 3.94099 9.70289 4.32021C10.0211 4.69943 9.97163 5.26481 9.5924 5.58302L9.24908 5.8711C9.39731 6.04775 9.46576 6.2648 9.45825 6.47845C9.48169 6.49886 9.50459 6.52022 9.5269 6.54253L15.747 12.7626H20.4351C20.4899 12.6724 20.5563 12.5873 20.6342 12.5094L25.7048 7.43888C26.2298 6.91381 27.0811 6.91381 27.6062 7.43888C28.1313 7.96395 28.1313 8.81526 27.6062 9.34033L22.5357 14.4109C22.4199 14.5266 22.2882 14.6169 22.1477 14.6816ZM19.2236 11.8653C18.2336 11.8653 17.4309 11.0629 17.4309 10.0731C17.4309 9.08327 18.2336 8.28086 19.2236 8.28086C20.2137 8.28086 21.0163 9.08327 21.0163 10.0731C21.0163 11.0629 20.2137 11.8653 19.2236 11.8653Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyFigureskating = React.memo(BetbyFigureskating);
MemoBetbyFigureskating.displayName = 'BetbyFigureskating';
export default MemoBetbyFigureskating;