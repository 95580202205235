import { queryDailyRewardsList } from '@/apis/rewards';
import Dropdown from '@/components/ui/Dropdown';
import queryKeysFactory from '@/reactQueryKeys/keysFactory';
import { appSidebarOpenState } from '@/state/globalSettings';
import { isLoggedIn } from '@/state/userInfo';
import useTheme from '@/theme/useTheme';
import { MoonArrowDropDown, MoonReward, StickiesReward } from '@crownbit/icons';
import { CardGiftcard } from '@mui/icons-material';
import { Box, Chip, CircularProgress, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import useBindEmailOrLogin from '@/hooks/useBindEmailOrLogin';
import { APP_SIDE_BAR_EXPAND_WIDTH } from '@/constants/global';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { queryActivityQueue } from '@/apis/promotions';

const RewardsPopup = dynamic(
  () => import('@/components/biz/rewards/rewardsPopup'),
  {
    ssr: false,
    loading: () => (
      <Stack alignItems={'center'} paddingY={2} paddingX={6} width={250}>
        <CircularProgress size={24} />
      </Stack>
    ),
  },
);

const RewardsDropdown = () => {
  const { t } = useTranslation();
  const { requirementToBindEmailOrLogin } = useBindEmailOrLogin();
  const appSidebarOpen = useAtomValue(appSidebarOpenState);
  const { theme } = useTheme();
  const { push } = useRouter();

  const userLogin = useAtomValue(isLoggedIn);

  const { data, refetch } = useQuery(
    queryKeysFactory.rewards.dailyRewardsList(),
    () => queryDailyRewardsList(),
    {
      enabled: userLogin,
      refetchOnMount: 'always',
    },
  );

  const { refetch: refetchQueue } = useQuery(
    queryKeysFactory.rewards.activityQueue(),
    () => {
      return queryActivityQueue().then((res) => {
        return res.activities;
      });
    },
    {
      enabled: userLogin,
      staleTime: 0,
    },
  );

  // useEffect(() => {
  //   if (userLogin) {
  //     refetch();
  //     // refreshAsync();
  //   }
  // }, [userLogin]);

  const rewardsLength = data?.data?.totalReward || 0;

  return (
    <Dropdown
      sx={(theme) => ({
        paddingTop: 2,
      })}
      triggerElement={({ open, anchorRef, onClick }) => {
        return (
          <Box
            onClick={(e) => {
              // if (requirementToBindEmailOrLogin()) return;
              if (!userLogin) {
                return push('/benefits');
              }
              e.stopPropagation();
              onClick();
              refetch();
              refetchQueue();
            }}
            flexGrow={1}
            sx={{
              display: 'flex',
              gap: appSidebarOpen ? 1 : 0,
              cursor: 'pointer',
              px: appSidebarOpen ? 1 : 0,
              paddingRight: appSidebarOpen ? 1.5 : 0,
              justifyContent: appSidebarOpen ? 'space-between' : 'center',
              alignItems: 'center',
              color: isLoggedIn ? 'inherit' : theme.palette.grey[500],
            }}
            ref={anchorRef}
          >
            <MoonReward />
            <Typography
              sx={{
                display: appSidebarOpen ? undefined : 'none',

                color: 'inherit',
                flex: 1,
              }}
              component={'span'}
            >
              {t('Rewards')}
              <Typography
                component={'span'}
                sx={{
                  display: Number(rewardsLength) > 0 ? undefined : 'none',
                  marginInlineStart: 1,
                }}
              >
                <Chip
                  label={rewardsLength}
                  size="small"
                  sx={{
                    aspectRatio: '1',
                  }}
                ></Chip>
              </Typography>
            </Typography>

            <MoonArrowDropDown
              style={{
                transform: open ? 'rotate(180deg)' : undefined,
                justifySelf: 'end',
                display: appSidebarOpen ? undefined : 'none',
              }}
            />
          </Box>
        );
      }}
      placement="bottom"
    >
      {({ open, handleClose }) => {
        return <RewardsPopup handleClose={handleClose}></RewardsPopup>;
      }}
    </Dropdown>
  );
};

export default RewardsDropdown;
