// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonCryptoTokenDai: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-crypto-token-dai"><circle cx="48" cy="48" r="48" fill="#F4B731" /><path fillRule="evenodd" clipRule="evenodd" d="M28.9501 23H48.951C61.117 23 70.3388 29.6115 73.7697 39.232H80V45.0468H75.0817C75.1779 45.9657 75.2271 46.9018 75.2271 47.8529V47.9958C75.2271 49.0665 75.165 50.1192 75.0433 51.1501H80V56.9649H73.6511C70.1299 66.4516 60.9794 73 48.951 73H28.9501V56.9649H22V51.1501H28.9501V45.0468H22V39.232H28.9501V23ZM34.5416 56.9649V67.7837H48.951C57.8429 67.7837 64.4493 63.4506 67.5246 56.9649H34.5416ZM69.2375 51.1501H34.5416V45.0468H69.2464C69.375 46.0073 69.4407 46.9919 69.4407 47.9958V48.1386C69.4407 49.1656 69.372 50.1711 69.2375 51.1501ZM48.951 28.2077C57.8839 28.2077 64.5101 32.6551 67.5667 39.232H34.5416V28.2077H48.951Z" fill="#FFFDFA" /></SvgBase>);
};
const MemoMoonCryptoTokenDai = React.memo(MoonCryptoTokenDai);
MemoMoonCryptoTokenDai.displayName = 'MoonCryptoTokenDai';
export default MemoMoonCryptoTokenDai;