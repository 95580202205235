// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyCycling: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-cycling"><g id="Property 1=sport-cycling"><path id="Vector" d="M17.7366 14.9495C18.2112 15.2389 18.4596 15.8159 18.3085 16.38L17.1993 20.5197C17.0155 21.2056 16.3105 21.6126 15.6246 21.4288C14.9387 21.245 14.5317 20.54 14.7155 19.8542L15.5952 16.5708L11.9344 14.3827C11.3409 14.0279 11.142 13.2624 11.4877 12.6636C11.4908 12.6582 11.4939 12.6529 11.4971 12.6476C11.5658 12.5327 11.6503 12.433 11.7462 12.3498C11.7528 12.3429 11.7595 12.3361 11.7663 12.3293L16.615 7.48057C16.9035 7.19205 17.2951 7.06932 17.6712 7.11238C18.0474 7.06932 18.4389 7.19205 18.7274 7.48057L23.5761 12.3293C24.0782 12.8314 24.0782 13.6455 23.5761 14.1476C23.074 14.6497 22.26 14.6497 21.7579 14.1476L17.6712 10.0609L14.6377 13.0944L17.6693 14.9064C17.6923 14.9202 17.7148 14.9345 17.7366 14.9495ZM9.14286 16.8571C11.979 16.8571 14.2857 19.1643 14.2857 22C14.2857 24.8368 11.9789 27.1429 9.14286 27.1429C6.3058 27.1429 4 24.8367 4 22C4 19.1643 6.30675 16.8571 9.14286 16.8571ZM9.14286 18.5714C7.25359 18.5714 5.71429 20.111 5.71429 22C5.71429 23.89 7.25263 25.4286 9.14286 25.4286C11.0323 25.4286 12.5714 23.8899 12.5714 22C12.5714 20.111 11.0321 18.5714 9.14286 18.5714ZM17.7143 22C17.7143 19.1633 20.0198 16.8571 22.8567 16.8571C25.6933 16.8571 28 19.1641 28 22C28 24.8372 25.6939 27.1429 22.8567 27.1429C20.0195 27.1429 17.7143 24.8373 17.7143 22ZM19.4286 22C19.4286 23.8906 20.9664 25.4286 22.8567 25.4286C24.7472 25.4286 26.2857 23.8904 26.2857 22C26.2857 20.1108 24.7465 18.5714 22.8567 18.5714C20.9667 18.5714 19.4286 20.11 19.4286 22ZM21.1429 7.42857C20.1961 7.42857 19.4286 6.66106 19.4286 5.71429C19.4286 4.76751 20.1961 4 21.1429 4C22.0896 4 22.8571 4.76751 22.8571 5.71429C22.8571 6.66106 22.0896 7.42857 21.1429 7.42857Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyCycling = React.memo(BetbyCycling);
MemoBetbyCycling.displayName = 'BetbyCycling';
export default MemoBetbyCycling;