import * as casino from './modules/casino';
import * as sports from './modules/sports';
import * as rewards from './modules/rewards';

const queryKeysFactory = {
  userInfo: () => ['userInfo'],
  twoFactorAuth: () => ['twoFactorAuth'],
  loginHistory: (page = 1) => ['loginHistory', page],
  gameList: (vendor: string, page = 1) => ['gameList', vendor, page],
  searchGame: (keyWord: string) => ['searchGame', keyWord],
  gameURL: (provider: string, gameId: string, currency: string) => ['gameURL', provider, gameId, currency],
  sports,
  casino,
  rewards,
};

export default queryKeysFactory;
