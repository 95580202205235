// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonCryptoTokenXrp: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-crypto-token-xrp"><path d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z" fill="#FFFDFA" /><path d="M68.4703 24H76.8537L59.4121 41.2613C53.0984 47.5098 42.8621 47.5098 36.5485 41.2613L19.1069 24H27.4902L40.7401 37.1131C44.7387 41.0703 51.2217 41.0703 55.2204 37.1131L68.4703 24Z" fill="#23292F" /><path d="M27.3833 72.0006H19L36.5493 54.6325C42.8629 48.3842 53.0992 48.3842 59.4129 54.6325L76.9623 72.0006H68.5789L55.2211 58.7808C51.2225 54.8235 44.7395 54.8235 40.7409 58.7808L27.3833 72.0006Z" fill="#23292F" /></SvgBase>);
};
const MemoMoonCryptoTokenXrp = React.memo(MoonCryptoTokenXrp);
MemoMoonCryptoTokenXrp.displayName = 'MoonCryptoTokenXrp';
export default MemoMoonCryptoTokenXrp;