import { atom } from 'jotai';

export const deviceInfoAtom = atom<{
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}>({
  isMobile: false,
  isTablet: false,
  isDesktop: false,
});
