// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyRowing: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-rowing"><g id="Property 1=sport-rowing"><path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M28.4912 3.51249C24.3907 1.91611 17.9996 4.11268 12.823 9.21609L7.67603 7.82707C7.53305 7.29149 7.11827 6.84518 6.54814 6.69132L5.0078 6.27562C4.1571 6.04604 3.28268 6.55451 3.05473 7.41132C2.82679 8.26814 3.33163 9.14884 4.18234 9.37842L5.72267 9.79412C6.29283 9.94799 6.87363 9.77033 7.26333 9.37848L11.5675 10.5401C9.33907 13.0562 7.76189 15.7985 6.92788 18.3984C5.54386 22.7128 9.4278 26.6246 13.7115 25.2307C16.255 24.4029 18.9359 22.8485 21.4039 20.6558L22.4815 24.7062C22.0926 25.0987 21.9163 25.6836 22.069 26.2578L22.4817 27.8092C22.7097 28.666 23.5841 29.1745 24.4348 28.9449C25.2855 28.7153 25.7904 27.8346 25.5624 26.9778L25.1497 25.4264C24.9969 24.8521 24.5537 24.4343 24.0218 24.2903L22.7215 19.4025C27.8646 14.1696 30.0872 7.67135 28.4912 3.51249ZM21.5003 14.8125L22.1156 17.1251C24.0625 14.946 25.452 12.6104 26.241 10.4221C26.9885 8.34897 27.1559 6.5353 26.8843 5.13089C25.4899 4.85732 23.6892 5.02599 21.6308 5.77883C19.4973 6.55917 17.2225 7.92279 15.0925 9.82858L17.379 10.4456L17.7085 10.1138C18.8761 8.93777 20.7693 8.93777 21.937 10.1138C23.1046 11.2899 23.1046 13.1967 21.937 14.3727L21.5003 14.8125ZM16.077 11.757L13.746 11.128C11.3063 13.6648 9.64392 16.4618 8.82467 19.0156C7.94046 21.772 10.3619 24.2108 13.0986 23.3202C15.5981 22.5069 18.332 20.8684 20.822 18.4683L20.1983 16.1239L18.8361 17.4959C17.6684 18.672 15.7752 18.672 14.6075 17.4959C13.4399 16.3198 13.4399 14.4131 14.6075 13.237L16.077 11.757ZM18.977 11.3915C19.4441 10.9211 20.2013 10.9211 20.6684 11.3915C21.1355 11.8619 21.1355 12.6246 20.6684 13.095L17.5675 16.2182C17.1004 16.6887 16.3432 16.6887 15.8761 16.2182C15.409 15.7478 15.409 14.9851 15.8761 14.5147L18.977 11.3915Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyRowing = React.memo(BetbyRowing);
MemoBetbyRowing.displayName = 'BetbyRowing';
export default MemoBetbyRowing;