import { THEME_MODE_ENU } from '@/constants/global';
import { Components } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import componentsOverrides from './overrides';
import palette from './palette';
import typography from './typography';
// export const ColorModeContext = createContext({
//   toggleColorMode: () => {},
// });

declare module '@mui/material/styles/createMixins' {
  // Allow for custom mixins to be added
  interface Mixins {
    onHover?: CSSProperties;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    standout: true;
    plain: true;
  }
}

// declare module '@mui/material/typography' {
//   interface TypographyPropsVariantOverrides {
//     secondary: true;
//   }
// }

export const useTheme = (themeModeProp?: THEME_MODE_ENU) => {
  //  enable dark mode automatically by checking for the user's preference in their OS or browser settings
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // const colorMode = useContext(ColorModeContext);
  const [themeMode, setThemeMode] = useState<THEME_MODE_ENU>(
    themeModeProp,
    // prefersDarkMode ? 'dark' : 'light'
  );

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setThemeMode((prevMode) =>
          prevMode === THEME_MODE_ENU.Dark
            ? THEME_MODE_ENU.Light
            : THEME_MODE_ENU.Dark,
        );
      },
    }),
    [],
  );

  const isDarkMode = themeMode === THEME_MODE_ENU.Dark;

  const themeOptions = useMemo(
    () => ({
      palette: {
        mode: themeMode,
        ...palette(themeMode).palette,
      },
      mixins: {
        onHover: {
          background: 'rgba(255, 255, 255, 0.08)',
        },
      },
      shape: {
        borderRadius: 8,
      },
      typography: typography(palette(themeMode).palette),
    }),
    [themeMode],
  ) as CustomThemeOptions;

  // const theme = useMemo(
  //   () => responsiveFontSizes(createTheme(themeOptions)),
  //   [themeOptions],
  // );

  const theme = responsiveFontSizes(createTheme(themeOptions));

  theme.components = componentsOverrides(theme) as Components;

  useEffect(() => {
    setThemeMode(themeModeProp);
  }, [themeModeProp]);
  // useEffect(() => {
  //   setThemeMode(prefersDarkMode ? 'dark' : 'light');
  // }, [prefersDarkMode]);

  return { theme, colorMode };
};

export default useTheme;
