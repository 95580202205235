// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonProfile: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-profile"><path d="M10.4987 3.3335C11.3828 3.3335 12.2306 3.68469 12.8557 4.30981C13.4808 4.93493 13.832 5.78277 13.832 6.66683C13.832 7.55088 13.4808 8.39873 12.8557 9.02385C12.2306 9.64897 11.3828 10.0002 10.4987 10.0002C9.61464 10.0002 8.7668 9.64897 8.14167 9.02385C7.51655 8.39873 7.16536 7.55088 7.16536 6.66683C7.16536 5.78277 7.51655 4.93493 8.14167 4.30981C8.7668 3.68469 9.61464 3.3335 10.4987 3.3335ZM10.4987 11.6668C14.182 11.6668 17.1654 13.1585 17.1654 15.0002V16.6668H3.83203V15.0002C3.83203 13.1585 6.81536 11.6668 10.4987 11.6668Z" fill="white" fillOpacity="1" /></SvgBase>);
};
const MemoMoonProfile = React.memo(MoonProfile);
MemoMoonProfile.displayName = 'MoonProfile';
export default MemoMoonProfile;