import { Tab } from '@/components/ui/BasicTabs';
import { APP_SIDE_BAR_EXPAND_WIDTH } from '@/constants/global';
import { styled } from '@mui/material/styles';

export const SideBarWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0),
  justifyContent: 'space-between',
}));
