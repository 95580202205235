// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const CasinoRecent: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-casino-recent"><path d="M0.727111 7.85352C0.0327752 7.85352 -0.143006 8.38086 0.234924 8.89941L2.37945 11.8701C2.67828 12.2832 3.13531 12.2744 3.42535 11.8701L5.56988 8.89062C5.93903 8.38965 5.77203 7.85352 5.08649 7.85352H3.81207C4.61188 4.69824 7.45074 2.38672 10.8697 2.38672C14.9039 2.38672 18.1382 5.62109 18.1382 9.65527C18.1382 13.6895 14.9039 16.9414 10.8697 16.9326C8.52301 16.9238 6.46637 15.8252 5.14801 14.0938C4.78766 13.6807 4.26031 13.5488 3.82965 13.8652C3.42535 14.1553 3.34625 14.7266 3.74176 15.21C5.35895 17.3193 8.03082 18.7432 10.8697 18.7432C15.8443 18.7432 19.9576 14.6299 19.9576 9.65527C19.9576 4.68066 15.8443 0.567383 10.8697 0.567383C6.50152 0.567383 2.80133 3.72266 1.95758 7.85352H0.727111ZM10.5445 4.56641C10.1138 4.56641 9.77106 4.90918 9.77106 5.33105V10.0947C9.77106 10.3057 9.84137 10.499 9.99078 10.7012L12.0826 13.4609C12.399 13.874 12.856 13.9443 13.2339 13.6719C13.5943 13.417 13.6295 12.9512 13.3482 12.5732L11.3091 9.83105V5.33105C11.3091 4.90918 10.9664 4.56641 10.5445 4.56641Z" fill="white" fillOpacity="0.7" /></SvgBase>);
};
const MemoCasinoRecent = React.memo(CasinoRecent);
MemoCasinoRecent.displayName = 'CasinoRecent';
export default MemoCasinoRecent;