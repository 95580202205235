import { isTheaterModeOn } from '@/state/gameSetting';
import useTheme from '@/theme/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from '@/components/system/AppBar';
import dynamic from 'next/dynamic';
import { ReactNode, useEffect } from 'react';
import { useAtomValue } from 'jotai';
import { AppCoreContainerStyle } from './styles';
import { useRouter } from 'next/router';
import { useCheckInvitationCode } from '@/hooks/invitation';
import { useZendeskStatus } from '@/hooks/biz/useZendeskStatus';
import busEvents from '@/helper/busEvents';
import useUserLoginCallbackHandler from '@/hooks/biz/useUserLoginCallbackHandler';
import { useMount } from 'ahooks';

const AppMobileBottomBar = dynamic(
  () => import('@/components/system/AppCoreContainer/AppMobileBottomBar'),
  {
    ssr: false,
  },
);

const Footer = dynamic(() => import('@/components/system/Footer'), {
  ssr: false,
});

export interface SystemLayoutProps {
  appSidebarOpened?: boolean;
  enableAppBar?: boolean;
  children: ReactNode;
}

export function AppCoreContainer(props: SystemLayoutProps) {
  const { enableAppBar = true, children, appSidebarOpened } = props;
  const { pathname, push } = useRouter();
  const { theme } = useTheme();
  const isTheaterMode = useAtomValue(isTheaterModeOn);
  const isSportsPage = pathname === '/sports';
  const { toggleZendesk, closeZendesk } = useZendeskStatus();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useMount(() => {
    closeZendesk();
    busEvents.on('toggleZendeskStatus', toggleZendesk);
  });

  useCheckInvitationCode();
  // Detect whether the user is logged in
  useUserLoginCallbackHandler();

  if (enableAppBar) {
    return (
      <>
        <AppBar appSidebarOpened={appSidebarOpened}>
          <AppCoreContainerStyle
            threaterMode={isTheaterMode}
            isSportsPage={isSportsPage}
          >
            {children}
          </AppCoreContainerStyle>
          <Footer />
          {isSmallScreen && <AppMobileBottomBar />}
        </AppBar>
      </>
    );
  }

  return <AppCoreContainerStyle>{children}</AppCoreContainerStyle>;
}

export default AppCoreContainer;
