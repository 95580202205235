import request from '.';

export async function fetchCurrencyRateTable() {
  const res = await request({
    method: 'post',
    url: '/api/mc-api/exchange/table',
    data: {},
  });
  if (res?.data?.table) {
    return res?.data?.table;
  } else {
    return {};
  }
}
