import axios, { AxiosPromise } from 'axios';
import addBodyForPost from './interceptors/addBodyForPost';
import autoAddAuthHeaderByDefault from './interceptors/autoAddAuthHeaderByDefault';
import errorMsg from './interceptors/errorMsg';
import { reqLog, resLog } from './interceptors/logs';
import type { CustomAxiosRequestConfig } from './interceptors/types';

export type JavaAPIResponse<T = never, E = Record<string, never>> = {
  data?: T;
  status?: {
    elapsed: string | number;
    code: string | number;
    message: string;
    timestamp: string;
  };
} & E;

export const factory = (config: CustomAxiosRequestConfig) => {
  return <T = any>(cf: CustomAxiosRequestConfig): AxiosPromise<T> => {
    const { reqInterceptors = [], resInterceptors = [] } = config;
    const {
      reqInterceptors: cfReqItr = [],
      resInterceptors: cfResItr = [],
      ...restCf
    } = cf;

    const ins = axios.create({
      ...config,
    });

    // reverse the order is due to the axios run request interceptor in reverse
    [...reqInterceptors, ...cfReqItr].reverse().forEach((reqItr) => {
      const [fn, cb, option] = reqItr;
      ins.interceptors.request.use((c) => (fn ? fn(c, ins) : c), cb);
    });

    [...resInterceptors, ...cfResItr].forEach((resItr) => {
      const [fn, cb, option] = resItr;
      ins.interceptors.response.use((c) => (fn ? fn(c, ins) : c), cb);
    });

    return ins(restCf);
  };
};

export const baseConfig: CustomAxiosRequestConfig = {
  timeout: 10e3,
  reqInterceptors: [
    // markRequestStartTime,
    // updateRequestHeaderWithCsrfToken,
    addBodyForPost,
    autoAddAuthHeaderByDefault,
    addBodyForPost,
    reqLog,
  ],
  resInterceptors: [
    errorMsg,
    // resendWhenCsrfValidationError,
    resLog,
  ],
};

export const baseFetch = factory(baseConfig);
export default baseFetch;
