export const envs = [
  'local',
  'local-telegram',
  'beta',
  'beta-telegram',
  'staging',
  'staging-telegram',
  'production',
  'production-telegram',
  'coming',
];

export const checkRuntime = typeof window === 'undefined';
export const isLocalDev = process.env.NEXT_PUBLIC_MOON_ENV.includes('local');
export const isProduction =
  process.env.NEXT_PUBLIC_MOON_ENV.includes('production');
export const isRelease = ['production', 'staging', 'pre'].includes(
  process.env.NEXT_PUBLIC_MOON_ENV,
);
export const isTelegramMiniApp =
  process.env.NEXT_PUBLIC_MOON_ENV?.includes('telegram');
