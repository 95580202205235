// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbySkirace: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-skirace"><g id="Property 1=sport-skirace"><path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M23.0915 13.7778H20.5556C20.503 13.7778 20.4512 13.7747 20.4002 13.7688C20.1975 13.7579 19.9943 13.7003 19.8064 13.5919L16.2186 11.5204L13.8506 15.6219L18.0341 18.236C18.6586 18.6262 18.8485 19.4488 18.4583 20.0733C18.4451 20.0944 18.4314 20.115 18.4172 20.1352C18.3893 20.2181 18.3529 20.2994 18.3074 20.3781L14.9333 26.2222H24.5556C24.7626 26.2222 25.4444 25.5396 25.4444 25.3333C25.4445 24.8424 25.8425 24.4444 26.3334 24.4444C26.8243 24.4445 27.2222 24.8425 27.2222 25.3334C27.2222 26.521 25.7448 28 24.5556 28H5.88889C5.39797 28 5 27.602 5 27.1111C5 26.6202 5.39797 26.2222 5.88889 26.2222H12.3113C12.2204 25.8907 12.257 25.5244 12.4425 25.2031L15.5468 19.8263L11.3442 17.2002C10.7197 16.81 10.5298 15.9874 10.9201 15.3629C10.9318 15.3441 10.9439 15.3258 10.9564 15.3078C10.9699 15.2802 10.9844 15.2529 11 15.2259L14.5556 9.06752C14.8735 8.51686 15.5249 8.27877 16.1074 8.46107C16.2492 8.48848 16.3889 8.53957 16.521 8.61583L20.8429 11.1111H23.821L24.0963 10.1049C24.1611 9.86818 24.4055 9.72876 24.6423 9.79353C24.8791 9.85831 25.0185 10.1027 24.9537 10.3395L24.7046 11.2501C25.1431 11.4685 25.4444 11.9213 25.4444 12.4444C25.4444 13.1808 24.8475 13.7778 24.1111 13.7778H24.013L21.3898 23.3658C21.3251 23.6025 21.0806 23.742 20.8439 23.6772C20.6071 23.6124 20.4677 23.368 20.5325 23.1312L23.0915 13.7778ZM16.5556 7.55463C15.5737 7.55463 14.7778 6.7589 14.7778 5.77731C14.7778 4.79573 15.5737 4 16.5556 4C17.5374 4 18.3333 4.79573 18.3333 5.77731C18.3333 6.7589 17.5374 7.55463 16.5556 7.55463Z" fill="white" /></g></SvgBase>);
};
const MemoBetbySkirace = React.memo(BetbySkirace);
MemoBetbySkirace.displayName = 'BetbySkirace';
export default MemoBetbySkirace;