import React from 'react';
import languages from '@/configs/languages';

import getCanonicalUrl from './getCanonicalUrl';
import getLanguageSpecificUrl from './getLanguageSpecificUrl';

interface RenderCanonicalTagsProps {
  pathname: string;
  lang: string;
  keepSearchParams?: string[];
  keepHrefLangSearchParams?: ['page' | 'start' | 'end'];
}

export function renderMetaCanonicalTags({
  pathname,
  lang,
  keepHrefLangSearchParams,
  keepSearchParams,
}: RenderCanonicalTagsProps) {
  // NOTE: Had to hard code the languages here instead of iterating over an array so that <Head /> would properly deduplicate the tags.
  // <Head /> did not seem to like dynamic `key` attributes.
  // Update this function when updating configs.languages.
  const enUrl = getLanguageSpecificUrl(pathname);
  // if (enUrl.startsWith('/headlines')) {
  //   return lang === 'en' ? (
  //     <>
  //       <meta
  //         key="og:url"
  //         property="og:url"
  //         content={getCanonicalUrl(pathname, lang, keepSearchParams)}
  //       />
  //       <link
  //         key="canonical"
  //         rel="canonical"
  //         href={getCanonicalUrl(pathname, lang, keepSearchParams)}
  //       />
  //     </>
  //   ) : (
  //     <meta name="robots" content="noindex" />
  //   );
  // }

  return (
    <>
      <meta
        key="og:url"
        property="og:url"
        content={getCanonicalUrl(pathname, lang, keepSearchParams)}
      />
      <link
        key="canonical"
        rel="canonical"
        href={getCanonicalUrl(pathname, lang, keepSearchParams)}
      />
      {Object.values(languages).map((language) => {
        return (
          <link
            key={`lang:${language.code}`}
            rel="alternate"
            hrefLang={language.hreflang || language.code}
            href={getCanonicalUrl(
              pathname,
              language.code,
              keepHrefLangSearchParams,
            )}
          />
        );
      })}
    </>
  );
}

export default renderMetaCanonicalTags;
