// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyNascar: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-nascar"><g id="Property 1=sport-nascar"><path id="Vector" d="M11.7575 4L5 28H9.69075L13.5597 14.4054L15.8386 28H20.4234L27.1538 4H22.4636L18.6476 17.5426L16.3951 4H11.7575Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyNascar = React.memo(BetbyNascar);
MemoBetbyNascar.displayName = 'BetbyNascar';
export default MemoBetbyNascar;