import { CURRENCY_NAME } from '@/constants/currency';

/**
 * businese-related constants
 */
export const BONUS_PACK_CONSTANTS = {
  DEFAULT_WAGER: 35,
  DEFAULT_EXPIRE_DAYS: 60,
};

export const GAME_PROVIDERS = {
  HUB88: 'HUB88',
  HACKSAW: 'HACKSAW',
  SLOTEGRATOR: 'SLOTEGRATOR',
} as const;

export const HUB88_PLATFORMS = {
  DESKTOP: 'GPL_DESKTOP',
  MOBILE: 'GPL_MOBILE',
} as const;

export const URLS = {
  domain: [
    'www.moonclub.com',
    'moonclub.com',
    'staging.moonclub.com',
    'www.staging.moonclub.com',
    'beta.moonclub.com',
    'www.beta.moonclub.com',
  ],
  telegramDomain: [
    'tg.beta.moonclub.com',
    'www.tg.beta.moonclub.com',
    'tg.staging.moonclub.com',
    'www.tg.staging.moonclub.com',
    'tg.moonclub.com',
    'www.tg.moonclub.com',
  ],
  mainDomain: 'moonclub.com',
  lobbyURL: (origin) => origin + '/casino',
  depositURL: (currentUrl) => currentUrl + '?wallet=deposit',
  localTgURL: ['https://tg-local-moonclub.ngrok.dev', '0.0.0.0', 'localhost'],
};

export const DEPOSIT_CONSTANTS = {
  MIN_DEPOSIT: {
    USDT: 10,
  },
  DEPOSIT_ARRIVAL_CONFIRMATIONS: {
    tron: 1,
  },
};
export const RECORD_UPDATE_DELAY_MS = 750;

export const BLOCKED_COUNTRIES = [
  'AL',
  'BS',
  'BB',
  'BW',
  'KH',
  'CN',
  'CU',
  'CW',
  // 'FR',
  'GH',
  'IS',
  'IR',
  'JM',
  'KP',
  'MU',
  'MN',
  'MM',
  'NL',
  'NI',
  'PA',
  'SG',
  'SY',
  'UG',
  'US',
  'YE',
  'ZW',
];
