// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyHearthstone: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-hearthstone"><g id="Property 1=sport-hearthstone"><path id="Vector" d="M9.00279 13.2351C9.66909 11.5136 10.9628 10.1046 12.6033 9.28856L15.8027 4L18.7016 9.07798C20.2681 9.72606 21.5628 10.8983 22.367 12.3761L28 15.4652L22.417 19.3236C21.6229 20.8342 20.3203 22.0357 18.7368 22.7005L16.0395 27.8794L13.0113 22.6922C11.4316 22.0236 10.1333 20.8203 9.34324 19.3092L4 15.9721L9.00279 13.2351ZM15.8839 21.2707C18.8519 21.2707 21.258 18.8646 21.258 15.8966C21.258 12.9285 18.8519 10.5225 15.8839 10.5225C12.9159 10.5225 10.5098 12.9285 10.5098 15.8966C10.5098 18.8646 12.9159 21.2707 15.8839 21.2707ZM16.1516 11.9467C17.3749 11.9419 19.5924 13.4044 19.5684 15.346C19.5427 17.4238 18.0717 19.2797 15.8117 19.3245C14.8787 19.3429 13.6598 19.1651 13.3202 18.326C13.9874 18.5484 14.5465 18.5843 14.9958 18.5866C16.1553 18.5926 18.0919 17.5774 18.0948 15.9404C18.0978 14.2428 17.1513 13.2511 15.9509 13.2446C15.0868 13.2399 14.1874 13.6926 14.0918 14.7342C14.0551 15.134 14.2154 15.7937 15.1279 15.849C15.4545 15.8688 15.956 15.5028 15.5956 14.5602C16.1817 14.436 16.6925 15.0961 16.6997 15.8437C16.7024 16.1289 16.3615 16.9091 15.0157 16.9105C14.01 16.9115 12.8086 16.0943 12.745 14.7816C12.6664 13.1558 14.3892 11.9537 16.1516 11.9467Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyHearthstone = React.memo(BetbyHearthstone);
MemoBetbyHearthstone.displayName = 'BetbyHearthstone';
export default MemoBetbyHearthstone;