const rewardsPrefix = 'rewards';

const activityPrefix = 'activity';

export const dailyRewardsAll = () => [rewardsPrefix];
export const dailyRewardsList = (coin = 'USDT') => [
  rewardsPrefix,
  'rewardList',
  coin,
];

export const rewardsDetail = (coin = 'USDT') => [
  rewardsPrefix,
  'rewardDetail',
  coin,
];

export const depositBonus = () => [rewardsPrefix, 'depositBonus'];

export const expiredList = (params: string) => [
  rewardsPrefix,
  'expiredList',
  params,
];

export const dailyRewardsComposition = (coin = 'USDT') => [
  rewardsPrefix,
  'dailyRewardsComposition',
  coin,
];

export const activityQueue = () => [activityPrefix, 'activityQueue'];
