import { Components, Theme } from '@mui/material/styles';

export default function ToggleButton(theme: Theme): Components<Theme> {
  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { color: 'primary' },
          style: {
            textTransform: 'none',
            color: theme.palette.primary.light,
            border: theme.palette.border.normal,
            ['&:hover']: {
              background: theme.palette.grey[800],
            },
            ['&.Mui-disabled']: {
              color: theme.palette.grey[400],
            },
            ['&.Mui-selected']: {
              background: theme.palette.primary.light,
              color: theme.palette.grey[900],
              '&:hover': {
                background: theme.palette.primary.light,
              },
            },
          },
        },
      ],
    },
  };
}
