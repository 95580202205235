import { Components, Theme } from '@mui/material/styles';

export default function Tabs(theme: Theme): Components<Theme> {
  return {
    MuiTabs: {
      defaultProps: {
        TabIndicatorProps: {
          style: {
            display: 'none',
          },
        },
      },
      styleOverrides: {
        root: {
          minHeight: 'unset',
          '&.MuiButtonBase-root:hover': {
            color: theme.palette.primary.light,
          },
          ['&.Mui-selected']: {
            '&.MuiButtonBase-root:hover': {
              color: theme.palette.grey[900],
            },
          },
        },
        flexContainer: {
          overflow: 'scroll',
          ['&::-webkit-scrollbar']: {
            display: 'none',
          },
        },
      },
    },
  };
}
