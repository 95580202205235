// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyBobsleigh: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-bobsleigh"><g id="Property 1=sport-bobsleigh"><path id="Vector" d="M16.2083 12.3599C16.9225 11.1228 18.5044 10.699 19.7415 11.4132C20.9785 12.1274 21.4024 13.7092 20.6882 14.9463L21.4348 15.3774L22.5952 13.3674C23.0714 12.5427 24.1259 12.2601 24.9507 12.7363C25.1072 12.8266 25.2485 12.941 25.3694 13.0752C28.7521 16.8288 28.8589 21.4857 25.7682 26.8389C25.5301 27.2513 25.0028 27.3926 24.5905 27.1545L4.43122 15.5155C4.01886 15.2775 3.87757 14.7502 4.11565 14.3378C4.35372 13.9255 4.881 13.7842 5.29336 14.0223L24.6911 25.2215C25.0463 24.5438 25.3416 23.8849 25.5772 23.2444L6.37104 12.1557C6.16486 12.0366 6.09422 11.773 6.21326 11.5668C6.33229 11.3606 6.59593 11.29 6.80211 11.409L25.8538 22.4085C26.7794 19.2497 26.1806 16.551 24.0885 14.2296L22.497 16.9862C22.2589 17.3985 21.7316 17.5398 21.3193 17.3017L7.87979 9.54244C7.46744 9.30436 7.32615 8.77709 7.56423 8.36473C7.8023 7.95237 8.32958 7.81109 8.74194 8.04916L10.2352 8.91131C10.9494 7.67423 12.5313 7.25038 13.7683 7.96461C15.0054 8.67883 15.4293 10.2607 14.715 11.4977L16.2083 12.3599ZM16.5554 6.58593C16.3268 6.98177 15.9503 7.27055 15.5087 7.38877C15.0671 7.50698 14.5965 7.44493 14.2004 7.21627C13.8043 6.98761 13.5153 6.61107 13.3969 6.16949C13.2784 5.72791 13.3403 5.25746 13.5688 4.86165C14.0447 4.03744 15.099 3.75527 15.9237 4.23141C16.7484 4.70755 17.0312 5.76169 16.5554 6.58593ZM22.5285 10.0345C22.3 10.4303 21.9235 10.7191 21.4818 10.8373C21.0402 10.9556 20.5696 10.8935 20.1735 10.6648C19.7775 10.4362 19.4884 10.0596 19.37 9.61807C19.2515 9.17649 19.3134 8.70604 19.5419 8.31022C20.0178 7.48601 21.0721 7.20385 21.8968 7.67999C22.7215 8.15613 23.0043 9.21027 22.5285 10.0345Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyBobsleigh = React.memo(BetbyBobsleigh);
MemoBetbyBobsleigh.displayName = 'BetbyBobsleigh';
export default MemoBetbyBobsleigh;