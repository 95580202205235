import { AppMenuGroup, IAppMenuItem } from '@/components/system/AppMenus/index';
import LanguageSwitcher from '@/components/system/LanguageSwitcher';
import {
  BetbyAmericanfootball,
  BetbyAussierules,
  BetbyBaseball,
  BetbyBasketball,
  BetbyBoxing,
  BetbyCounterstrike,
  BetbyCricket,
  BetbyCycling,
  BetbyDarts,
  BetbyDota2,
  BetbyEcricketB,
  BetbyEFightingB,
  BetbyFifaB,
  BetbyFormula1,
  BetbyGolf,
  BetbyHandball,
  BetbyIcehockey,
  BetbyLive,
  BetbyMma,
  BetbyMotorcycleracing,
  BetbyMyBets,
  BetbyNba2K,
  BetbyRocketLeagueB,
  BetbyRugby,
  BetbySnooker,
  BetbySoccer,
  BetbyStarcraft,
  BetbyStartingSoon,
  BetbyStockcarracing,
  BetbyTabletennis,
  BetbyTennis,
  BetbyVolleyball,
  CasinoBaccarat,
  CasinoBlackjack,
  CasinoFavorites,
  CasinoLive,
  CasinoOriginals,
  CasinoRecent,
  CasinoRoulette,
  CasinoSlots,
  CasinoTableGames,
  StickiesReferralTracking,
} from '@crownbit/icons';
import {
  CardGiftcard,
  SportsSoccer,
  SupportAgent,
  VideogameAsset,
} from '@mui/icons-material';
import { CasinoIcon, SportIcon } from './styles';
import busEvents from '@/helper/busEvents';
import React from 'react';
import { NextRouter } from 'next/router';
import { isProduction, isTelegramMiniApp } from '@/utils/checkRuntime';

export const menusConfig: (
  router: NextRouter,
  t: any,
) => Array<AppMenuGroup | IAppMenuItem> = (router, t) => [
  {
    groupTitle: t('Casino'),
    icon: (
      <CasinoIcon>
        <VideogameAsset fontSize={'small'} />
      </CasinoIcon>
    ),
    pathPattern: '/casino/*',
    href: '/casino/lobby',
    closeMenuPanelWhenClick: true,
    menus: [
      {
        icon: (
          <CasinoIcon>
            <CasinoFavorites />
          </CasinoIcon>
        ),
        title: t('Favorites'),
        href: '/casino/favorites',
        requireAuth: true,
      },
      {
        icon: (
          <CasinoIcon>
            <CasinoRecent />
          </CasinoIcon>
        ),
        title: t('Recent'),
        requireAuth: true,
        href: '/casino/recent',
      },
      {
        icon: (
          <CasinoIcon>
            <CasinoSlots />
          </CasinoIcon>
        ),
        title: t('Slots'),
        href: '/casino/slots',
      },

      {
        icon: (
          <CasinoIcon>
            <CasinoLive />
          </CasinoIcon>
        ),
        title: t('Live Casino'),
        href: '/casino/live-casino',
      },
      {
        icon: (
          <CasinoIcon>
            <CasinoBlackjack />
          </CasinoIcon>
        ),
        title: t('Blackjack'),
        href: '/casino/blackjack',
      },
      {
        icon: (
          <CasinoIcon>
            <CasinoOriginals />
          </CasinoIcon>
        ),
        title: t('Originals'),
        href: '/casino/originals',
      },

      {
        icon: (
          <CasinoIcon>
            <CasinoTableGames />
          </CasinoIcon>
        ),
        title: t('Table Games'),
        href: '/casino/table-games',
      },

      // {
      //   icon: (
      //     <CasinoIcon>
      //       <CasinoRelease />
      //     </CasinoIcon>
      //   ),
      //   title: t('New Release'),
      //   href: '/casino/release',
      // },

      // {
      //   icon: (
      //     <CasinoIcon>
      //       <CasinoGameShows />
      //     </CasinoIcon>
      //   ),
      //   title: t('Game Shows'),
      //   href: '/casino/game-shows',
      // },

      {
        icon: (
          <CasinoIcon>
            <CasinoBaccarat />
          </CasinoIcon>
        ),
        title: t('Baccarat'),
        href: '/casino/baccarat',
      },
      {
        icon: (
          <CasinoIcon>
            <CasinoRoulette />
          </CasinoIcon>
        ),
        title: t('Roulette'),
        href: '/casino/roulette',
      },
      'divider',
    ],
  },
  {
    icon: <SportsSoccer fontSize={'small'} />,
    hidden: isTelegramMiniApp || isProduction,
    groupTitle: t('Sports'),
    pathPattern: '/sports/*',
    href: '/sports',
    menus: [
      {
        icon: (
          <CasinoIcon>
            <BetbyMyBets />
          </CasinoIcon>
        ),
        title: t('My Bets'),
        href: '/sports?bt-path=/bets',
        isBetByHref: true,
        requireAuth: true,
      },
      {
        icon: (
          <SportIcon>
            <BetbyLive />
          </SportIcon>
        ),
        title: t('Live'),
        href: '/sports?bt-path=/live',
        isBetByHref: true,
      },
      {
        icon: (
          <CasinoIcon>
            <BetbyStartingSoon />
          </CasinoIcon>
        ),
        title: t('Schedule'),
        href: '/sports?bt-path=/schedule',
        isBetByHref: true,
      },
      {
        icon: (
          <CasinoIcon>
            <CasinoFavorites />
          </CasinoIcon>
        ),
        title: t('Favorites'),
        href: '/sports?bt-path=/favorites',
        isBetByHref: true,
      },
      'divider',
      {
        groupTitle: 'All Sports',
        icon: <SportsSoccer fontSize={'small'} />,
        menus: [
          {
            icon: (
              <SportIcon>
                {' '}
                <BetbySoccer />{' '}
              </SportIcon>
            ),
            title: t('Soccer'),
            href: '/sports?bt-path=/soccer-1',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                {' '}
                <BetbyTennis />{' '}
              </SportIcon>
            ),
            title: t('Tennis'),
            href: '/sports?bt-path=/tennis-5',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyFifaB />
              </SportIcon>
            ),
            title: t('FIFA'),
            href: '/sports?bt-path=/fifa-300',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyBasketball />
              </SportIcon>
            ),
            title: t('Basketball'),
            href: '/sports?bt-path=/basketball-2',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyIcehockey />
              </SportIcon>
            ),
            title: t('Ice Hockey'),
            href: '/sports?bt-path=/ice-hockey-4',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyVolleyball />
              </SportIcon>
            ),
            title: t('Volleyball'),
            href: '/sports?bt-path=/volleyball-23',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyTabletennis />
              </SportIcon>
            ),
            title: t('Table Tennis'),
            href: '/sports?bt-path=/table-tennis-20',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyBaseball />
              </SportIcon>
            ),
            title: t('Baseball'),
            href: '/sports?bt-path=/baseball-3',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyHandball />
              </SportIcon>
            ),
            title: t('Handball'),
            href: '/sports?bt-path=/handball-6',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyGolf />
              </SportIcon>
            ),
            title: t('Golf'),
            href: '/sports?bt-path=/golf-9',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyBoxing />
              </SportIcon>
            ),
            title: t('Boxing'),
            href: '/sports?bt-path=/boxing-10',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyRugby />
              </SportIcon>
            ),
            title: t('Rugby'),
            href: '/sports?bt-path=/rugby-12',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                {' '}
                <BetbyAussierules />
              </SportIcon>
            ),
            title: t('Aussie Rules'),
            href: '/sports?bt-path=/aussie-rules-13',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                {' '}
                <BetbyAmericanfootball />{' '}
              </SportIcon>
            ),
            title: t('American Football'),
            href: '/sports?bt-path=/american-football-16',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyCycling />
              </SportIcon>
            ),
            title: t('Cycling'),
            href: '/sports?bt-path=/cycling-17',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbySnooker />
              </SportIcon>
            ),
            title: t('Snooker'),
            href: '/sports?bt-path=/snooker-19',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyCricket />
              </SportIcon>
            ),
            title: t('Cricket'),
            href: '/sports?bt-path=/cricket-21',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyDarts />
              </SportIcon>
            ),
            title: t('Darts'),
            href: '/sports?bt-path=/darts-22',
            isBetByHref: true,
          },
          // {icon:(<SportIcon><BetbyWaterpolo/></SportIcon>), title: "Waterpolo", href: "/sports?bt-path=/", isBetByHref: true},
          // {icon:(<SportIcon><BetbySquash/></SportIcon>), title: "Squash", href: "/sports?bt-path=/squash-37", isBetByHref: true},
          {
            icon: (
              <SportIcon>
                {' '}
                <BetbyFormula1 />{' '}
              </SportIcon>
            ),
            title: t('Formula 1'),
            href: '/sports?bt-path=/formula-1-40',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyCounterstrike />
              </SportIcon>
            ),
            title: t('Counter-Strike'),
            href: '/sports?bt-path=/counter-strike-109',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyCounterstrike />
              </SportIcon>
            ),
            title: t('League of Legends'),
            href: '/sports?bt-path=/league-of-legends-110',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyDota2 />
              </SportIcon>
            ),
            title: t('Dota 2'),
            href: '/sports?bt-path=/dota-2-111',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyStarcraft />
              </SportIcon>
            ),
            title: t('Starcraft'),
            href: '/sports?bt-path=/starcraft-112 ',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyMma />
              </SportIcon>
            ),
            title: t('MMA'),
            href: '/sports?bt-path=/mma-117',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyMotorcycleracing />
              </SportIcon>
            ),
            title: t('Motorcycle Racing'),
            href: '/sports?bt-path=/motorcycle-racing-190',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyStockcarracing />
              </SportIcon>
            ),
            title: t('Stock Car Racing'),
            href: '/sports?bt-path=/stock-car-racing-191',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyRocketLeagueB />
              </SportIcon>
            ),
            title: t('Rocket League'),
            href: '/sports?bt-path=/rocket-league-301',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyNba2K />
              </SportIcon>
            ),
            title: t('NBA 2k19'),
            href: '/sports?bt-path=/nba-2k19-302',
            isBetByHref: true,
          },
          // {icon:(<SportIcon><BetbyAotennisB/></SportIcon>), title: "AD Tennis 2", href: "/sports?bt-path=/", isBetByHref: true},
          {
            icon: (
              <SportIcon>
                <BetbyEFightingB />
              </SportIcon>
            ),
            title: t('eFighting'),
            href: '/sports?bt-path=/efighting-304',
            isBetByHref: true,
          },
          {
            icon: (
              <SportIcon>
                <BetbyEcricketB />
              </SportIcon>
            ),
            title: t('eCricket'),
            href: '/sports?bt-path=/ecricket-305',
            isBetByHref: true,
          },
          'divider',
        ],
      },
    ],
  },
  // {
  //   title: <Box>{t('Lucky Referral')}</Box>,
  // },
  {
    groupTitle: t('Referral'),
    icon: <StickiesReferralTracking fontSize={'small'} />,
    requireAuth: true,
    closeMenuPanelWhenClick: true,
    href: '/profile/referral',
    hidden: isProduction,
  },
  {
    groupTitle: t('Welcome Bonus'),
    icon: <CardGiftcard fontSize={'small'} />,
    requireAuth: true,
    closeMenuPanelWhenClick: true,
    href: '/benefits/deposit',
    hidden: isProduction,
  },
  {
    icon: <SupportAgent fontSize={'small'} />,
    groupTitle: t('Live Support'),
    closeMenuPanelWhenClick: true,
    onClick: () => {
      busEvents.emit('toggleZendeskStatus');
    },
  },
  {
    title: <LanguageSwitcher />,
  },
];

export default menusConfig;
