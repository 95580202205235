// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyCinemaawards: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-cinemaawards"><g id="Property 1=sport-cinemaawards"><path id="Vector" d="M20.2844 11.8565C21.6364 11.8567 22.8553 13.0893 22.8553 14.4276V14.7175C24.0625 14.1132 25.0231 13.7499 25.7594 13.6272C27.1035 13.4032 28.054 14.0256 27.9976 15.2847V22.9982C27.9976 24.2845 27.049 24.8991 25.7141 24.6766C24.9801 24.5542 24.0346 24.1904 22.8553 23.5851V23.8553C22.8553 25.1991 21.6231 26.4264 20.2841 26.4264H6.57117C5.22457 26.4264 4 25.21 4 23.8553V14.4276C4 13.3429 4.80633 12.3364 5.81562 11.9867C5.21629 11.2496 4.85706 10.3094 4.85706 9.28529C4.85706 6.91859 6.77565 5 9.14235 5C10.8386 5 12.3046 5.98549 12.9991 7.41514C13.6936 5.98549 15.1597 5 16.8559 5C19.2226 5 21.1412 6.91859 21.1412 9.28529C21.1412 10.25 20.8224 11.1403 20.2844 11.8565ZM13.4273 11.8565C13.2633 11.638 13.1196 11.4034 12.9991 11.1554C12.8786 11.4034 12.735 11.638 12.5709 11.8565H13.4273ZM26.2843 15.2897C26.2277 15.293 26.1467 15.3004 26.0412 15.318C25.3058 15.4406 24.0794 15.9651 22.4147 16.8908L21.1412 17.599V14.4276C21.1412 14.0316 20.6852 13.5706 20.2841 13.5706H6.57117C6.18295 13.5706 5.71412 14.0335 5.71412 14.4276V23.8553C5.71412 24.2608 6.16871 24.7123 6.57117 24.7123H20.2841C20.6779 24.7123 21.1412 24.2509 21.1412 23.8553V20.661L22.4249 21.3978C24.057 22.3346 25.265 22.864 25.9959 22.9858C26.375 23.049 26.2835 23.1083 26.2835 22.9982L26.2843 15.2897ZM9.14235 11.8565C10.5624 11.8565 11.7135 10.7053 11.7135 9.28529C11.7135 7.86527 10.5624 6.71412 9.14235 6.71412C7.72233 6.71412 6.57117 7.86527 6.57117 9.28529C6.57117 10.7053 7.72233 11.8565 9.14235 11.8565ZM16.8559 11.8565C18.2759 11.8565 19.427 10.7053 19.427 9.28529C19.427 7.86527 18.2759 6.71412 16.8559 6.71412C15.4358 6.71412 14.2847 7.86527 14.2847 9.28529C14.2847 10.7053 15.4358 11.8565 16.8559 11.8565Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyCinemaawards = React.memo(BetbyCinemaawards);
MemoBetbyCinemaawards.displayName = 'BetbyCinemaawards';
export default MemoBetbyCinemaawards;