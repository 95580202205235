import { useAtomValue, useSetAtom } from 'jotai';
import { memo } from 'react';
import { isLoggedIn, isTemporaryLoginWithoutToken } from '@/state/userInfo';
import { GLOBAL_MODALS, globalModals } from '@/state/globalSettings';

type BindEmailOrLogin =
  | {
      openRegister?: boolean;
    }
  | undefined;
export const useBindEmailOrLogin = () => {
  const isTemporaryLogin = useAtomValue(isTemporaryLoginWithoutToken);
  const isLogin = useAtomValue(isLoggedIn);
  const setModals = useSetAtom(globalModals);
  const openBindEmailModal = () => {
    setModals({
      [GLOBAL_MODALS.BIND_EMAIL]: true,
    });
  };

  const openLoginModal = () => {
    setModals({
      [GLOBAL_MODALS.LOGIN]: true,
    });
  };

  const openRegisterModal = () => {
    setModals({
      [GLOBAL_MODALS.REGISTER]: true,
    });
  };

  const requirementToBindEmailOrLogin = (props?: BindEmailOrLogin) => {
    if (isTemporaryLogin) {
      openBindEmailModal();
      return true;
    }

    if (!isLogin) {
      props?.openRegister ? openRegisterModal() : openLoginModal();
      return true;
    }

    return false;
  };

  // console.log('isTemporaryLogin', isTemporaryLogin, isLogin, 'isLogin');
  return {
    requirementToBindEmailOrLogin,
    isTemporaryLogin,
    isLogin,
    openLoginModal,
    openRegisterModal,
    openBindEmailModal,
  };
};

export default useBindEmailOrLogin;
