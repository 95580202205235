import {
  checkRuntime,
  isRelease,
  isTelegramMiniApp,
} from '@/utils/checkRuntime';
import { AxiosResponse } from 'axios';
import { toast } from 'react-hot-toast';
import type { CustomAxiosRequestConfig, Interceptor } from './types';
import busEvents from '@/helper/busEvents';
import { API_RESPONSE_SUCCESS_CODE } from '@/constants/common';
import { debounce } from 'lodash-es';
import * as Sentry from '@sentry/nextjs';
import { cookie } from '@/utils/cookies';
import { COOKIE_KEY_USER_TOKEN } from '@/constants/cookieStorageKeys';
import { getLocalStorage } from '@/utils/localStoreHelper';
import { LOCAL_KEY_USER_TOKEN } from '@/constants/localStorageKeys';
import { userInfoState, isTemporaryLoginWithoutToken } from '@/state/userInfo';
import { GLOBAL_MODALS, globalModals } from '@/state/globalSettings';
import { store } from '@/state/store';
import { getDefaultStore } from 'jotai';

export const errorCodeMapping = {
  10001: 'System is busy, please try later.',
  45000: 'Time range error',
  45001: 'Failed to get id.',
  45003: 'Failed to like, please retry.',
  45004: 'Report failed, please retry.',
  45005: 'Target post not found.',
  45006: 'You are posting too frequently, take a break and try again later.',
  45007: 'Posting failed.',
  45008: 'Account not found.',
  45009: 'Your account was banned and this operation is not allowed.',
  45010: 'Your reply failed due to the abnormal status of the post.',
  45011: 'Rate limit exceeded, please try again later.',
  45012: 'Cryptocurrency id is required.',
  45013: 'This cryptocurrency is not enabled for posting.',
  45014: 'Like operation is required.',
  45015: 'Post id is required.',
  45016: "You can't report your own posts.",
  45017: 'The report reason exceeds the character limit.',
  45018: 'The report type is required.',
  45019: 'Report reason is required.',
  45020: 'You have already reported this post.',
  45021: 'Your account was banned and this operation is not allowed.',
  45022:
    'Your like operation is too frequent, take a break and try again later.',
  45047: 'You have already reported this account.',
  45031: 'You are unable to follow more people.',
  46001:
    'Your account has been verified. The profile information can be changed a few days later.',
  1018: 'The token has expired, please login again.',
};

const noAuthErrorCodes = ['1018', '1001', '1000'];
//90008: duplicated bet error
const noToastErrorCodes = ['40110', '3700', '10023', '90008'];

const toastLoggedOutMessage = debounce((msg: string) => toast(msg), 1500, {
  leading: false,
  trailing: true,
});

const debounceAutoLoginToTelegram = debounce(
  () => busEvents.emit('autoLoginToTelegram'),
  1500,
  {
    leading: true,
    trailing: false,
  },
);

const debounceUserLogout = debounce(() => busEvents.emit('userLogout'), 1500, {
  leading: true,
  trailing: false,
});

const debounceBindEmailFunc = debounce(
  () => {
    console.log(store.get(globalModals)[GLOBAL_MODALS.BIND_EMAIL])
    store.set(globalModals, {
      [GLOBAL_MODALS.BIND_EMAIL]: true,
    });
    console.log('openBindEmail',store.get(globalModals)[GLOBAL_MODALS.BIND_EMAIL]);
  },
  1500,
  {
    leading: true,
    trailing: false,
  },
);

const errorMsg: Interceptor<AxiosResponse> = [
  async (response) => {
    // let isTemporaryLogin = getDefaultStore().get(isTemporaryLoginWithoutToken);
    let isTemporaryLogin = store.get(isTemporaryLoginWithoutToken);

    const { data, config } = response || {};
    const responseStatusCode = data?.status?.code;
    const { suppressWarning: noToast = false } =
      config as CustomAxiosRequestConfig;
    const ignoreErrorCode = noToastErrorCodes.includes(responseStatusCode);
    if (checkRuntime || ignoreErrorCode || !config?.url?.startsWith('/')) {
      return response;
    }

    // means success
    if (Number(responseStatusCode) === API_RESPONSE_SUCCESS_CODE) {
      return response;
    }

    Sentry.captureException(response);

    if (noAuthErrorCodes.includes(responseStatusCode) && !isTemporaryLogin) {
      const userToken =
        cookie.get(COOKIE_KEY_USER_TOKEN) ||
        getLocalStorage(LOCAL_KEY_USER_TOKEN);
      if (!isTelegramMiniApp && userToken) {
        toastLoggedOutMessage(
          'You have been logged out because you signed in on another device.',
        );
        debounceUserLogout();
      } else if (userToken) {
        toastLoggedOutMessage(
          "Automatically logged out, you're already logged in on another device.",
        );
        debounceAutoLoginToTelegram();
      }

      console.warn(
        `${response?.config?.url}`,
        data?.status?.message || 'No auth error code',
      );

      return Promise.reject(response);
    } else if (isTemporaryLogin) {
      debounceBindEmailFunc();
      return Promise.reject(response);
    }

    // if (Number(errorCode) === ) {
    const errorMessage =
      data?.status?.message ||
      'Something wrong at server, please try again later.';

    // console.error(
    //   `Error: ${responseStatusCode} - ${errorMessage} - ${config?.url} - ${config?.method} - ${config?.data}`,
    // );

    if (!noToast || !isRelease) {
      toast.error(errorMessage);
    } else {
      console.error(errorMessage);
    }
    return Promise.reject(response);
  },
  (error) => {
    const noToast = (error?.config as CustomAxiosRequestConfig)
      ?.suppressWarning;
    const code = error?.response?.status;

    // error without `code` is mainly 429 or canceled request, better no toast
    if (noToast || !code) return Promise.reject(error);

    if (!isRelease) {
      toast.error(
        `Network error, please try again. code: ${code}, path: ${error.config.url}`,
      );
    }

    return Promise.reject(error);
  },
];

export default errorMsg;
