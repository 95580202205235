import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/system';

const GlowBox = styled(Stack)(({ theme }) => ({
  border: theme.palette.border.primary,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  boxShadow: theme.palette.boxShadow.glow,
}));

export default GlowBox;
