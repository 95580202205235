// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyStartingSoon: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-starting-soon"><g id="left panel menu icon"><path id="Vector" d="M9.92392 20.0736C7.13509 20.0736 4.77787 19.1014 2.85226 17.1571C0.926659 15.2128 -0.0239402 12.8473 0.000457899 10.0604H2.442C2.46195 12.1582 3.18926 13.9451 4.62393 15.4211C6.05863 16.8971 7.8248 17.6351 9.92247 17.6351C12.035 17.6351 13.8359 16.8887 15.325 15.3959C16.8142 13.9031 17.5588 12.0916 17.5588 9.96131C17.5588 7.88296 16.811 6.12609 15.3153 4.6907C13.8197 3.25531 12.0221 2.53761 9.92247 2.53761C8.88352 2.53761 7.90084 2.74946 6.97444 3.17316C6.04804 3.59686 5.22986 4.17188 4.51991 4.89822H6.70171V6.7919H0.7537V0.903346H2.59784V3.36772C3.55022 2.34738 4.65411 1.54322 5.90951 0.955228C7.16491 0.367237 8.50257 0.0732422 9.92247 0.0732422C11.3077 0.0732422 12.6108 0.336973 13.8315 0.864435C15.0523 1.3919 16.1236 2.10536 17.0455 3.00483C17.9675 3.9043 18.6904 4.9549 19.2144 6.15663C19.7383 7.35837 20.0003 8.65099 20.0003 10.0345C20.0003 11.418 19.7383 12.7193 19.2144 13.9383C18.6904 15.1573 17.9675 16.2209 17.0455 17.129C16.1236 18.0371 15.0523 18.7549 13.8315 19.2824C12.6108 19.8098 11.3082 20.0736 9.92392 20.0736ZM13.1432 14.4704L9.14325 10.5274V4.8204H10.9874V9.7232L14.4939 13.1214L13.1432 14.4704Z" fill="white" fillOpacity="0.7" /></g></SvgBase>);
};
const MemoBetbyStartingSoon = React.memo(BetbyStartingSoon);
MemoBetbyStartingSoon.displayName = 'BetbyStartingSoon';
export default MemoBetbyStartingSoon;