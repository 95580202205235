import { useEffect } from 'react';
import {
  useBackButton,
  useClosingBehavior,
  useViewport,
} from '@tma.js/sdk-react';
import { useRouter } from 'next/router';

function BackButtonAndCloseBehavior() {
  const viewport = useViewport();
  const backButton = useBackButton();
  const closingBehaviorButton = useClosingBehavior();
  const router = useRouter();
  const { pathname, query } = router;
  const isHome = pathname === '/casino';

  useEffect(() => {
    const onBackButtonClick = () => {
      history.back();
    };

    isHome ? backButton.hide() : backButton.show();

    backButton.on('click', onBackButtonClick);
    closingBehaviorButton.enableConfirmation();

    return () => {
      backButton.hide();
      backButton.off('click', onBackButtonClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHome]);

  return null;
}

export default BackButtonAndCloseBehavior;
