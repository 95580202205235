// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonCryptoTokenAvax: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-crypto-token-avax"><circle cx="48" cy="48" r="48" fill="#E84142" /><mask id="mask0_3239_4780" style={{"maskType":"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="96" height="96"><circle cx="48" cy="48" r="48" fill="white" /></mask><g mask="url(#mask0_3239_4780)"><path d="M69.8202 49.0381C68.2901 46.3206 65.8421 46.3206 64.312 49.0381L54.6509 66.0997C53.1208 68.7726 54.3885 71 57.405 71H76.5524C79.6124 71 80.8802 68.7726 79.3503 66.0997L69.8202 49.0381Z" fill="white" /><path d="M58.0434 28.966L52.8044 19.9174L50.5723 16.0393C48.978 13.3202 46.4268 13.3202 44.8323 16.0393L15.6772 66.097C14.0827 68.8159 15.4038 71 18.5926 71H26.7014H32.259H33.2157C35.8579 71 39.1378 69.1725 40.4589 66.8993L58.0432 37.1232C59.3189 34.9391 59.3189 31.2394 58.0434 28.966Z" fill="white" /></g></SvgBase>);
};
const MemoMoonCryptoTokenAvax = React.memo(MoonCryptoTokenAvax);
MemoMoonCryptoTokenAvax.displayName = 'MoonCryptoTokenAvax';
export default MemoMoonCryptoTokenAvax;