import { PropsWithChildren } from 'react';
import { useMiniApp, usePostEvent, useSDKContext } from '@tma.js/sdk-react';
import CatchError from '@/components/system/CacthError';
import useUserLoginLogout from '@/hooks/biz/useUserLoginLogout';
import BackButtonAndCloseBehavior from '../BackButtonAndCloseBehavior';
import { useIsomorphicLayoutEffect } from 'ahooks';

export const InitializeTelegramMiniApp = ({ children }: PropsWithChildren) => {
  const { initResult } = useSDKContext();
  const postEvent = usePostEvent();
  const miniApp = useMiniApp();
  const { logout } = useUserLoginLogout();

  useIsomorphicLayoutEffect(() => {
    miniApp.ready();
    postEvent('web_app_expand');
    return () => {
      logout();
    };
  }, []);

  if (!initResult) {
    return null;
  }

  return (
    <>
      <BackButtonAndCloseBehavior />
      {children}
    </>
  );
};

export default CatchError(InitializeTelegramMiniApp);
