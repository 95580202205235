import request from '../index';

export interface BetbyJWTTokenBodyParams {
  update?: boolean;
}

/**
 * Get Betby JWT token from Sever
 * @param data
 */
export const getBetbyJWTToken = (data?: BetbyJWTTokenBodyParams) => {
  return request<{
    email: string;
    token: string;
  }>({
    url: '/api/mc-api/betby/token',
    method: 'post',
    data,
  });
};
