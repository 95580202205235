// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbySpeedskating: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-speedskating"><g id="Property 1=sport-speedskating"><path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M5.68527 22.3447C5.69479 22.3326 5.70453 22.3206 5.7145 22.3087L10.7593 16.2965L10.4973 16.1131C10.3948 16.0413 10.3046 15.9586 10.2269 15.8677C10.0203 15.7268 9.8477 15.5302 9.73434 15.2871C9.39823 14.5663 9.71007 13.7095 10.4308 13.3734L18.1798 9.76H10.0858C9.29048 9.76 8.64577 9.11529 8.64577 8.32C8.64577 7.52471 9.29048 6.88 10.0858 6.88H20.6458C21.4411 6.88 22.0858 7.52471 22.0858 8.32V14.56H26.4058C27.2011 14.56 27.8458 15.2047 27.8458 16C27.8458 16.7953 27.2011 17.44 26.4058 17.44H20.6458C19.8505 17.44 19.2058 16.7953 19.2058 16V12.4593L13.8602 14.952L17.6539 17.6084C18.1284 17.9406 18.3413 18.507 18.2458 19.042V25.12C18.776 25.12 19.2058 25.5498 19.2058 26.08H20.1658C20.696 26.08 21.1258 26.5098 21.1258 27.04C21.1258 27.5702 20.696 28 20.1658 28H15.3658C14.8356 28 14.4058 27.5702 14.4058 27.04C14.4058 26.5098 14.8356 26.08 15.3658 26.08V19.522L13.1275 17.9547L8.34843 23.6502L8.69352 23.9953C9.06843 24.3702 9.06843 24.978 8.69352 25.3529L9.03293 25.6923C9.40784 26.0672 9.40784 26.6751 9.03293 27.05C8.65803 27.4249 8.05019 27.4249 7.67529 27.05L4.28118 23.6559C3.90627 23.281 3.90627 22.6731 4.28118 22.2982C4.65608 21.9233 5.26392 21.9233 5.63882 22.2982L5.68527 22.3447ZM24.9658 7.839C23.9054 7.839 23.0458 6.97961 23.0458 5.9195C23.0458 4.85939 23.9054 4 24.9658 4C26.0262 4 26.8858 4.85939 26.8858 5.9195C26.8858 6.97961 26.0262 7.839 24.9658 7.839Z" fill="white" /></g></SvgBase>);
};
const MemoBetbySpeedskating = React.memo(BetbySpeedskating);
MemoBetbySpeedskating.displayName = 'BetbySpeedskating';
export default MemoBetbySpeedskating;