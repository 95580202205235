// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyLuge: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-luge"><g id="Property 1=sport-luge"><path id="Vector" d="M12.0484 8.64074L26.6704 18.8792C27.3517 19.3562 27.5173 20.2952 27.0402 20.9764C26.5632 21.6577 25.6242 21.8233 24.943 21.3462L18.1135 16.5642C17.3276 17.4001 16.0268 17.5542 15.0594 16.8768L9.03577 12.659C7.97411 11.9156 7.71609 10.4523 8.45947 9.39065L8.64653 9.1235C9.38992 8.06184 10.8532 7.80382 11.9149 8.54721L12.0484 8.64074ZM5.432 15.5023C4.97783 15.1842 4.86745 14.5583 5.18547 14.1041C5.50348 13.6499 6.12947 13.5395 6.58364 13.8576C13.2549 18.5288 13.2549 18.5288 23.7381 25.8693C24.0779 26.107 25.0618 25.9738 25.2513 25.7032C25.5693 25.249 26.1953 25.1387 26.6495 25.4567C27.1036 25.7747 27.214 26.4007 26.896 26.8549C26.049 28.0645 23.8068 28.3679 22.5867 27.5141L5.432 15.5023ZM6.00782 8.01564C4.89893 8.01564 4 7.11671 4 6.00782C4 4.89893 4.89893 4 6.00782 4C7.11671 4 8.01564 4.89893 8.01564 6.00782C8.01564 7.11671 7.11671 8.01564 6.00782 8.01564Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyLuge = React.memo(BetbyLuge);
MemoBetbyLuge.displayName = 'BetbyLuge';
export default MemoBetbyLuge;