// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonCryptoTokenDoge: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-crypto-token-doge"><circle cx="48" cy="48" r="48" fill="#C2A633" /><path d="M26.9133 19.8188H48.3442C53.2654 19.8188 56.996 20.2025 59.536 20.9698C62.9755 22.0016 65.9123 23.8272 68.3465 26.4466C70.7806 29.0395 72.6062 32.1879 73.8233 35.8921C75.0668 39.5962 75.6885 44.1734 75.6885 49.6237C75.6885 54.4126 75.0006 58.7385 73.6248 62.6014C72.2755 66.4378 70.2779 69.6392 67.6321 72.2056C65.5948 74.1371 62.8564 75.6584 59.4169 76.7696C56.824 77.5898 53.3448 77.9999 48.9792 77.9999H26.9133V52.6003H20.8809V45.2185H26.9133V19.8188ZM38.6209 29.6612V45.2185H51.0826V52.6003H38.6209V68.1972H47.4314C51.3208 68.1972 54.1253 67.8797 55.8451 67.2448C58.1205 66.451 59.8535 64.9958 61.0441 62.8792C62.7109 59.8894 63.5443 55.3254 63.5443 49.1872C63.5443 44.8216 63.1872 41.4615 62.4728 39.1067C61.7849 36.7255 60.753 34.8073 59.3772 33.3521C58.0278 31.8969 56.2552 30.8783 54.0592 30.2962C52.3658 29.8729 48.9925 29.6612 43.939 29.6612H38.6209Z" fill="white" /></SvgBase>);
};
const MemoMoonCryptoTokenDoge = React.memo(MoonCryptoTokenDoge);
MemoMoonCryptoTokenDoge.displayName = 'MoonCryptoTokenDoge';
export default MemoMoonCryptoTokenDoge;