// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonArrowDropDown: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-arrow-drop-down"><g id="arrow_drop_down" clipPath="url(#clip0_423_97407)"><path id="Vector" d="M8.70999 11.71L11.3 14.3C11.69 14.69 12.32 14.69 12.71 14.3L15.3 11.71C15.93 11.08 15.48 10 14.59 10H9.40999C8.51999 10 8.07999 11.08 8.70999 11.71Z" fill="white" fillOpacity="0.56" /></g><defs><clipPath id="clip0_423_97407"><rect width="24" height="24" fill="white" /></clipPath></defs></SvgBase>);
};
const MemoMoonArrowDropDown = React.memo(MoonArrowDropDown);
MemoMoonArrowDropDown.displayName = 'MoonArrowDropDown';
export default MemoMoonArrowDropDown;