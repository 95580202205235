// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const StickiesGiftRed: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" height="40" width="40" sx={propSx}  {...restProps} iconName="i-stickies-gift-red"><g><path d="M39.5 15.29c0 -2.24 -0.08 -4.81 -3.13 -5.39a16.56 16.56 0 0 0 -3 -0.51c0.08 -0.2 0.16 -0.41 0.24 -0.65C35 4.56 32.23 -1 25.42 1.78A19.73 19.73 0 0 0 20 5.54a19.73 19.73 0 0 0 -5.42 -3.76C7.77 -1 5.05 4.56 6.41 8.74c0.08 0.24 0.16 0.45 0.24 0.65a16.74 16.74 0 0 0 -3 0.51C0.59 10.48 0.5 13.05 0.5 15.29s0.1 3.74 3 4.38c-0.18 1.8 -0.23 3.68 -0.23 5.37 0 4.94 0.47 10.1 3 11.38C7.58 37.69 14.37 39 20 39s12.42 -1.28 13.69 -2.55c2.55 -1.28 3 -6.44 3 -11.38 0 -1.69 -0.05 -3.57 -0.23 -5.37 2.95 -0.7 3.04 -2.19 3.04 -4.41Z" fill="#f6465d" stroke="#231f20" strokeMiterlimit="10" strokeWidth="1" /><path d="M22.79 34.79c0.24 -1.47 0.47 -7.33 0.6 -13.93" fill="none" stroke="#231f20" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" /><path d="M16.61 20.86c0.13 6.6 0.37 12.46 0.6 13.93" fill="none" stroke="#231f20" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" /><path d="M29.75 20.59s-4.87 0.27 -9.75 0.27 -9.75 -0.27 -9.75 -0.27" fill="none" stroke="#231f20" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" /><path d="M13 9.68s3.48 -0.19 7 -0.19 7 0.19 7 0.19" fill="none" stroke="#231f20" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" /><path d="M28.1 3.82a2.39 2.39 0 0 1 3 2.5" fill="none" stroke="#f6465d" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" /></g></SvgBase>);
};
const MemoStickiesGiftRed = React.memo(StickiesGiftRed);
MemoStickiesGiftRed.displayName = 'StickiesGiftRed';
export default MemoStickiesGiftRed;