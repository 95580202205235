// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyArchery: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-archery"><g id="Property 1=sport-archery"><path id="Vector" d="M27 3.5H24.2C23.6 3.5 23.2 3.9 23.2 4.5C23.2 5.1 23.6 5.5 24.2 5.5H24.6L21.3 8.8C19 6.8 16.1 5.6 13.1 5.5C9.69998 5.4 6.59998 6.5 4.39998 8.8C3.99998 9.2 3.99998 9.8 4.39998 10.2L12.2 18L10.4 19.8H7.89998C7.29998 19.8 6.89998 20.2 6.89998 20.8C6.89998 21.4 7.29998 21.8 7.89998 21.8H8.29998L7.49998 22.6H5.09998C4.49998 22.6 4.09998 23 4.09998 23.6C4.09998 24.2 4.49998 24.6 5.09998 24.6H6.89998V26.4C6.89998 27 7.29998 27.4 7.89998 27.4C8.49998 27.4 8.89998 27 8.89998 26.4V24L9.69998 23.2V23.6C9.69998 24.2 10.1 24.6 10.7 24.6C11.3 24.6 11.7 24.2 11.7 23.6V21.2L13.5 19.4L21.3 27.2C21.5 27.4 21.8 27.5 22 27.5C22.2 27.5 22.5 27.4 22.7 27.2C27.2 22.7 27.1 15.3 22.7 10.3L26 6.9V7.3C26 7.9 26.4 8.3 27 8.3C27.6 8.3 28 7.9 28 7.3V4.5C28 3.9 27.5 3.5 27 3.5ZM6.49998 9.5C8.29998 8 10.6 7.3 13 7.4C15.5 7.5 17.9 8.5 19.9 10.2L13.6 16.5L6.49998 9.5ZM22 25L15 18L21.3 11.7C24.7 15.6 25 21.2 22 25Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyArchery = React.memo(BetbyArchery);
MemoBetbyArchery.displayName = 'BetbyArchery';
export default MemoBetbyArchery;