// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyTabletennis: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-tabletennis"><g id="Property 1=sport-tabletennis"><path id="Vector" d="M9.8161 14.9767L19.4209 24.5815C20.79 24.2873 22.0869 23.5854 23.1851 22.4873C26.2327 19.4396 26.1877 14.4441 23.0733 11.3296C19.9588 8.21513 14.9633 8.17012 11.9156 11.2178C10.8158 12.3176 10.1121 13.6118 9.8161 14.9767ZM18.3166 24.7293L9.66661 16.0793C9.58783 17.4849 9.92686 18.9288 10.6957 20.284C10.996 20.8134 10.8325 21.2537 10.4655 21.7205C10.3076 21.9213 10.1625 22.0716 9.75162 22.4778C9.33261 22.892 9.13192 23.0998 8.91331 23.3576C8.05872 24.3655 7.95123 25.0073 8.67337 25.7295C9.39548 26.4516 10.0355 26.3439 11.0431 25.4882C11.2991 25.2708 11.5053 25.0715 11.9173 24.6548C12.3252 24.2422 12.4766 24.0963 12.6787 23.9376C13.1459 23.5709 13.5876 23.4076 14.1169 23.7061C15.4701 24.469 16.9127 24.8062 18.3166 24.7293ZM7.09895 10.1979C5.38745 10.1979 4 8.81045 4 7.09895C4 5.38745 5.38745 4 7.09895 4C8.81045 4 10.1979 5.38745 10.1979 7.09895C10.1979 8.81045 8.81045 10.1979 7.09895 10.1979ZM7.09895 9.31248C8.32145 9.31248 9.31248 8.32145 9.31248 7.09895C9.31248 5.87645 8.32145 4.88541 7.09895 4.88541C5.87645 4.88541 4.88541 5.87645 4.88541 7.09895C4.88541 8.32145 5.87645 9.31248 7.09895 9.31248ZM13.1765 25.8999C12.7291 26.3523 12.4968 26.5768 12.1894 26.838C10.5636 28.2186 8.93267 28.4931 7.4212 26.9817C5.91042 25.4709 6.1838 23.8387 7.56262 22.2124C7.82539 21.9025 8.05157 21.6684 8.5067 21.2185C8.72598 21.0017 8.8571 20.8697 8.9473 20.7722C7.07208 17.115 7.69994 12.9291 10.6634 9.96564C14.4077 6.22131 20.5244 6.27641 24.3254 10.0774C28.1264 13.8785 28.1816 19.9951 24.4372 23.7394C21.4818 26.6948 17.2884 27.32 13.629 25.4536C13.5306 25.5443 13.3972 25.6766 13.1765 25.8999Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyTabletennis = React.memo(BetbyTabletennis);
MemoBetbyTabletennis.displayName = 'BetbyTabletennis';
export default MemoBetbyTabletennis;