// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const CasinoFavorites: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-casino-favorites"><path d="M8.99121 17.1631C9.21973 17.1631 9.55371 16.9873 9.82617 16.8115C14.7393 13.6475 17.8857 9.93848 17.8857 6.18555C17.8857 2.94238 15.6445 0.692383 12.8408 0.692383C11.0918 0.692383 9.78223 1.65918 8.99121 3.10059C8.21777 1.65918 6.89941 0.692383 5.15039 0.692383C2.33789 0.692383 0.0966797 2.94238 0.0966797 6.18555C0.0966797 9.93848 3.25195 13.6475 8.15625 16.8115C8.42871 16.9873 8.7627 17.1631 8.99121 17.1631Z" fill="#FDCB35" /></SvgBase>);
};
const MemoCasinoFavorites = React.memo(CasinoFavorites);
MemoCasinoFavorites.displayName = 'CasinoFavorites';
export default MemoCasinoFavorites;