// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyGolf: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-golf"><g id="Property 1=sport-golf"><path id="Vector" d="M12.1954 4.14767L19.8996 9.21587C20.4106 9.55203 20.4152 10.2999 19.9084 10.6422L12.5714 15.5982V17.9109C13.6591 17.782 14.8102 17.7143 16 17.7143C16.7267 17.7143 17.439 17.7395 18.1312 17.7886C18.7128 16.7229 19.8434 16 21.1429 16C22.9508 16 24.4319 17.3994 24.5621 19.1741C26.6855 20.0748 28 21.3462 28 22.8571C28 25.9211 22.5948 28 16 28C9.4052 28 4 25.9211 4 22.8571C4 20.6467 6.81339 18.9489 10.8571 18.1745V4.85714C10.8571 4.38376 11.2409 4 11.7143 4C11.8926 4 12.0582 4.05447 12.1954 4.14767ZM12.5714 6.44699V13.5295L17.8833 9.9414L12.5714 6.44699ZM24.2418 20.8973C23.6917 22.056 22.5108 22.8571 21.1429 22.8571C19.2671 22.8571 17.7432 21.3508 17.7147 19.4819C17.1575 19.447 16.5847 19.4286 16 19.4286C14.7957 19.4286 13.6422 19.5067 12.5714 19.6457V22C12.5714 22.4734 12.1877 22.8571 11.7143 22.8571C11.2409 22.8571 10.8571 22.4734 10.8571 22V19.9331C7.77454 20.5753 5.71429 21.7362 5.71429 22.8571C5.71429 24.5271 10.2867 26.2857 16 26.2857C21.7133 26.2857 26.2857 24.5271 26.2857 22.8571C26.2857 22.177 25.5271 21.482 24.2418 20.8973Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyGolf = React.memo(BetbyGolf);
MemoBetbyGolf.displayName = 'BetbyGolf';
export default MemoBetbyGolf;