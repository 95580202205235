import { z } from 'zod';
import {
  SliveWInBetsPeriodSchema,
  TInferSchemaType,
} from '@/constants/schemas';

export const casinoPrefix = 'casino';
export const queryMyBets = (params: string) => [casinoPrefix, 'myBets', params];

export const queryPlatformBets = () => [casinoPrefix, 'platformBets'];
export const queryPlatformHighRollerBets = () => [
  casinoPrefix,
  'platformHighRollerBets',
];
export const queryFavoriteGames = () => [casinoPrefix, 'favoriteGames'];

export const queryUserFootprint = () => [casinoPrefix, 'userFootprint'];

export const isGameFavorited = (gameId: string) => [
  casinoPrefix,
  'gameFavorite',
  gameId,
];

export const queryAllBets = () => [casinoPrefix, 'allBets'];
export const queryLuckyBets = () => [casinoPrefix, 'LuckyBets'];
export const queryLoginUserBets = () => [casinoPrefix, 'myBets'];
export const queryAllGameList = () => [casinoPrefix, 'allGameList'];
export const queryPlatformLiveWinBets = (
  period: TInferSchemaType<typeof SliveWInBetsPeriodSchema>,
) => [casinoPrefix, 'platformLiveWinBets', period];
export const queryPromotionList = (type: string) => [
  casinoPrefix,
  'promotions',
  type,
];
