import { includes, startsWith, keys } from 'lodash-es';
import configs from '@/configs';

export function getLanguageSpecificUrl(pathname = '', lang = 'en') {
  let ret;

  /* trim the leading slash */

  pathname = pathname.replace(/^\//g, '');

  /* if lang matches one of options, replace; otherwise, prepend */

  const split = pathname.split('/');
  const testLang = split[0].toLowerCase();

  if (includes(keys(configs.languages), testLang)) {
    split[0] = lang;
  } else {
    split.unshift(lang);
  }

  ret = '/' + split.join('/');

  /* if english, just remove lang */

  if (startsWith(ret, '/en/')) {
    ret = ret.slice(3);
  }

  return ret;
}

export default getLanguageSpecificUrl;
