// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const CasinoGameShows: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-casino-game-shows"><path d="M8.75145 20.0012H2.49743C1.80743 20.0012 1.24871 19.4749 1.24871 18.8264V11.7681C0.558714 11.7681 0 11.2417 0 10.5933V8.23467C0 7.58554 0.55946 7.05992 1.24871 7.05992H8.75145V20.0012ZM18.7516 7.05921H11.2489V20.0012H17.5029C18.1929 20.0012 18.7516 19.4749 18.7516 18.8264V11.7681C19.4416 11.7681 20.0003 11.2417 20.0003 10.5933V8.23467C20.0003 7.58554 19.4409 7.05992 18.7516 7.05992V7.05921ZM15.0047 0.000854492C12.2402 0.000854492 10.0002 2.10896 10.0002 4.709H15.0047C15.6947 4.709 16.2534 4.18268 16.2534 3.53425V1.17561C16.2534 0.526477 15.694 0.000854492 15.0047 0.000854492ZM4.99561 0.000854492C4.3056 0.000854492 3.74689 0.527179 3.74689 1.17561V3.53425C3.74689 4.18338 4.30635 4.709 4.99561 4.709H10.0002C10.0002 2.10826 7.75934 0.000854492 4.99561 0.000854492Z" fill="currentColor" fillOpacity="0.7" /></SvgBase>);
};
const MemoCasinoGameShows = React.memo(CasinoGameShows);
MemoCasinoGameShows.displayName = 'CasinoGameShows';
export default MemoCasinoGameShows;