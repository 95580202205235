// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const CasinoCloseSquare: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-casino-close-square"><g id="Close Square 1"><path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M15.0825 14.3268L14.0639 15.3454L11.7589 13.0423L9.45494 15.3425L8.43734 14.3239L10.7404 12.0247L8.43734 9.72265L9.4559 8.70406L11.7599 11.0071L14.0649 8.70598L15.0825 9.72553L12.7785 12.0247L15.0825 14.3268ZM11.7599 2.67334C6.59894 2.67334 2.3999 6.87238 2.3999 12.0334C2.3999 17.1943 6.59894 21.3934 11.7599 21.3934C16.9209 21.3934 21.1199 17.1943 21.1199 12.0334C21.1199 6.87238 16.9209 2.67334 11.7599 2.67334Z" fill="#848E9C" /></g></SvgBase>);
};
const MemoCasinoCloseSquare = React.memo(CasinoCloseSquare);
MemoCasinoCloseSquare.displayName = 'CasinoCloseSquare';
export default MemoCasinoCloseSquare;