// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyAussierules: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-aussierules"><g id="Property 1=sport-aussierules"><path id="Vector" d="M12.253 15.2492C12.6234 14.8167 13.0004 14.3972 13.384 13.9908L12.7157 13.2946C12.5376 13.1091 12.5437 12.8143 12.7292 12.6362C12.9147 12.4581 13.2095 12.4641 13.3876 12.6497L14.0329 13.3219C14.0425 13.3124 14.052 13.3028 14.0615 13.2933C14.4497 12.9051 14.8474 12.5267 15.2547 12.158L14.5679 11.4425C14.3898 11.257 14.3958 10.9622 14.5813 10.7841C14.7668 10.606 15.0616 10.612 15.2397 10.7975L15.9545 11.5421C18.427 9.42688 21.2347 7.65092 24.3765 6.21446C21.4982 5.0168 14.8186 6.64488 10.7468 10.7167C6.58425 14.8792 4.95465 21.6295 6.28673 24.4594C7.87885 21.2408 9.66734 18.4103 11.6527 15.9687L10.8636 15.1468C10.6855 14.9612 10.6915 14.6665 10.877 14.4884C11.0626 14.3103 11.3573 14.3163 11.5354 14.5018L12.253 15.2492ZM12.3027 16.6458C10.3028 19.1212 8.50436 22.0087 6.90785 25.309C9.20399 27.3041 16.7669 25.7697 21.2833 21.2532C25.7998 16.7367 27.3097 9.01174 25.2915 6.82482C22.0304 8.2583 19.1339 10.055 16.6006 12.2151L17.6104 13.267C17.7885 13.4526 17.7825 13.7473 17.597 13.9254C17.4115 14.1035 17.1167 14.0975 16.9386 13.912L15.9 12.8301C15.497 13.1943 15.1037 13.5682 14.7201 13.9518C14.7061 13.9658 14.692 13.9799 14.678 13.9939L15.7583 15.1192C15.9364 15.3047 15.9304 15.5995 15.7449 15.7776C15.5593 15.9557 15.2646 15.9497 15.0865 15.7641L14.0298 14.6634C13.647 15.0702 13.2707 15.4905 12.9011 15.9244L13.9062 16.9713C14.0843 17.1568 14.0783 17.4516 13.8927 17.6297C13.7072 17.8078 13.4124 17.8018 13.2343 17.6163L12.3027 16.6458ZM5.47851 26.5215C2.46492 23.5079 4.19395 14.6354 9.42972 9.3996C14.6317 4.19764 23.589 2.48581 26.5516 5.44839C29.5142 8.41097 27.8024 17.3683 22.6004 22.5703C17.3646 27.806 8.49211 29.5351 5.47851 26.5215Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyAussierules = React.memo(BetbyAussierules);
MemoBetbyAussierules.displayName = 'BetbyAussierules';
export default MemoBetbyAussierules;