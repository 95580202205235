// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbySnooker: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-snooker"><g id="Property 1=sport-snooker"><path id="Vector" d="M6.39693 23.998H25.6031L16 8.48531L6.39693 23.998ZM16.7287 6.40594L27.8703 24.4039C28.2238 24.9749 27.8131 25.7121 27.1416 25.7121H4.85841C4.1869 25.7121 3.77624 24.9749 4.12969 24.4039L15.2713 6.40594C15.6063 5.86469 16.3937 5.86469 16.7287 6.40594ZM10.8577 23.1409C9.88889 23.1409 9.14363 22.3211 9.14363 21.4268C9.14363 20.5325 9.96342 19.7127 10.8577 19.7127C11.752 19.7127 12.5718 20.5325 12.5718 21.4268C12.5718 22.3211 11.8266 23.1409 10.8577 23.1409ZM16 23.1409C15.0312 23.1409 14.2859 22.3211 14.2859 21.4268C14.2859 20.5325 15.1057 19.7127 16 19.7127C16.8943 19.7127 17.7141 20.5325 17.7141 21.4268C17.7141 22.3211 16.9688 23.1409 16 23.1409ZM13.4289 18.8557C12.46 18.8557 11.7148 18.0359 11.7148 17.1416C11.7148 16.2473 12.5346 15.4275 13.4289 15.4275C14.3232 15.4275 15.143 16.2473 15.143 17.1416C15.143 18.0359 14.3977 18.8557 13.4289 18.8557ZM18.5711 18.8557C17.6023 18.8557 16.857 18.0359 16.857 17.1416C16.857 16.2473 17.6768 15.4275 18.5711 15.4275C19.4654 15.4275 20.2852 16.2473 20.2852 17.1416C20.2852 18.0359 19.54 18.8557 18.5711 18.8557ZM21.1423 23.1409C20.1734 23.1409 19.4282 22.3211 19.4282 21.4268C19.4282 20.5325 20.248 19.7127 21.1423 19.7127C22.0366 19.7127 22.8564 20.5325 22.8564 21.4268C22.8564 22.3211 22.1111 23.1409 21.1423 23.1409ZM16 14.5705C15.0312 14.5705 14.2859 13.7507 14.2859 12.8564C14.2859 11.9621 15.1057 11.1423 16 11.1423C16.8943 11.1423 17.7141 11.9621 17.7141 12.8564C17.7141 13.7507 16.9688 14.5705 16 14.5705Z" fill="white" /></g></SvgBase>);
};
const MemoBetbySnooker = React.memo(BetbySnooker);
MemoBetbySnooker.displayName = 'BetbySnooker';
export default MemoBetbySnooker;