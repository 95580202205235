const languages = {
  en: {
    code: 'en',
    name: 'English',
    countryFlag: 'US',
    currency: 'usd',
    hreflang: 'en',
  },
  // zh: {
  //   code: 'zh',
  //   name: '简体中文',
  //   countryFlag: 'ZH',
  //   currency: 'cny',
  //   englishAbbreviation: 'Simplified Chinese',
  //   hreflang: 'zh',
  // },
  'pt-br': {
    code: 'pt-br',
    name: 'Português Brasil',
    countryFlag: 'BR',
    currency: 'brl',
    englishAbbreviation: 'Portuguese',
    hreflang: 'pt-br',
  },
  ru: {
    code: 'ru',
    name: 'Русский',
    countryFlag: 'RU',
    currency: 'rub',
    englishAbbreviation: 'Russian',
    hreflang: 'ru',
  },
  de: {
    code: 'de',
    name: 'Deutsch',
    countryFlag: 'DE',
    currency: 'eur',
    englishAbbreviation: 'German',
    hreflang: 'de',
  },
  'es-mx': {
    code: 'es-mx',
    name: 'Español',
    countryFlag: 'ES',
    currency: 'usd',
    englishAbbreviation: 'Spanish (Mexico)',
    hreflang: 'es-mx',
  },
  tr: {
    code: 'tr',
    name: 'Türkçe',
    countryFlag: 'TR',
    currency: 'try',
    englishAbbreviation: 'Turkish',
    hreflang: 'tr',
  },
  hi: {
    code: 'hi',
    name: 'हिन्दी',
    countryFlag: 'IN',
    currency: 'usd',
    englishAbbreviation: 'Hindi',
    hreflang: 'hi',
  },
  pl: {
    code: 'pl',
    name: 'Język polski',
    countryFlag: 'PL',
    currency: 'pln',
    englishAbbreviation: 'Polish',
    hreflang: 'pl',
  },
  vi: {
    code: 'vi',
    name: 'Tiếng Việt',
    countryFlag: 'VN',
    currency: 'vnd',
    englishAbbreviation: 'Vietnamese',
    hreflang: 'vi',
  },
};

module.exports = languages;
