export const APP_SIDE_BAR_EXPAND_WIDTH = 262;
export const APP_SIDE_BAR_COLLAPSE_WIDTH = 64;
export const APP_PAGE_CONTENT_MAX_WIDTH = 1145;
export const APP_FIXED_HEADER_HEIGHT = 56;
export const APP_FIXED_MOBILE_BOTTOM_BAR_HEIGHT = 68;

export enum THEME_MODE_ENU {
  Light = 'light',
  Dark = 'dark',
}

export const RECOIL_KEY_PREFIX = '@crown-recoil';
