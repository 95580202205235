// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonSocialYoutube: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-social-youtube"><path fillRule="evenodd" clipRule="evenodd" d="M17.792 0.423618C18.6521 0.654682 19.3325 1.33505 19.5635 2.19512C19.9872 3.76123 20 7.00898 20 7.00898C20 7.00898 20 10.2696 19.5764 11.8228C19.3453 12.6829 18.665 13.3633 17.8049 13.5944C16.2516 14.018 10 14.018 10 14.018C10 14.018 3.74839 14.018 2.19512 13.5944C1.33504 13.3633 0.654686 12.6829 0.42362 11.8228C0 10.2567 0 7.00898 0 7.00898C0 7.00898 0 3.76123 0.410783 2.20796C0.641849 1.34788 1.32221 0.667524 2.18229 0.43646C3.73556 0.0128338 9.98716 0 9.98716 0C9.98716 0 16.2388 0 17.792 0.423618ZM13.1836 7.00899L7.99743 10.0128V4.00514L13.1836 7.00899Z" fill="white" /></SvgBase>);
};
const MemoMoonSocialYoutube = React.memo(MoonSocialYoutube);
MemoMoonSocialYoutube.displayName = 'MoonSocialYoutube';
export default MemoMoonSocialYoutube;