// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const ActionGlobal03ActionBanxuanLine: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" sx={propSx}  {...restProps} iconName="i-action-global03-action-banxuan-line"><title>{"global_03_action_banxuan_line"}</title><g id="控件" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="icon/01_global/03_action/banxuan/48/01_line"><rect id="bg" x="0" y="0" width="48" height="48" /><g transform="translate(4.000000, 4.000000)" fill="#000000" fillRule="nonzero" id="形状"><path d="M34,0 C37.2383969,0 39.8775718,2.56557489 39.9958615,5.77506174 L40,6 L40,34 C40,37.2383969 37.4344251,39.8775718 34.2249383,39.9958615 L34,40 L6,40 C2.76160306,40 0.122428238,37.4344251 0.00413847206,34.2249383 L0,34 L0,6 C0,2.76160306 2.56557489,0.122428238 5.77506174,0.00413847206 L6,0 L34,0 Z M34,4 L6,4 C4.9456382,4 4.08183488,4.81587779 4.00548574,5.85073766 L4,6 L4,34 C4,35.0543618 4.81587779,35.9181651 5.85073766,35.9945143 L6,36 L34,36 C35.0543618,36 35.9181651,35.1841222 35.9945143,34.1492623 L36,34 L36,6 C36,4.9456382 35.1841222,4.08183488 34.1492623,4.00548574 L34,4 Z M26,12 C27.0543618,12 27.9181651,12.8158778 27.9945143,13.8507377 L28,14 L28,26 C28,27.0543618 27.1841222,27.9181651 26.1492623,27.9945143 L26,28 L14,28 C12.9456382,28 12.0818349,27.1841222 12.0054857,26.1492623 L12,26 L12,14 C12,12.9456382 12.8158778,12.0818349 13.8507377,12.0054857 L14,12 L26,12 Z" /></g></g></g></SvgBase>);
};
const MemoActionGlobal03ActionBanxuanLine = React.memo(ActionGlobal03ActionBanxuanLine);
MemoActionGlobal03ActionBanxuanLine.displayName = 'ActionGlobal03ActionBanxuanLine';
export default MemoActionGlobal03ActionBanxuanLine;