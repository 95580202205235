// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const CasinoLive: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="6 6 16 15.49" sx={propSx}  {...restProps} iconName="i-casino-live"><path d="M14.1177 18.1848C14.5121 18.1207 14.7799 17.7491 14.7159 17.3547C14.6519 16.9602 14.2802 16.6924 13.8858 16.7564C13.4914 16.8204 13.2235 17.1921 13.2876 17.5865C13.3516 17.9809 13.7232 18.2488 14.1177 18.1848Z" fill="currentColor" fillOpacity="0.7" /><path d="M11.5 9.61941L14.001 8.63081L16.502 9.61941C17.1102 9.61941 17.1102 6 16.502 6L14.001 6.9886L11.5 6C10.8882 6 10.8882 9.61941 11.5 9.61941Z" fill="currentColor" fillOpacity="0.7" /><path d="M14.5134 15.0866C14.7959 14.8041 14.7959 14.346 14.5134 14.0634C14.2308 13.7808 13.7727 13.7808 13.4902 14.0634C13.2076 14.346 13.2076 14.8041 13.4902 15.0866C13.7727 15.3692 14.2308 15.3692 14.5134 15.0866Z" fill="currentColor" fillOpacity="0.7" /><path d="M14.1667 12.3835C14.5556 12.2917 14.7965 11.902 14.7047 11.5131C14.6129 11.1242 14.2232 10.8833 13.8343 10.9751C13.4454 11.0669 13.2046 11.4566 13.2963 11.8455C13.3881 12.2344 13.7778 12.4752 14.1667 12.3835Z" fill="currentColor" fillOpacity="0.7" /><path d="M9.90499 9.5758C7.00637 9.59577 5.12813 12.6405 6.40995 15.2405L7.92598 18.3152C8.88462 20.2597 10.8645 21.4907 13.0324 21.4907L9.90499 9.5758Z" fill="currentColor" fillOpacity="0.7" /><path d="M18.0951 9.57582L14.9677 21.4898C17.1356 21.4898 19.1155 20.2588 20.0741 18.3143L21.5902 15.2396C22.872 12.6406 20.9928 9.59489 18.0951 9.57491V9.57582Z" fill="currentColor" fillOpacity="0.7" /></SvgBase>);
};
const MemoCasinoLive = React.memo(CasinoLive);
MemoCasinoLive.displayName = 'CasinoLive';
export default MemoCasinoLive;