import GlobalStyles from '@mui/material/GlobalStyles';

export default function GlobalStyle() {
  return (
    <GlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
          WebkitTapHighlightColor: 'transparent',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
          fontFamily: [
            'Work Sans',
            // 'Readex Pro',
            '-apple-system',
            'BlinkMacSystemFont',
            'Roboto',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          // background: 'red',
        },
        'div#__next': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        '::-webkit-scrollbar': {
          width: '5px',
          height: '5px',
        },

        '::-webkit-scrollbar-corner,::-webkit-scrollbar-track': {
          backgroundColor: 'none',
        },

        '::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(203,215,255,.1);',
          borderRadius: '6px',
        },
        '.MuiMenuItem-root.Mui-selected': {
          backgroundColor: '#40404A !important',
        },
        // '::-webkit-scrollbar-thumb:hover': {
        //   backgroundColor: '#555',
        // },
      }}
    />
  );
}
