// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyBandy: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-bandy"><g id="Property 1=sport-bandy"><path id="Vector" d="M22.3747 9.30859C22.1623 9.73524 21.9538 10.1545 21.7493 10.5664L23.3302 12.8242L23.9354 11.5376L22.3747 9.30859ZM22.7813 8.49251L24.3285 10.702L25.9066 7.34697C26.1733 6.77514 25.9259 6.09542 25.3541 5.82878C24.7822 5.56213 24.1025 5.80952 23.8255 6.40289C23.4678 7.1173 23.1198 7.81384 22.7813 8.49251ZM21.3438 11.3841C19.1043 15.9042 17.3652 19.4923 16.1272 22.1473C16.1167 22.1697 16.1054 22.1915 16.0933 22.2127C15.9338 22.5755 15.538 22.7911 15.1338 22.7087L7.32791 21.1176C6.43609 21.1591 5.72106 21.9104 5.72106 22.8368V24.5579C5.72106 25.5111 6.4781 26.2789 7.40583 26.2789H15.8902C16.7457 26.2789 16.9452 26.0929 17.8732 24.4252L22.9372 13.6597L21.3438 11.3841ZM14.8165 20.8875C16.4895 17.3233 18.9787 12.24 22.2761 5.654C22.9444 4.22072 24.6481 3.60062 26.0814 4.26897C27.5147 4.93732 28.1348 6.64104 27.4652 8.07693L19.4066 25.2088C19.3897 25.2447 19.3707 25.2788 19.3498 25.3112C18.1629 27.4355 17.5467 28 15.8902 28H7.40583C5.52209 28 4 26.4562 4 24.5579V22.8368C4 20.9385 5.52209 19.3947 7.40583 19.3947L7.5777 19.4121L14.8165 20.8875ZM7.44211 10.3592C5.77871 10.3592 4.43026 9.01073 4.43026 7.34733C4.43026 5.68393 5.77871 4.33548 7.44211 4.33548C9.10551 4.33548 10.454 5.68393 10.454 7.34733C10.454 9.01073 9.10551 10.3592 7.44211 10.3592ZM7.44211 9.49865C8.63025 9.49865 9.59343 8.53547 9.59343 7.34733C9.59343 6.15919 8.63025 5.19601 7.44211 5.19601C6.25397 5.19601 5.29079 6.15919 5.29079 7.34733C5.29079 8.53547 6.25397 9.49865 7.44211 9.49865Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyBandy = React.memo(BetbyBandy);
MemoBetbyBandy.displayName = 'BetbyBandy';
export default MemoBetbyBandy;