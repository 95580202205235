//https://www.npmjs.com/package/material-ui-confirm

export const ConfirmConfig: any = {
  // cancel btn
  confirmationButtonProps: {
    color: 'primary',
    sx: {
      backgroundColor: '#FDCB35',
      color: (theme) => theme.palette.grey[900],
      '&:hover': {
        backgroundColor: '#FDCB35',
      },
    },
  },
  //confirm btn
  cancellationButtonProps: {
    color: 'secondary',
    sx: {
      backgroundColor: (theme) => theme.palette.grey[800],
      color: (theme) => theme.palette.grey[50],
      ml: 2,
      '&:hover': {
        backgroundColor: (theme) => theme.palette.grey[800],
      },
    },
  },
  dialogProps: {
    sx: {
      '& .MuiDialog-paper': {
        width: '343px',
        background: (theme) => theme.palette.grey[900],
      },
      // description p
      '& .MuiTypography-root.MuiDialogContentText-root': {
        textAlign: 'start',
        fontSize: '14px',
        color: (theme) => theme.palette.grey[50],
      },
      '& .MuiDialogActions-root': {
        justifyContent: 'center',
        p: 3,
        paddingTop: 0,
        '& .MuiButton-root': {
          flex: 1,
          height: '42px',
        },
      },
    },
  },
};
