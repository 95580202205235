// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyStockcarracing: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-stockcarracing"><g id="Property 1=sport-stockcarracing"><path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M8.53333 7H6.66667L2 25H3.86667L8.53333 7ZM10.4001 7H12.2667L7.60007 25H5.7334L10.4001 7ZM14.1335 7H16.9335L12.2668 25H9.4668L14.1335 7ZM23.4665 7H29.9998L25.3331 25H18.7998L23.4665 7ZM21.6005 7H18.8005L14.1338 25H16.9338L21.6005 7Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyStockcarracing = React.memo(BetbyStockcarracing);
MemoBetbyStockcarracing.displayName = 'BetbyStockcarracing';
export default MemoBetbyStockcarracing;