// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyVirtualports: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-virtualports"><g id="Property 1=sport-virtualsport"><path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M7 5C5.34315 5 4 6.34315 4 8V14C4 15.6569 5.34315 17 7 17H12V18H8C7.44772 18 7 18.4477 7 19C7 19.5523 7.44772 20 8 20H12V21C12 22.6569 13.3431 24 15 24H25C26.6569 24 28 22.6569 28 21V15C28 13.3431 26.6569 12 25 12H20V8C20 6.34315 18.6569 5 17 5H7ZM18 12V8C18 7.44772 17.5523 7 17 7H7C6.44772 7 6 7.44772 6 8V14C6 14.5523 6.44772 15 7 15H12C12 13.3431 13.3431 12 15 12H18ZM14 19V21C14 21.5523 14.4477 22 15 22H25C25.5523 22 26 21.5523 26 21V15C26 14.4477 25.5523 14 25 14H15C14.4477 14 14 14.4477 14 15V19ZM16 25C15.4477 25 15 25.4477 15 26C15 26.5523 15.4477 27 16 27H24C24.5523 27 25 26.5523 25 26C25 25.4477 24.5523 25 24 25H16Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyVirtualports = React.memo(BetbyVirtualports);
MemoBetbyVirtualports.displayName = 'BetbyVirtualports';
export default MemoBetbyVirtualports;