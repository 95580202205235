// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyArenaOfValor: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-arena-of-valor"><g id="Property 1=sport-arena_of_valor"><path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M15.9286 21.2199C19.0535 21.2199 21.586 18.7089 21.586 15.6099C21.586 12.5151 19.0535 10 15.9286 10C12.8037 10 10.2712 12.5109 10.2712 15.6099C10.2712 18.7089 12.8037 21.2199 15.9286 21.2199ZM15.9234 19.2803C17.8384 19.2803 19.3904 17.6244 19.3904 15.5807C19.3904 13.5327 17.8384 11.8769 15.9234 11.881C14.0084 11.881 12.4564 13.5369 12.4564 15.5807C12.4564 17.6244 14.0084 19.2803 15.9234 19.2803ZM11.3272 21.2198H7.26362L7.44302 20.7902C7.44302 20.7902 7.69752 20.3606 7.39296 19.4681L5.31108 14.2877L4.42242 16.6235L5.59061 16.6485L6.37914 18.5004L3.63807 18.5755C3.63807 18.5755 2.79948 20.2522 3.33351 21.2157H0C0 21.2157 1.34759 19.2095 1.49778 18.6255V18.3961L0.684224 16.7277L2.28631 16.7027L5.18175 10.1251C5.18175 10.1251 5.36115 9.84567 5.46128 10.1251L9.3455 18.5796C9.3455 18.5796 10.0798 20.8153 11.3272 21.2198ZM15.9462 17.0823C16.7597 17.0823 17.4189 16.4233 17.4189 15.61C17.4189 14.7966 16.7597 14.1376 15.9462 14.1376C15.1326 14.1376 14.4734 14.7966 14.4734 15.61C14.4734 16.4233 15.1326 17.0823 15.9462 17.0823ZM30.5772 12.3858C30.5772 12.3858 31.5159 10.3295 31.9999 10.1502V10H28.4202C28.4202 10 28.7498 10.3796 28.8249 10.5047C28.8249 10.5047 28.7749 11.0135 28.6998 11.343L26.9975 15.4056C26.9975 15.4056 26.8181 15.635 26.9975 15.8894C26.9975 15.8894 28.0114 18.0207 28.0406 18.3502C28.0364 18.3502 30.5021 12.5401 30.5772 12.3858ZM26.918 20.9071L27.6815 18.9634L24.5274 11.4224C24.3772 11.1471 24.4273 10.4088 24.4273 10.4088L24.6067 10.0042H20.7225V10.1919L20.9519 10.2295C21.3066 10.3588 22.0951 11.6267 22.0951 11.6267C22.2995 11.8561 26.3381 20.9071 26.3381 20.9071C26.6427 21.5578 26.918 20.9071 26.918 20.9071Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyArenaOfValor = React.memo(BetbyArenaOfValor);
MemoBetbyArenaOfValor.displayName = 'BetbyArenaOfValor';
export default MemoBetbyArenaOfValor;