import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Stack,
  SxProps,
  Theme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { forwardRef, useState } from 'react';

const IconSX: SxProps<Theme> = {
  transition: 'opacity 0.3s',
  color: 'inherit',
};

const ChildWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
}));

const WrappedButton = forwardRef(
  (
    props: ButtonProps & {
      isLoading?: boolean;
      children?: React.ReactNode;
    },
    ref,
  ) => {
    const { isLoading, children, ...rest } = props;

    return (
      <Button
        color={'primary'}
        variant={'contained'}
        {...rest}
        disabled={isLoading || rest.disabled}
        ref={ref as any}
      >
        <ChildWrapper as={'span'}>
          {isLoading !== undefined ? (
            <Stack
              sx={{
                width: !isLoading ? 0 : '1em',
                marginRight: !isLoading ? 0 : 1,
                overflow: 'hidden',
                transition: 'all 0.3s',
              }}
              component={'span'}
            >
              <CircularProgress size={'1em'} color="inherit" sx={IconSX} />
            </Stack>
          ) : null}
          {children}
        </ChildWrapper>
      </Button>
    );
  },
);

export default WrappedButton;
