import { useAtom } from 'jotai';
import { GLOBAL_MODALS, globalModals } from '@/state/globalSettings';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import {
  globalQueryKeyReferralCode,
  globalQueryKeyScaleoReferralCode,
} from '@/constants/globalQueryKeyMaps';
import { useAtomValue } from 'jotai/index';
import { isLoggedIn } from '@/state/userInfo';
import { isServer } from '@tanstack/react-query';

/**
 * @description check invitation code from url query, if exist, open register modal and fill the code
 */
export const useCheckInvitationCode = () => {
  const router = useRouter();

  const refCode = router.query[globalQueryKeyReferralCode];
  const scaleoRefCode = router.query[globalQueryKeyScaleoReferralCode];
  const isLogin = useAtomValue(isLoggedIn);

  const [modals, setModals] = useAtom(globalModals);

  const handleClose = () => {
    setModals({});
  };
  const openRegisterModal = useCallback(() => {
    if (isServer) return;
    if (!refCode && !scaleoRefCode) return;
    if (isLogin) {
      handleClose();
    } else {
      setModals({
        [GLOBAL_MODALS.REGISTER]: true,
      });
    }
  }, [isLogin, refCode, isServer, scaleoRefCode]);

  useEffect(() => {
    openRegisterModal();
  }, [openRegisterModal]);
};
