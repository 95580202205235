// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyRally: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-rally"><g id="Property 1=sport-rally"><path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M10.5728 27C9.78481 27 9.0061 26.7774 8.37871 26.3006C5.70496 24.2686 4 21.7334 4 18C4 11.3726 9.37259 6 16 6C22.6274 6 28 11.3726 28 18C28 21.7334 26.2951 24.2686 23.6213 26.3006C22.9939 26.7774 22.2152 27 21.4272 27H10.5728ZM6.03088 17.2081C6.01041 17.4694 6 17.7335 6 18C6 20.9641 7.2854 22.9576 9.58885 24.7083C9.81601 24.8809 10.1514 25 10.5728 25H15V21.874C13.5384 21.4978 12.4001 20.3162 12.0863 18.8306L6.03088 17.2081ZM6.38773 15.2331L12.1872 16.7871C12.7009 15.1709 14.2137 14 16 14C17.7975 14 19.318 15.1855 19.8223 16.8174L25.621 15.2636C24.4308 11.071 20.5741 8 16 8C11.437 8 7.58789 11.0562 6.38773 15.2331ZM17 21.874C18.4502 21.5007 19.5821 20.3348 19.9061 18.8655L25.9716 17.2402C25.9904 17.491 26 17.7444 26 18C26 20.9641 24.7146 22.9576 22.4111 24.7083C22.184 24.8809 21.8486 25 21.4272 25H17V21.874ZM16 20C17.1046 20 18 19.1046 18 18C18 16.8954 17.1046 16 16 16C14.8954 16 14 16.8954 14 18C14 19.1046 14.8954 20 16 20Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyRally = React.memo(BetbyRally);
MemoBetbyRally.displayName = 'BetbyRally';
export default MemoBetbyRally;