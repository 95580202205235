// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyRugbyleague: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-rugbyleague"><g id="Property 1=sport-rugbyleague"><path id="Vector" d="M6.83344 16.9576C7.03369 18.4832 8.12417 20.5098 9.92362 22.3092C11.5062 23.8918 13.2644 24.926 14.703 25.2885C17.0255 24.4795 19.4151 23.1215 21.2833 21.2532C23.0624 19.4742 24.3749 17.1973 25.1891 14.9572C24.9702 13.4381 23.8844 11.4385 22.1065 9.66067C20.5415 8.09566 18.8047 7.06692 17.3751 6.69369C15.0293 7.49631 12.6139 8.84958 10.7468 10.7167C8.96938 12.4941 7.65381 14.7433 6.83344 16.9576ZM6.31089 18.6064C5.55025 21.4942 5.68541 24.0943 6.79558 25.2044C7.87927 26.2881 10.3827 26.4428 13.1875 25.742C11.8868 25.1692 10.5156 24.2183 9.26509 22.9677C7.87293 21.5756 6.85207 20.0339 6.31089 18.6064ZM18.8893 6.24857C20.1761 6.82343 21.5294 7.76654 22.765 9.00214C24.1411 10.3782 25.1543 11.9002 25.7002 13.3138C26.4452 10.4336 26.3142 7.84511 25.2345 6.76546C24.1803 5.71125 21.6876 5.56146 18.8893 6.24857ZM5.47851 26.5215C2.46492 23.5079 4.19395 14.6354 9.42972 9.3996C14.6317 4.19764 23.589 2.48581 26.5516 5.44839C29.5142 8.41097 27.8024 17.3683 22.6004 22.5703C17.3646 27.806 8.49211 29.5351 5.47851 26.5215Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyRugbyleague = React.memo(BetbyRugbyleague);
MemoBetbyRugbyleague.displayName = 'BetbyRugbyleague';
export default MemoBetbyRugbyleague;