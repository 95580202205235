const envConfig = require('./base');

// Default config
const defaultConfig = {
  name: 'production',
  endpoints: {
    domain: 'https://www.moonclub.com',
    baseAPI: 'https://api.moonclub.com',
    websocket: 'wss://api.moonclub.com/api/game-ws',
  },
};

// Specific environment config
const environmentConfig = {
  local: {
    ...defaultConfig,
    name: 'localhost',
    endpoints: {
      telegramBot: 'https://t.me/moonclub_local_bot',
      domain: 'http://0.0.0.0:4200',
      baseAPI: 'https://api.beta.moonclub.com',
      websocket: 'wss://api.beta.moonclub.com/api/game-ws',
      affiliatesDomain: 'https://beta.affiliates.moonclub.com/',
    },
  },
  'local-telegram': {
    ...defaultConfig,
    name: 'localhost',
    endpoints: {
      telegramBot: 'https://t.me/moonclub_local_bot',
      domain: 'http://0.0.0.0:4200',
      baseAPI: 'https://api.beta.moonclub.com',
      websocket: 'wss://api.beta.moonclub.com/api/game-ws',
      affiliatesDomain: 'https://beta.affiliates.moonclub.com/',
    },
  },
  beta: {
    ...defaultConfig,
    name: 'development',
    hostname: 'beta.moonclub.com',
    endpoints: {
      telegramBot: 'https://t.me/moonclub_tg_beta_bot',
      domain: 'https://beta.moonclub.com',
      baseAPI: 'https://api.beta.moonclub.com',
      websocket: 'wss://api.beta.moonclub.com/api/game-ws',
      affiliatesDomain: 'https://beta.affiliates.moonclub.com/',
    },
  },
  'beta-telegram': {
    ...defaultConfig,
    name: 'beta-telegram',
    endpoints: {
      telegramBot: 'https://t.me/moonclub_tg_beta_bot',
      domain: 'https://tg.beta.moonclub.com/',
      baseAPI: 'https://api.beta.moonclub.com',
      websocket: 'wss://api.beta.moonclub.com/api/game-ws',
      affiliatesDomain: 'https://beta.affiliates.moonclub.com/',
    },
  },
  staging: {
    ...defaultConfig,
    hostname: 'staging.moonclub.com',
    endpoints: {
      telegramBot: 'https://t.me/moonclub_staging_bot',
      domain: 'https://staging.moonclub.com',
      baseAPI: 'https://api.staging.moonclub.com',
      websocket: 'wss://api.staging.moonclub.com/api/game-ws',
      affiliatesDomain: 'https://affiliates.moonclub.com/',
    },
  },
  'staging-telegram': {
    ...defaultConfig,
    endpoints: {
      telegramBot: 'https://t.me/moonclub_staging_bot',
      domain: 'https://tg.staging.moonclub.com',
      baseAPI: 'https://api.staging.moonclub.com',
      websocket: 'wss://api.staging.moonclub.com/api/game-ws',
      affiliatesDomain: 'https://affiliates.moonclub.com/',
    },
  },
  production: {
    ...defaultConfig,
    GOOGLE_TAG_MANAGER_ID: 'GTM-TLH4L3WM',
    hostname: 'www.moonclub.com',
    endpoints: {
      telegramBot: 'https://t.me/MoonClubComBot',
      domain: 'https://www.moonclub.com',
      baseAPI: 'https://api.moonclub.com',
      websocket: 'wss://api.moonclub.com/api/game-ws',
      affiliatesDomain: 'https://affiliates.moonclub.com/',
    },
  },
  'production-telegram': {
    ...defaultConfig,
    GOOGLE_TAG_MANAGER_ID: 'GTM-TLH4L3WM',
    endpoints: {
      telegramBot: 'https://t.me/MoonClubComBot',
      domain: 'https://www.tg.moonclub.com/',
      baseAPI: 'https://api.moonclub.com',
      websocket: 'wss://api.moonclub.com/api/game-ws',
      affiliatesDomain: 'https://affiliates.moonclub.com/',
    },
  },
};

const env = process.env.NEXT_PUBLIC_MOON_ENV || 'production';

Object.assign(envConfig, environmentConfig[env] || defaultConfig);

module.exports = envConfig;
