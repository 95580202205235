// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const StickiesBrokenHeart: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" height="40" width="40" sx={propSx}  {...restProps} iconName="i-stickies-broken-heart"><path fill="#00034a" stroke="#00034a" strokeMiterlimit="10" d="M22.439 6.636a10.67 10.67 0 0 1 7.133 -0.848c6.682 0.961 10.925 6.817 9.726 13.388 -1.707 9.34 -11.51 14.237 -18.797 16.628a4.56 4.56 0 0 1 -3.055 -0.28C9.716 31.334 0.5 23.981 0.5 14.357 0.5 8.178 3.932 4.062 10.795 4.062a10.68 10.68 0 0 1 6.863 2.12l1.445 1.447 0.588 5.465 -3.672 4.897 3.923 4.675 -0.33 1.614 3.54 -2.829 -2.458 -4.877 3.672 -3.673 -1.927 -6.265Z" strokeWidth="1" /><path fill="#fdcb35" stroke="#00034a" strokeMiterlimit="10" d="M29.572 5.788a10.67 10.67 0 0 0 -7.133 0.848l1.927 6.265 -3.672 3.673 2.458 4.877 -5.62 4.492 0.964 -4.723 -3.923 -4.675 3.673 -4.897 -0.588 -5.465a10.68 10.68 0 0 0 -6.863 -2.12C3.932 4.062 0.5 8.177 0.5 14.356 0.5 23.85 9.262 30.425 16 34.078a4.56 4.56 0 0 0 3.055 0.28c7.288 -2.39 17.09 -7.287 18.797 -16.627 1.108 -6.073 -1.533 -10.739 -8.28 -11.943Z" strokeWidth="1" /></SvgBase>);
};
const MemoStickiesBrokenHeart = React.memo(StickiesBrokenHeart);
MemoStickiesBrokenHeart.displayName = 'StickiesBrokenHeart';
export default MemoStickiesBrokenHeart;