// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyMyBets: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-my-bets"><g id="left panel menu icon" clipPath="url(#clip0_7956_64037)"><path id="Vector" d="M19.7767 6.65015L18.0423 4.91574C17.6729 5.28512 17.1623 5.51388 16.5986 5.51388C15.471 5.51388 14.5566 4.59949 14.5566 3.47197C14.5566 2.90821 14.7854 2.39758 15.1548 2.0282L13.436 0.293793C13.2997 0.15629 13.1104 0.0712891 12.9016 0.0712891C12.6929 0.0712891 12.5035 0.15629 12.3672 0.293793L9.58594 3.05946L17.0111 10.4846L19.7767 7.71891C19.9142 7.58266 19.9992 7.39328 19.9992 7.18453C19.9992 6.97578 19.9142 6.7864 19.7767 6.65015Z" fill="white" fillOpacity="0.7" /><path id="Vector_2" d="M8.55733 4.08984L0.222816 12.4244C0.0809389 12.5619 -0.0078125 12.7537 -0.0078125 12.9662C-0.0078125 13.1787 0.0803139 13.3712 0.222191 13.5081L1.95659 15.2269C2.32597 14.8575 2.83661 14.6288 3.40037 14.6288C4.52789 14.6288 5.44228 15.5432 5.44228 16.6707C5.44228 17.2344 5.21352 17.7451 4.84414 18.1144L6.57854 19.8488C6.7148 19.9864 6.90417 20.0714 7.11293 20.0714C7.32168 20.0714 7.51106 19.9864 7.64731 19.8488L15.9818 11.5143L8.55733 4.08984Z" fill="white" fillOpacity="0.7" /></g><defs><clipPath id="clip0_7956_64037"><rect width="20.0003" height="20.0003" fill="white" transform="translate(0 0.0722656)" /></clipPath></defs></SvgBase>);
};
const MemoBetbyMyBets = React.memo(BetbyMyBets);
MemoBetbyMyBets.displayName = 'BetbyMyBets';
export default MemoBetbyMyBets;