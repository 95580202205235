import uiState from '@/state/ui';
import useTheme from '@/theme/useTheme';
import { useAtomValue } from 'jotai';
import { AccountCircle } from '@mui/icons-material';
import Link from 'next/link';
import { useAtom } from 'jotai/index';
import { GLOBAL_MODALS, globalModals } from '@/state/globalSettings';
import { isLoggedIn, isTemporaryLoginWithoutToken } from '@/state/userInfo';

export default function TgUserHead() {
  const { isHeaderTransparent } = useAtomValue(uiState);
  const { theme } = useTheme();
  const isLogin = useAtomValue(isLoggedIn);
  const isTemporaryLogin = useAtomValue(isTemporaryLoginWithoutToken);
  const [_, setModals] = useAtom(globalModals);
  const handleBindEmail = () => {
    setModals({
      [GLOBAL_MODALS.BIND_EMAIL]: true,
    });
  };

  const iconColor = isHeaderTransparent
    ? theme.palette.grey[900]
    : theme.palette.text.primary;

  if (!isLogin && !isTemporaryLogin) {
    return (
      <AccountCircle
        fontSize={'large'}
        sx={{
          color: iconColor,
        }}
      />
    );
  }

  return (
    <Link href={'/profile/general'}>
      <AccountCircle
        fontSize={'large'}
        sx={{
          color: iconColor,
        }}
      />
    </Link>
  );
}
