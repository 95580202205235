import { useRouter } from 'next/router';
import {
  globalQueryKeyReferralCode,
  globalQueryKeyScaleoReferralCode,
} from '@/constants/globalQueryKeyMaps';

export const useScaleoReferralCode = () => {
  const router = useRouter();
  return router.query[globalQueryKeyScaleoReferralCode] as string;
};
const useReferralCode = () => {
  const router = useRouter();
  return router.query[globalQueryKeyReferralCode] as string;
};

export default useReferralCode;
