import { LOCAL_KEY_SELECTED_WALLET_ID } from '@/constants/localStorageKeys';
import { fetchGeoInfo } from '@/helper/fetchGeoInfo';
import { updateUserInfoMeta } from '@/state/userInfo';
import { TUserInfo } from '@/types/user';
import { removeLocalStorage, setLocalUserInfo } from '@/utils/localStoreHelper';
import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
// import queryClient from '@/reactQueryKeys/queryClient';
import queryClient from '@/reactQueryKeys/queryClient';
import { QueryCache } from '@tanstack/react-query';
import { store } from '@/state/store';

const queryCache = new QueryCache();

const useUserLoginLogout = () => {
  const [userInfo, setUserInfo] = useAtom(updateUserInfoMeta,{store:store});

  const { pathname, push } = useRouter();

  const login = (userInfo: TUserInfo, onSuccess = () => void 0) => {
    setUserInfo({
      userMetaData: userInfo,
    });
    setLocalUserInfo(userInfo);
    /**
     * fetch & set geo info
     */
    fetchGeoInfo(true);

    onSuccess && onSuccess();
  };
  const logout = (redirectToPath?: string) => {
    setUserInfo({
      userMetaData: undefined,
    });
    setLocalUserInfo(undefined);
    removeLocalStorage(LOCAL_KEY_SELECTED_WALLET_ID);
    queryClient.invalidateQueries({
      queryKey: [],
    });
    // queryCache.clear();
    if (redirectToPath) {
      push(redirectToPath);
    }
  };
  return {
    login,
    logout,
  };
};

export default useUserLoginLogout;
