// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonCryptoTokenUsdt: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-crypto-token-usdt"><path d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z" fill="#24A980" /><path d="M53.9582 52.7846C53.6495 52.7846 51.8746 52.9389 48.0933 52.9389C45.0064 52.9389 42.9228 52.8617 42.1511 52.7846C30.4984 52.3215 21.701 50.2379 21.701 47.8457C21.701 45.3762 30.4212 43.3698 42.1511 42.8296V50.7781C42.9228 50.8553 45.0836 50.9325 48.1704 50.9325C51.7974 50.9325 53.6495 50.7781 53.9582 50.7781V42.8296C65.6881 43.3698 74.4084 45.3762 74.4084 47.8457C74.4084 50.2379 65.6881 52.3215 53.9582 52.7846ZM53.9582 41.9807V34.881H70.3183V24H25.8682V34.881H42.2283V41.9807C28.955 42.5981 19 45.2219 19 48.3859C19 51.5498 28.955 54.1736 42.2283 54.791V77.6334H54.0354V54.791C67.3087 54.1736 77.2637 51.5498 77.2637 48.3859C77.1865 45.2219 67.2315 42.5981 53.9582 41.9807Z" fill="#FFFDFA" /></SvgBase>);
};
const MemoMoonCryptoTokenUsdt = React.memo(MoonCryptoTokenUsdt);
MemoMoonCryptoTokenUsdt.displayName = 'MoonCryptoTokenUsdt';
export default MemoMoonCryptoTokenUsdt;