import {
  TBonusInfo,
  TPlayerPendingClaim,
  TPlayerSelectedPack,
  TUserInfo,
} from '@/types/user';
import { atomWithImmer } from 'jotai-immer';
import { atom } from 'jotai';
import { UTCStringTemplate } from '@/types/common';
import { getUserInfoFromCookie } from '@/helper/cookieHelper';
import { LOCAL_KEY_SELECTED_WALLET_ID } from '@/constants/localStorageKeys';
import { getLocalStorage, setLocalStorage } from '@/utils/localStoreHelper';
// import { getUserInfoFromCookie } from '@/helper/cookieHelper';
export type TUserInfoState = {
  userMetaData: Partial<TUserInfo> | null;
  isUserLoading: boolean;
};

export const userInfoState = atomWithImmer<TUserInfoState>({
  userMetaData: undefined,
  isUserLoading: true,
});

export type PlayerBonusInfoState = {
  allBonusPacks: TBonusInfo[];
  currentSelectedPack?: TPlayerSelectedPack;
  userInfo?: TUserInfo & {
    registerTime: UTCStringTemplate;
  };
  rewards: {
    playerPendingClaimRewards: TPlayerPendingClaim[];
    playerClaimedRewards: TPlayerPendingClaim[];
    playerExpiredRewards: TPlayerPendingClaim[];
  };
  loaded: boolean;
  finishTask?: TPlayerSelectedPack[];
  leftBonusTimes?: number;
};

export const userBonusInfoState = atomWithImmer<PlayerBonusInfoState>({
  allBonusPacks: [],
  loaded: false,
  rewards: {
    playerPendingClaimRewards: [],
    playerClaimedRewards: [],
    playerExpiredRewards: [],
  },
});

export type IUserWalletInfo = {
  id: string;
  uid: string;
  coin: string;
  coinName: string;
  symbol: string;
  fullName: string;
  // decimals: number;
  // logo: string;
  balance: string;
  frozenBalance: string;
  version: string;
  status: number;
  availableBalance?: number;
  chainNetworks: Array<{
    address: string;
    addressType: string;
    chain: string;
    coinKey: string;
    coinType: string;
    contract: string;
    feeCoin: string;
    feeDecimal: number;
    feeUnit: string;
    minTransferAmount: string;
    network: string;
    coinFullName: string;
    coinName: string;
    decimals: string;
    symbol: string;
  }>;
};

// export type IUserWalletInfo = {
//   availableBalance?: string;
//   depositBalance: string;
//   withdrawBalance: string;
//   balance: string;
//   coin: string;
//   frozenBalance: string;
//   version: string;
//   status: number;
//   cryptoCurrencies: Array<IUserWalletInfo_Currency>;
// };

export type TWalletState = {
  currentSelectedWallet: number | string;
  wallets: IUserWalletInfo[];
};

const walletState = atomWithImmer<TWalletState>({
  currentSelectedWallet: '',
  wallets: [],
});

export const userWalletInfoState = atom(
  (get) => {
    const currentState = get(walletState);
    let currentSelectedWallet = currentState.currentSelectedWallet;
    const walletIdCache = getLocalStorage(LOCAL_KEY_SELECTED_WALLET_ID);
    if (walletIdCache) {
      currentSelectedWallet = walletIdCache;
    }
    return {
      currentSelectedWallet,
      wallets: currentState.wallets.map((w) => ({
        ...w,
        availableBalance: Number(w.balance) - Number(w.frozenBalance),
      })),
    };
  },
  (
    get,
    set,
    newValue: Partial<TWalletState> & {
      update?: boolean;
    },
  ) => {
    const { update = true } = newValue;
    if (newValue.currentSelectedWallet) {
      setLocalStorage(
        LOCAL_KEY_SELECTED_WALLET_ID,
        newValue.currentSelectedWallet,
      );
    }
    set(walletState, (prevValue) => {
      return update
        ? Object.assign(prevValue, { ...newValue, update: undefined })
        : { ...newValue, update: undefined };
    });
  },
);

export const userLoginGeoInfoState = atomWithImmer<{
  country?: string;
  ip?: string;
  countryName?: string;
}>({});

// export const userFavoriteState = atomWithImmer<{
//   favoriteGames: TPagedGameListResponse['games']['result'];
// }>({
//   favoriteGames: [],
// });
//
// export const userRecentPlayedState = atomWithImmer<{
//   recentPlayedGames: TPagedGameListResponse['games']['result'];
// }>({
//   recentPlayedGames: [],
// });
