// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonSocialFacebook: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-social-facebook"><path fillRule="evenodd" clipRule="evenodd" d="M17.5565 0.0900879C18.9051 0.0900879 20 1.18501 20 2.5336V17.6466C20 18.9952 18.9051 20.0901 17.5565 20.0901H13.3976V12.5543H15.9991L16.4941 9.32696H13.3976V7.23255C13.3976 6.34962 13.8301 5.48896 15.2171 5.48896H16.625V2.7413C16.625 2.7413 15.3473 2.52325 14.1257 2.52325C11.5754 2.52325 9.90852 4.06892 9.90852 6.86716V9.32696H7.07363V12.5543H9.90852V20.0901H2.44352C1.09492 20.0901 0 18.9952 0 17.6466V2.5336C0 1.18501 1.09488 0.0900879 2.44352 0.0900879L17.5565 0.0900879Z" fill="#E5E5E5" /></SvgBase>);
};
const MemoMoonSocialFacebook = React.memo(MoonSocialFacebook);
MemoMoonSocialFacebook.displayName = 'MoonSocialFacebook';
export default MemoMoonSocialFacebook;