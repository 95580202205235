// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const CasinoPerson: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-casino-person"><path d="M6.00067 6C6.79632 6 7.55938 5.68393 8.12199 5.12132C8.6846 4.55871 9.00067 3.79565 9.00067 3C9.00067 2.20435 8.6846 1.44129 8.12199 0.87868C7.55938 0.316071 6.79632 0 6.00067 0C5.20502 0 4.44196 0.316071 3.87935 0.87868C3.31674 1.44129 3.00067 2.20435 3.00067 3C3.00067 3.79565 3.31674 4.55871 3.87935 5.12132C4.44196 5.68393 5.20502 6 6.00067 6ZM13.5007 7C14.1637 7 14.7996 6.73661 15.2684 6.26777C15.7373 5.79893 16.0007 5.16304 16.0007 4.5C16.0007 3.83696 15.7373 3.20107 15.2684 2.73223C14.7996 2.26339 14.1637 2 13.5007 2C12.8376 2 12.2017 2.26339 11.7329 2.73223C11.2641 3.20107 11.0007 3.83696 11.0007 4.5C11.0007 5.16304 11.2641 5.79893 11.7329 6.26777C12.2017 6.73661 12.8376 7 13.5007 7ZM0.615667 14.428C0.419603 14.3058 0.262124 14.1306 0.161434 13.9227C0.0607444 13.7148 0.0209556 13.4826 0.0466673 13.253C0.231023 11.8041 0.937417 10.4721 2.03348 9.50662C3.12954 8.54118 4.54004 8.00855 6.00067 8.00855C7.46129 8.00855 8.87179 8.54118 9.96786 9.50662C11.0639 10.4721 11.7703 11.8041 11.9547 13.253C12.0127 13.72 11.7827 14.173 11.3847 14.427C9.77791 15.4566 7.90902 16.0027 6.00067 16C4.09207 16.0032 2.22281 15.4575 0.615667 14.428ZM13.5007 14H13.3947C13.4647 13.703 13.4827 13.389 13.4427 13.067C13.2717 11.6923 12.7219 10.3922 11.8547 9.312C12.4128 9.09238 13.0092 8.9865 13.6088 9.00058C14.2084 9.01465 14.7991 9.1484 15.3463 9.39396C15.8936 9.63952 16.3862 9.99195 16.7953 10.4305C17.2044 10.8691 17.5217 11.3851 17.7287 11.948C17.7922 12.1277 17.7911 12.324 17.7253 12.5029C17.6596 12.6818 17.5335 12.8322 17.3687 12.928C16.2014 13.6318 14.8637 14.0025 13.5007 14Z" fill="white" fillOpacity="1" /></SvgBase>);
};
const MemoCasinoPerson = React.memo(CasinoPerson);
MemoCasinoPerson.displayName = 'CasinoPerson';
export default MemoCasinoPerson;