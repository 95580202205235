import { z } from 'zod';
import { TInferSchemaType } from '@/constants/schemas';

export enum GameProviderEnum {
  SELF = 'SELF',
  HUB88 = 'HUB88',
  BETBY = 'BETBY',
  CQ9 = 'CQ9',
  HACKSAW = 'HACKSAW',
  SLOTEGRATOR = 'SLOTEGRATOR',
}

export enum GameCategoryEnum {
  'Table Games' = 'Table Games',
  'Video Slots' = 'Video Slots',
  'Jackpot Slots' = 'Jackpot Slots',
  'Live Blackjack' = 'Live Blackjack',
  'Blackjack' = 'Blackjack',
  'Live Baccarat' = 'Live Baccarat',
  'Baccarat' = 'Baccarat',
  'Live Roulette' = 'Live Roulette',
  'Roulette' = 'Roulette',
  'Live Game Shows' = 'Live Game Shows',
  'Live Dice' = 'Live Dice',
  'Dice' = 'Dice',
  'Live Dragon Tiger' = 'Live Dragon Tiger',
  'Dragon Tiger' = 'Dragon Tiger',
  'Live Poker' = 'Live Poker',
  'Video Poker' = 'Video Poker',
  'Crash Games' = 'Crash Games',
  'Scratch Cards' = 'Scratch Cards',
  'Arcade Games' = 'Arcade Games',
  'Lottery' = 'Lottery',
  'Bingo' = 'Bingo',
  'Virtual Sports' = 'Virtual Sports',
  'Sportsbook' = 'Sportsbook',
  'Player Props' = 'Player Props',
  'Other Table Games' = 'Other Table Games',
  'Other Live Games' = 'Other Live Games',
  'Original' = 'Original',
}

export const SGameProvidersSchema = z.nativeEnum(GameProviderEnum);
export const SGameCategoriesSchema = z.nativeEnum(GameCategoryEnum);

export const SCurrencyInfoSchema = z.object({
  name: z.string(),
  fullName: z.string().optional(),
  symbol: z.string(),
  decimals: z.number(),
  logoUrl: z.string().optional(),
});

export type TCurrencyInfo = TInferSchemaType<typeof SCurrencyInfoSchema>;

export type TIAnchoredList<T> = {
  list: T[];
  anchor: string;
  hasNext?: boolean;
  hasPrevious?: boolean;
};

// export type TCurrencyInfo = {
//   number;
//   string;
//   string;
//   number;
// };
