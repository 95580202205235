export const renderMetaDescription = (desc: string) => {
  return (
    <>
      <meta name="description" content={desc} />
      {/*<meta key="meta:og:description" property="og:description" content={desc} />*/}
    </>
  );
};

export default renderMetaDescription;
