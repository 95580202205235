// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyFortnite: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-fortnite"><g id="Property 1=sport-fortnite"><path id="Vector" d="M20.6516 4L20.6229 4.25776C20.5943 4.48687 20.1647 9.14081 20.1647 9.19809C20.1647 9.21241 19.4344 9.22673 18.5036 9.22673H16.2554V13.0931H19.6492L19.6205 14.8401C19.6062 15.8138 19.5776 16.9594 19.5776 17.4033V18.2053H16.2554V27.0119L15.9833 27.0692C15.4535 27.1838 11.0859 28 11.043 28C11.0286 28 11.0143 23.8329 11 18.3484V4.01432H20.6516V4Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyFortnite = React.memo(BetbyFortnite);
MemoBetbyFortnite.displayName = 'BetbyFortnite';
export default MemoBetbyFortnite;