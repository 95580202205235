import React from 'react';
import InfiniteSpinner from './loading-svg/infinite-spinner.svg';
import FadeStaggerCircles from './loading-svg/fade-stagger-circles.svg';
import InfiniteLayers from './loading-svg/infinite-layers.svg';
import motionBlur1 from './loading-svg/motion-blur-1.svg';
import motionBlur2 from './loading-svg/motion-blur-2.svg';
import Ripples from './loading-svg/ripples.svg';
import Image from 'next/image';
import { isString } from 'lodash-es';

// https://www.svgbackgrounds.com/elements/animated-svg-preloaders/
export type LoadingVariant =
  | 'infinite-spinner'
  | 'fade-stagger-circles'
  | 'infinite-layers'
  | 'motion-blur-1'
  | 'motion-blur-2'
  | 'ripples';

export const TypeToComponent: Record<LoadingVariant, string> = {
  'infinite-spinner': InfiniteSpinner,
  'fade-stagger-circles': FadeStaggerCircles,
  'infinite-layers': InfiniteLayers,
  'motion-blur-1': motionBlur1,
  'motion-blur-2': motionBlur2,
  ripples: Ripples,
};

const Loading: React.FC<{
  variant?: LoadingVariant;
  size?: number | string;
}> = (props) => {
  const { size = 400, variant = 'infinite-spinner' } = props;
  const imageSize = isString(size) ? size : `${size}px`;
  const Component = TypeToComponent[variant];

  return (
    <div style={{ textAlign: 'center' }}>
      <Image
        src={Component}
        style={{
          width: imageSize,
          height: imageSize,
          display: 'inline-block',
        }}
        alt="Loading..."
      />
    </div>
  );
};

export default Loading;
