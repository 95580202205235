import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const CTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {},

  '& .MuiInputBase-input': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,

    '&:-webkit-autofill': {
      webkitTextFillColor: 'none !important',
      boxShadow: 'none',
    },

    '&:-internal-autofill-selected': {
      appearance: 'menulist-button',
      backgroundImage: 'none !important',
      backgroundColor: 'red !important',
      color: 'fieldtext !important',
    },

    '&:focus-visible': {
      outline: 'none',
    },
  },

  '& .MuiInputBase-input:focus': {
    backgroundColor: theme.palette.background.default,
    borderColor: 'white',
    borderRadius: theme.shape.borderRadius,
  },

  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
}));

export default CTextField;
