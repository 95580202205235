import request from '@/apis';
import { TFavouriteGame } from '@/types/user';
import { TGameListItem } from '@/apis/hub88';

export const favouriteGame = async <
  TAction extends 'view' | 'follow' | 'unfollow',
>(
  game: TFavouriteGame & {
    action: TAction;
  },
) => {
  return await request<{
    favorite: TFavouriteGame & {
      id: string;
      follow: TAction extends 'follow'
        ? true
        : TAction extends 'unfollow'
          ? false
          : boolean;
    };
  }>({
    url: '/api/mc-api/favourites/collect',
    data: game,
    method: 'post',
  });
};

export type TPagedGameListResponse = {
  games: (TGameListItem & {
    addTime: string;
    id: string;
    follow: boolean;
  })[];
  prevId?: string;
  nextId?: string;
};

export const fetchFavouriteGames = async ({
  prevId = undefined,
  nextId = undefined,
} = {}) => {
  return await request<TPagedGameListResponse>({
    url: '/api/mc-api/favourites/list',
    data: {
      prevId,
      nextId,
    },
    method: 'post',
  }).then((d) => {
    return d.data;
  });
};

export const recordGameVisit = async (
  gameId: string,
  provider?: TGameListItem['provider'],
) => {
  return await request({
    url: '/api/mc-api/footprints/add',
    method: 'post',
    data: {
      gameId,
      provider,
    },
  });
};

export const fetchGameVisitList = async ({
  prevId = undefined,
  nextId = undefined,
} = {}) => {
  return await request<TPagedGameListResponse>({
    url: '/api/mc-api/footprints/list',
    data: {
      prevId,
      nextId,
    },
    method: 'post',
  }).then((d) => {
    return d.data;
  });
};
