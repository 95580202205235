// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonMenuExpand: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-menu-expand"><g id="menu_expand" clipPath="url(#clip0_416_97403)"><path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M19.3333 6.49992C19.3333 5.99366 18.9229 5.58325 18.4166 5.58325H17.5C16.9937 5.58325 16.5833 5.99366 16.5833 6.49992V17.4999C16.5833 18.0062 16.9937 18.4166 17.5 18.4166H18.4166C18.9229 18.4166 19.3333 18.0062 19.3333 17.4999V6.49992Z" fill="white" /><path id="Vector_2" fillRule="evenodd" clipRule="evenodd" d="M4.66667 11.5417C4.66667 11.0354 5.07706 10.625 5.58333 10.625H11.0833C11.5896 10.625 12 11.0354 12 11.5417V12.4583C12 12.9646 11.5896 13.375 11.0833 13.375H5.58333C5.07706 13.375 4.66667 12.9646 4.66667 12.4583V11.5417Z" fill="white" /><path id="Vector_3" d="M10.0325 7.28238C9.74374 6.99365 9.25003 7.19814 9.25003 7.60647V16.3934C9.25003 16.8017 9.74374 17.0062 10.0325 16.7175L14.4259 12.3241C14.6049 12.1451 14.6049 11.8548 14.4259 11.6758L10.0325 7.28238Z" fill="white" /></g><defs><clipPath id="clip0_416_97403"><rect width="24" height="24" fill="white" /></clipPath></defs></SvgBase>);
};
const MemoMoonMenuExpand = React.memo(MoonMenuExpand);
MemoMoonMenuExpand.displayName = 'MoonMenuExpand';
export default MemoMoonMenuExpand;