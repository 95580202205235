export const SENTRY_PROJECT_NAME_MAPPING = {
  local: 'moonclub-local',
  'local-telegram': 'moonclub-local-telegram',
  beta: 'moonclub-beta',
  'beta-telegram': 'moonclub-beta-telegram',
  staging: 'moonclub-staging',
  'staging-telegram': 'moonclub-staging-telegram',
  production: 'moonclub-production',
  'production-telegram': 'moonclub-production-telegram',
  coming: 'moonclub-coming',
};

const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const sentryConfig = {
  dsn: dsn,
};
