import { APP_PAGE_CONTENT_MAX_WIDTH } from '@/constants/global';
import { styled } from '@mui/material/styles';
import { appSystemLayoutPaddingMixins } from '@/components/system/mixins';

export const AppCoreContainerStyle = styled('main')<{
  threaterMode?: boolean;
  isSportsPage?: boolean;
}>(({ theme, threaterMode, isSportsPage }) => ({
  position: 'relative',
  margin: '0 auto',
  zIndex: 2,
  // paddingTop: theme.spacing(1),
  ...((isSportsPage && {
    maxWidth: '100%',
  }) || {
    maxWidth: APP_PAGE_CONTENT_MAX_WIDTH,
  }),
  ...(!isSportsPage && appSystemLayoutPaddingMixins(theme)),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginLeft: 0,
    backgroundImage: 'none',
  },
}));
