// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonMedium: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-medium"><path d="M7.6407 6.3208C10.756 6.3208 13.2812 8.86366 13.2812 12.0002C13.2812 15.1368 10.7558 17.6794 7.6407 17.6794C4.52561 17.6794 2 15.1368 2 12.0002C2 8.86366 4.52542 6.3208 7.6407 6.3208ZM16.6485 6.65349C18.2061 6.65349 19.4688 9.04705 19.4688 12.0002H19.469C19.469 14.9526 18.2063 17.3469 16.6487 17.3469C15.091 17.3469 13.8283 14.9526 13.8283 12.0002C13.8283 9.04782 15.0908 6.65349 16.6485 6.65349ZM21.0081 7.21039C21.5558 7.21039 22 9.35483 22 12.0002C22 14.6448 21.556 16.79 21.0081 16.79C20.4602 16.79 20.0163 14.6454 20.0163 12.0002C20.0163 9.35502 20.4604 7.21039 21.0081 7.21039Z" fill="#F0F0F0" /></SvgBase>);
};
const MemoMoonMedium = React.memo(MoonMedium);
MemoMoonMedium.displayName = 'MoonMedium';
export default MemoMoonMedium;