import { Components, Theme } from '@mui/material/styles';

export default function Button(theme: Theme): Components<Theme> {
  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiButton: {
      // defaultProps: {
      //   disableFocusRipple: true,
      //   disableRipple: true,
      // },
      styleOverrides: {
        sizeSmall: {
          height: '30px',
        },
        sizeMedium: {
          height: '36px',
          fontSize: theme.typography.body2.fontSize,
        },
        sizeLarge: {
          fontSize: theme.typography.body1.fontSize,
        },
        root: {
          textTransform: 'none',
        },
        containedPrimary: {
          background: theme.palette.primary.main,
          color: theme.palette.grey[1000],
          textTransform: 'none',
          padding: '12px 16px',
        },
        containedSecondary: {
          background: theme.palette.secondary.main,
          textTransform: 'none',
          padding: '12px 16px',
          ':hover': {
            background: theme.palette.secondary.dark,
          },
          ':disabled': {
            cursor: 'not-allowed',
          },
          ':focus': {},
        },
      },
      variants: [
        {
          props: { variant: 'plain' },
          style: {
            fontSize: theme.typography.body2.fontSize,
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            ['&:hover']: {
              background: theme.palette.secondary.main,
              filter: 'brightness(1.2)',
            },
            [':disabled']: {
              background: theme.palette.secondary.main,
            },
            ':focus': {
              background: theme.palette.secondary.main,

              filter: 'brightness(1.2)',
              outline: `1px solid ${theme.palette.secondary.main}`,
            },
          },
        },
      ],
    },
  };
}
