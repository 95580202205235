import { Box, MenuItem, SelectChangeEvent } from '@mui/material';
import {
  CN,
  DE,
  ES,
  IN,
  PL,
  PT,
  RU,
  TR,
  UA,
  US,
  VN,
} from 'country-flag-icons/react/1x1';
import { useRouter } from 'next/router';
import React from 'react';
import { CustomSelect } from '../../ui/WrappedSelector/styled';
import { cookie } from '@/utils/cookies';
// import { CustomSelect } from './styles';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const languages = require('../../../configs/languages');
export const countryFlags = {
  ZH: CN,
  US,
  BR: PT,
  RU,
  UK: UA,
  DE,
  ES,
  TR,
  VN,
  PL,
  IN,
};

export const getLanguageFlag = (language: { countryFlag: string }) => {
  if (!language) {
    return null;
  }

  const Flag = countryFlags[language.countryFlag];
  if (!Flag) {
    return null;
  }

  return <Flag />;
};

export interface LanguageSwitcherProps {
  open?: boolean;
  usedInTheMenuNavigation?: boolean;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = (
  props: LanguageSwitcherProps,
) => {
  const { open = true, usedInTheMenuNavigation } = props;
  const router = useRouter();
  const hideArrow = usedInTheMenuNavigation && !open;

  const handleLanguageChange = async (event: SelectChangeEvent) => {
    const newLocale = event.target.value as string;
    const { pathname, asPath, query } = router;
    cookie.set('NEXT_LOCALE', newLocale);
    await router.push({ pathname, query }, router.asPath, {
      locale: newLocale,
    });
  };

  return (
    <CustomSelect
      value={router.locale}
      onChange={handleLanguageChange}
      hidearrow={`${hideArrow || ''}`}
      sx={{
        fontSize: '14px',
        backgroundColor: 'transparent',
        padding: 0,
        border: 'none',
        '& .MuiSelect-select': {
          padding: open ? '0px' : '0 0 0 6px',
        },
        '&.MuiInputBase-root': {
          width: '100%',
          height: '100%',
          textAlign: 'center',
        },
      }}
    >
      {Object.keys(languages).map((key, index) => {
        return (
          <MenuItem
            value={key}
            key={key}
            sx={(theme) => ({
              fontSize: '14px',
              position: 'relative',
              '&.Mui-selected': {
                backgroundColor: theme.palette.action.selected,
                '&:hover': {
                  backgroundColor: theme.palette.action.selected,
                },
              },
            })}
          >
            <Box
              sx={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& svg': {
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                },
              }}
            >
              {getLanguageFlag(languages[key])}
            </Box>

            {!open ? (
              ''
            ) : (
              <Box
                sx={{
                  ml: hideArrow ? 0 : '12px',
                }}
              >
                {languages[key].name}
              </Box>
            )}
          </MenuItem>
        );
      })}
    </CustomSelect>
  );
};

export default LanguageSwitcher;
