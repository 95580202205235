import { isLocalDev } from '@/utils/checkRuntime';
import { useEffect } from 'react';

const useVConsole = (isMobile) => {
  useEffect(() => {
    if (isLocalDev && isMobile) {
      import('vconsole').then((VConsole) => {
        const console = new VConsole.default({ theme: 'dark' });
      });
    }
  }, []);
};

export default useVConsole;
