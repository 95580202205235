// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonCryptoTokenSand: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-crypto-token-sand"><g clipPath="url(#clip0_476_98171)"><path d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z" fill="url(#paint0_linear_476_98171)" /><path d="M31.872 22.656L26.688 27.84V48.384L31.872 53.568H52.416V63.936H42.24V58.752H26.688V69.12L31.872 74.304H62.784L67.968 69.12V48.384L62.784 43.2H42.24V32.832H52.608V38.016H68.16V27.648L62.976 22.464H31.872V22.656Z" fill="white" /></g><defs><linearGradient id="paint0_linear_476_98171" x1="20.5824" y1="17.0592" x2="80.3904" y2="84.5472" gradientUnits="userSpaceOnUse"><stop stopColor="#00ADEF" /><stop offset="1" stopColor="#0084FF" /></linearGradient><clipPath id="clip0_476_98171"><rect width="96" height="96" fill="white" /></clipPath></defs></SvgBase>);
};
const MemoMoonCryptoTokenSand = React.memo(MoonCryptoTokenSand);
MemoMoonCryptoTokenSand.displayName = 'MoonCryptoTokenSand';
export default MemoMoonCryptoTokenSand;