// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyDtm: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-dtm"><g id="Property 1=sport-dtm"><path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M13.021 13.0078H18.8673L18.451 14.6068H16.741L15.475 19.8483H13.1861L14.4647 14.5681H12.6122L13.021 13.0078ZM20.1977 13.0389H22.7795L23.2107 16.9895L25.6127 13H28L27.8521 19.8172H25.45L25.6867 15.952L23.4716 19.8328H21.4491L21.04 15.983L19.5151 19.8249H17.2091L20.1977 13.0389ZM5.62733 13.0381H9.51859C9.51859 13.0381 12.2676 13.1575 12.0123 15.6013C11.7572 18.0451 10.3701 19.1121 9.02326 19.4994C7.67632 19.8869 4 19.8071 4 19.8071L5.62733 13.0381ZM7.49715 14.572L6.63288 18.1779C6.63288 18.1779 8.84628 18.6051 9.40477 16.562C9.96339 14.5189 8.83731 14.5667 8.83731 14.5667L7.49715 14.572Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyDtm = React.memo(BetbyDtm);
MemoBetbyDtm.displayName = 'BetbyDtm';
export default MemoBetbyDtm;