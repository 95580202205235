import 'simplebar-react/dist/simplebar.min.css';
import { styled } from '@mui/material/styles';
import SimpleBar from 'simplebar-react';

export const ScrollbarAdvanced = styled(SimpleBar, {
  shouldForwardProp: (prop) => prop !== 'offsetX' && prop !== 'offsetY',
})<{ offsetX?: number; offsetY?: number }>(({ theme, offsetX, offsetY }) => ({
  [`& .simplebar-scrollbar`]: {
    width: 4,
    height: 4,
    transition: 'height 0.25s',
    marginTop: 4,
    borderRadius: 6,
    backgroundColor: theme.palette.secondary.main,
    '&:before': {
      display: 'none',
    },
  },
  [`& .simplebar-vertical`]: {
    marginRight: offsetY || 0,
  },
}));
