// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyAlpineskiing: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-alpineskiing"><g id="Property 1=sport-alpineskiing"><path id="Vector" d="M11.4683 8.54318L8.21349 6.66402C8.00804 6.54541 7.93765 6.28271 8.05627 6.07726C8.17488 5.87182 8.43758 5.80143 8.64303 5.92004L11.1741 7.38136C11.1111 6.76337 11.5035 6.17335 12.1229 6.0074C12.1286 6.00536 12.1342 6.0034 12.1398 6.00148L14.8434 5.07056C15.2491 4.93083 15.6769 5.00544 16.0034 5.23425C16.0832 5.26547 16.1615 5.30295 16.2374 5.34678L20.9675 8.0777C21.3012 8.27039 21.5575 8.56775 21.7001 8.91883L23.9153 12.7556L27.3555 14.7418C27.9718 15.0976 28.183 15.8857 27.8271 16.502C27.4713 17.1184 26.6832 17.3295 26.0669 16.9737L23.1172 15.2707C23.1059 15.2655 23.0948 15.2596 23.0837 15.2533L14.626 10.3702L15.618 14.0726C15.6331 14.1289 15.6442 14.1853 15.6516 14.2416C15.8005 14.5995 15.7869 15.0203 15.5781 15.3819L13.4304 19.1018C13.0746 19.7182 12.2865 19.9294 11.6702 19.5735C11.0538 19.2177 10.8426 18.4296 11.1985 17.8132L13.0793 14.5556L11.4683 8.54318ZM14.3115 9.19668L21.1712 13.1571L20.3105 11.6663L14.0691 8.06287L14.0129 8.08223L14.3115 9.19668ZM4.42968 18.3692C4.01879 18.1319 3.87801 17.6065 4.11524 17.1956C4.35247 16.7847 4.87787 16.644 5.28876 16.8812C10.9306 20.1385 10.9306 20.1385 19.7962 25.2571C20.0469 25.4017 20.8218 25.1994 20.9699 24.9428C21.2072 24.5319 21.7326 24.3911 22.1435 24.6283C22.5544 24.8655 22.6951 25.3909 22.4579 25.8018C21.8329 26.8844 20.0057 27.3616 18.9373 26.7452L4.42968 18.3692ZM23.5092 9.68284C22.6874 9.20838 22.4058 8.15757 22.8803 7.33579C23.3547 6.51401 24.4056 6.23245 25.2273 6.7069C26.0491 7.18136 26.3307 8.23217 25.8562 9.05395C25.3818 9.87573 24.331 10.1573 23.5092 9.68284Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyAlpineskiing = React.memo(BetbyAlpineskiing);
MemoBetbyAlpineskiing.displayName = 'BetbyAlpineskiing';
export default MemoBetbyAlpineskiing;