import { requestWithZod } from '@/apis/index';
import request from './index';
import { TRequestEmailCodeMode } from '@/types/emails';

type UserLoginData = {
  email: string;
  pwd: string;
  twoFaCode?: string;
};

export enum TUserStatus {
  unverified = 1,
  verified = 2,
  twoFaVerified = 6,
}

export const userLoginAPI = (data: UserLoginData) => {
  return request<{
    email: string;
    name: string;
    status: TUserStatus;
    token?: string;
  }>({
    url: '/api/mc-api/user/login',
    data,
    method: 'post',
    suppressWarning: false,
  });
};

export const getThirdAuthUrl = (data: { platform: EThirdPartyLoginType }) => {
  return request<{
    url: string;
  }>({
    url: '/api/mc-api/user/third-party-url',
    data,
    method: 'post',
    suppressWarning: false,
  });
};

 

export const sendEmailVerificationCode = (
  data: {
    email: string;
    mode: TRequestEmailCodeMode;
  } & Record<string, string | number>,
) => {
  return request({
    url: '/api/mc-api/user/verification/code',
    data,
    method: 'post',
  });
};

export const resetPasswordWithCode = (data: {
  email: string;
  code: string;
  newPwd: string;
}) => {
  return request<{
    message: string;
  }>({
    url: '/api/mc-api/user/forgot',
    data,
    method: 'post',
  });
};

export const getUserZenDeskToken = () => {
  return request<{
    token: string;
  }>({
    url: '/api/mc-api/zendesk/token',
    method: 'post',
  });
};

export enum EThirdPartyLoginType {
  TELEGRAM = 'TELEGRAM',
  GOOGLE = 'GOOGLE',
  TWITTER = 'TWITTER',
}

export const thirdPartyLogin = (data: {
  platform: EThirdPartyLoginType;
  needAuthData: string;
  appName?: string;

  //Optional parameters, required when binding
  email?: string;
  code?: string;
  inviteCode?: string;
}) => {
  return request<{
    activeStatus: number;
    email: string;
    name: string;
    status: TUserStatus;
    thirdInfoMap: { [key in EThirdPartyLoginType]: string };
    verified2FA: boolean;
    token?: string;
  }>({
    url: '/api/mc-api/user/third-party-login',
    method: 'post',
    data,
  });
};

export const thirdPartyBindEmail = (data: {
  platform: EThirdPartyLoginType;
  needAuthData: string;
  email: string;
  code: string;
  appName?: string;
  inviteCode?: string;
  ip: string;
}) => {
  return request<{
    activeStatus: number;
    email: string;
    name: string;
    status: number;
    verified2FA: boolean;
    token?: string;
  }>({
    url: '/api/mc-api/user/bind-email',
    method: 'post',
    data,
  });
};
