import { useRouter } from 'next/router';
import busEvents from '@/helper/busEvents';

type AtLeastOne =
  | { label: string; basePath?: never }
  | { basePath: string; label?: never }
  | { label: string; basePath: string };

export const useChangeQueryString = (params: AtLeastOne) => {
  const { replace, locale } = useRouter();

  const { label, basePath } = params;

  const handleURL = (value?: string) => {
    if (!label && !basePath) return;
    if (!window) return;
    /**
     * WARNING: only handle en locale
     * since other languages will reload the page
     */
    // if (locale !== 'en') return;
    const url = new URL(window.location.href);

    if (label) {
      const urlParams = url.searchParams;
      if (value) {
        urlParams.set(label, value);
      } else {
        urlParams.delete(label);
      }
    } else {
      url.pathname = value ? `${basePath}/${value}` : `${basePath}`;
    }
    busEvents.emit('noProgressBar');
    replace(url.toString(), url.toString(), { shallow: true, locale });
    // window.history.replaceState({}, '', url.toString());
  };
  return {
    handleURL,
  };
};
