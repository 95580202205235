import { Chip, Stack } from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';

export const PreviousRoundResultBox = styled(Stack)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  margin: '10px 0',
}));

export const sliderIn = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const slideFromTop = keyframes`
  0% {
    transform: translateY(-300%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const BoxChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  height: '18px',
  animation: `${sliderIn} 0.5s ease-in-out`,
  '& .MuiChip-label': {
    padding: theme.spacing(0.5),
  },
}));
