// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonCryptoTokenBtc: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-crypto-token-btc"><path d="M94.5712 59.5985C88.1865 85.3493 62.0799 100.956 36.399 94.5713C10.7181 88.1868 -4.96011 62.0813 1.42465 36.4015C7.80941 10.6507 33.845 -4.95577 59.5969 1.42872C85.3488 7.88414 100.956 33.9187 94.5712 59.5985Z" fill="#F7941D" /><path d="M68.7975 40.8648C69.7197 34.4803 64.8958 31.0753 58.2272 28.7343L60.3555 20.0798L55.1058 18.7319L52.9775 27.1736C51.5587 26.819 50.1398 26.5352 48.7919 26.1805L50.9202 17.6678L45.6705 16.32L43.5422 24.9745C42.4072 24.6908 41.2721 24.478 40.208 24.1942L32.9719 22.3498L31.5531 27.954C31.5531 27.954 35.4549 28.8762 35.3839 28.8762C37.5122 29.4437 37.9378 30.7915 37.8669 31.9265L35.3839 41.787C35.5258 41.858 35.7387 41.858 35.9515 41.9289C35.8096 41.858 35.5968 41.858 35.3839 41.787L31.9078 55.6201C31.624 56.2585 30.9856 57.2517 29.4958 56.897C29.5668 56.9679 25.6649 55.9748 25.6649 55.9748L23.04 62.0046L29.9214 63.7071C31.1984 64.0618 32.4753 64.3455 33.6813 64.7002L31.4821 73.4257L36.7318 74.7735L38.8601 66.119C40.2789 66.5447 41.6978 66.8993 43.0457 67.1831L40.9174 75.7667L46.1671 77.1145L48.3663 68.389C57.3759 70.0916 64.1154 69.3822 66.953 61.2952C69.2232 54.7688 66.8112 51.0091 62.129 48.5262C65.5342 47.8168 68.0881 45.5468 68.7975 40.8648ZM56.7374 57.7482C55.1057 64.2746 44.1098 60.7277 40.4918 59.8764L43.4004 48.3134C47.0184 49.1647 58.44 50.9381 56.7374 57.7482ZM58.3691 40.7939C56.8793 46.7527 47.7278 43.7024 44.7483 42.993L47.3731 32.4941C50.3527 33.2034 59.9298 34.5513 58.3691 40.7939Z" fill="#FFFDFA" /></SvgBase>);
};
const MemoMoonCryptoTokenBtc = React.memo(MoonCryptoTokenBtc);
MemoMoonCryptoTokenBtc.displayName = 'MoonCryptoTokenBtc';
export default MemoMoonCryptoTokenBtc;