import { zendeskZIndex } from '@/constants/zIndex';

export const setZendeskSettings = () => {
  if (typeof window === 'undefined' || typeof window?.zE === 'undefined') {
    return;
  }

  try {
    window?.zE('messenger:set', 'zIndex', zendeskZIndex);
  } catch (e) {
    console.error(e);
  }
};

export default setZendeskSettings;
