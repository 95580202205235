// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const CasinoSwapHorizontal: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-casino-swap-horizontal"><g id="Swap horizontal square"><path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M15.2581 14.4216H10.1509L11.0044 15.6014C11.2386 15.923 11.1656 16.3733 10.8431 16.6065C10.7164 16.6997 10.5685 16.7438 10.4226 16.7438C10.1989 16.7438 9.97908 16.6411 9.83796 16.4462L8.157 14.124C7.9986 13.9051 7.97556 13.6152 8.09844 13.3742C8.22132 13.1333 8.469 12.9816 8.73972 12.9816H15.2581C15.6556 12.9816 15.9781 13.3041 15.9781 13.7016C15.9781 14.099 15.6556 14.4216 15.2581 14.4216ZM8.73972 8.97359H13.8479L12.9944 7.79471C12.7602 7.47311 12.8332 7.02191 13.1557 6.78959C13.4773 6.55535 13.9276 6.62831 14.1599 6.94991L15.8408 9.27119C15.9992 9.49103 16.0223 9.77999 15.8994 10.0209C15.7765 10.2619 15.5288 10.4136 15.2581 10.4136H8.73972C8.34228 10.4136 8.01972 10.091 8.01972 9.69359C8.01972 9.29615 8.34228 8.97359 8.73972 8.97359ZM16.0482 2.57999H7.95156C4.91796 2.57999 2.87988 4.71215 2.87988 7.88495V15.5141C2.87988 18.6878 4.91796 20.82 7.95156 20.82H16.0472C19.0808 20.82 21.1199 18.6878 21.1199 15.5141V7.88495C21.1199 4.71215 19.0818 2.57999 16.0482 2.57999Z" fill="#848E9C" /></g></SvgBase>);
};
const MemoCasinoSwapHorizontal = React.memo(CasinoSwapHorizontal);
MemoCasinoSwapHorizontal.displayName = 'CasinoSwapHorizontal';
export default MemoCasinoSwapHorizontal;