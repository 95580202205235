// components/AmplitudeHeader.js
import Script from 'next/script';

const AmplitudeHeader = () => {
  const amplitudeScript = `
    window.amplitude.add(window.sessionReplay.plugin({ sampleRate: 1 })).promise.then(function() {
      window.amplitude.add(window.amplitudeAutocapturePlugin.plugin());
      window.amplitude.init('77efde8a4e55c1d72cc82815da670270');
    });
  `;

  return (
    <>
      <Script
        src="https://cdn.amplitude.com/libs/analytics-browser-2.7.4-min.js.gz"
        strategy="beforeInteractive"  
      />
      <Script
        src="https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.6.8-min.js.gz"
        strategy="beforeInteractive"
      />
      <Script
        src="https://cdn.amplitude.com/libs/plugin-autocapture-browser-0.9.0-min.js.gz"
        strategy="beforeInteractive"
      /> 
      <Script
        id="amplitude-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{ __html: amplitudeScript }}
      />
    </>
  );
};

export default AmplitudeHeader;
