// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyNordiccombined: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-nordiccombined"><g id="Property 1=sport-nordiccombined"><path id="Vector" d="M13.2308 16.0629V24.7692C13.2308 24.9311 13.203 25.0864 13.152 25.2308H16.0788C16.0278 25.0864 16 24.9311 16 24.7692V18.0019L13.2308 16.0629ZM14.5524 13.6077L18.2002 16.1619C18.6563 16.4813 18.8611 17.026 18.7692 17.5404V24.7692C18.7692 24.9311 18.7415 25.0864 18.6904 25.2308H19.6049L23.8007 16H21.0769C20.3122 16 19.6923 15.3801 19.6923 14.6154V11.2109L14.5524 13.6077ZM10.5403 25.2308C10.4893 25.0864 10.4615 24.9311 10.4615 24.7692V13.6923C10.4615 13.6421 10.4642 13.5925 10.4694 13.5436C10.3837 12.9544 10.6875 12.3544 11.2549 12.0898L18.7058 8.61538H11.4912L6.27037 20.7972C6.16996 21.0315 5.89864 21.14 5.66434 21.0396C5.43005 20.9392 5.32152 20.6679 5.42193 20.4336L10.5133 8.55374C9.94865 8.37904 9.53846 7.85281 9.53846 7.23077C9.53846 6.46607 10.1584 5.84615 10.9231 5.84615H21.0769C21.8416 5.84615 22.4615 6.46607 22.4615 7.23077V13.2308H26.6154C27.3801 13.2308 28 13.8507 28 14.6154C28 15.3801 27.3801 16 26.6154 16H24.7693C24.7694 16.064 24.7561 16.129 24.7279 16.191L20.6189 25.2308H24.3077C24.5227 25.2308 25.2308 24.5219 25.2308 24.3076C25.2308 23.7978 25.6441 23.3846 26.1539 23.3846C26.6637 23.3846 27.0769 23.7979 27.0769 24.3077C27.0769 25.5411 25.5427 27.0769 24.3077 27.0769H4.92308C4.41328 27.0769 4 26.6636 4 26.1538C4 25.644 4.41328 25.2308 4.92308 25.2308H10.5403ZM23.3846 5.84615C23.3846 4.82655 24.211 4 25.2303 4C26.2496 4 27.076 4.82655 27.076 5.84615C27.076 6.86576 26.2496 7.69231 25.2303 7.69231C24.211 7.69231 23.3846 6.86576 23.3846 5.84615Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyNordiccombined = React.memo(BetbyNordiccombined);
MemoBetbyNordiccombined.displayName = 'BetbyNordiccombined';
export default MemoBetbyNordiccombined;