export const APP_LOCAL_KEY_PREFIX = '@CROWNBIT_LOCAL_KEY';
export const LOCAL_KEY_APP_BAR_OPEN_STATUS = `${APP_LOCAL_KEY_PREFIX}::APP_BAR_OPEN_STATUS`;
export const LOCAL_KEY_USER_TOKEN = `${APP_LOCAL_KEY_PREFIX}::USER_AUTH_TOKEN`;
export const LOCAL_KEY_USER_INFO = `${APP_LOCAL_KEY_PREFIX}::USER_INFO`;

export const LOCAL_KEY_GAME_SEARCH_HISTORY = `${APP_LOCAL_KEY_PREFIX}::GAME_SEARCH_HISTORY`;

export const LOCAL_KEY_USER_UUID = `${APP_LOCAL_KEY_PREFIX}::USER_UUID`;

export const LOCAL_KEY_SELECTED_WALLET_ID = `${APP_LOCAL_KEY_PREFIX}::SELECTED_WALLET_ID`;

export const LOCAL_KEY_CASINO_CINEMA_MODE = `${APP_LOCAL_KEY_PREFIX}::CASINO_CINEMA_MODE`;
