import { Theme } from '@mui/material/styles';

export default function ButtonGroup(theme: Theme) {
  return {
    MuiToggleButtonGroup: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  };
}
