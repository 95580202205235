// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyFieldhockey: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-fieldhockey"><g id="Property 1=sport-fieldhockey"><path id="Vector" d="M7.27651 10.185C5.56856 10.185 4.184 8.80047 4.184 7.09252C4.184 5.38457 5.56856 4 7.27651 4C8.98446 4 10.369 5.38457 10.369 7.09252C10.369 8.80047 8.98446 10.185 7.27651 10.185ZM7.27651 9.30146C8.49648 9.30146 9.48545 8.31248 9.48545 7.09252C9.48545 5.87255 8.49648 4.88358 7.27651 4.88358C6.05655 4.88358 5.06757 5.87255 5.06757 7.09252C5.06757 8.31248 6.05655 9.30146 7.27651 9.30146ZM25.9741 10.4288C25.629 10.0837 25.0695 10.0837 24.7245 10.4288L12.2288 22.9244C11.2189 23.9343 9.55434 23.9918 8.47814 22.9156C7.40849 21.846 7.49033 20.1655 8.48013 19.1757C8.77077 18.8851 8.78808 18.2341 8.48117 17.9272C8.16208 17.6081 7.54125 17.6196 7.2326 17.9282C7.03275 18.1281 6.83318 18.3563 6.62132 18.6563C6.30988 19.0974 6.06587 19.5732 5.91968 20.0723C5.52889 21.4064 5.86058 22.7586 7.24062 24.1842C7.26516 24.2094 7.26516 24.2094 7.28997 24.2344C8.98219 25.9266 11.7258 25.9266 13.418 24.2344L25.9741 11.6784C26.3191 11.3333 26.3191 10.7739 25.9741 10.4288ZM27.2236 9.17923C28.2588 10.2144 28.2588 11.8928 27.2236 12.9279L14.6676 25.484C12.2853 27.8663 8.42273 27.8663 6.0404 25.484C6.00548 25.4488 6.00548 25.4488 5.97093 25.4133C4.148 23.5302 3.65459 21.5187 4.22378 19.5756C4.42925 18.8741 4.76088 18.2274 5.17781 17.637C5.45498 17.2445 5.71767 16.944 5.98303 16.6786C6.97171 15.69 8.71103 15.6579 9.73073 16.6777C10.7431 17.69 10.696 19.459 9.72969 20.4253C9.40431 20.7507 9.37681 21.3152 9.7277 21.6661C10.0937 22.0321 10.6409 22.0132 10.9793 21.6749L23.4749 9.17923C24.5101 8.14405 26.1884 8.14405 27.2236 9.17923Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyFieldhockey = React.memo(BetbyFieldhockey);
MemoBetbyFieldhockey.displayName = 'BetbyFieldhockey';
export default MemoBetbyFieldhockey;