// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const StickiesTrophy: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" height="40" width="40" sx={propSx}  {...restProps} iconName="i-stickies-trophy"><path fill="#00034a" stroke="#00034a" strokeMiterlimit="10" d="M25.335 31.352c0.16 -1.502 0.218 -3.012 0.174 -4.522v-0.312a11.678 11.678 0 0 0 5.158 -4.126c5.84 -0.049 8.759 -4.525 8.759 -11.221 0 -2.241 -3.435 -3.33 -5.111 -3.56 -0.839 -5.697 -11.016 -5.323 -14.838 -5.323 -9.274 0 -13.916 1.48 -13.916 4.632v0.584C2.193 7.825 0.5 8.653 0.5 10.034c0 5.772 3.15 12.27 9.663 12.357 0.083 0.092 0.167 0.183 0.253 0.272a11.757 11.757 0 0 0 4.002 3.414c-0.028 1.608 0.06 3.217 0.261 4.813 -2.676 0.973 -4.048 2.92 -4.048 5.362 0 3.132 8.267 2.597 9.818 2.597 5.936 0 8.856 -0.467 8.856 -1.46 0 -2.441 -1.846 -4.906 -3.97 -6.037Z" strokeWidth="1" /><path fill="#fdcb35" stroke="#00034a" strokeMiterlimit="10" d="M29.706 21.256c5.839 -0.05 8.758 -4.526 8.758 -11.221 0 -1.382 -1.693 -2.21 -5.08 -2.53l0 -0.585c0.01 -3.153 -4.632 -4.632 -13.907 -4.632 -9.274 0 -13.916 1.48 -13.916 4.632l0 0.584C2.193 7.825 0.5 8.653 0.5 10.034c0 6.696 2.92 11.173 8.759 11.222a11.756 11.756 0 0 0 5.167 4.136v0.301c-0.05 1.736 0.035 3.474 0.253 5.197 -2.676 0.973 -4.048 2.92 -4.048 5.362 0 0.974 2.92 1.46 8.856 1.46 5.937 0 8.856 -0.467 8.856 -1.46 0 -2.481 -1.372 -4.379 -4.048 -5.362a33.63 33.63 0 0 0 0.253 -5.197v-0.311a11.679 11.679 0 0 0 5.158 -4.127Z" strokeWidth="1" /><path stroke="#00034a" strokeLinecap="round" strokeMiterlimit="10" d="M6.826 11.144c0.155 2.153 0.58 4.278 1.265 6.326" strokeWidth="1" /><path stroke="#00034a" strokeLinecap="round" strokeMiterlimit="10" d="M32.129 11.144a25.743 25.743 0 0 1 -1.265 6.326" strokeWidth="1" /></SvgBase>);
};
const MemoStickiesTrophy = React.memo(StickiesTrophy);
MemoStickiesTrophy.displayName = 'StickiesTrophy';
export default MemoStickiesTrophy;