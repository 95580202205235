import { slideFromTop } from '@/components/features/casino/crash/GameBox/PreviourRoundResult/styles';
import WrappedButton from '@/components/ui/WrappedButton';
import WrappedModal from '@/components/ui/WrappedModal';
import WrappedTabs from '@/components/ui/WrappedTab';
import useReferralCode from '@/hooks/biz/useReferralCode';
import useUpdateUserInfoAsync from '@/hooks/biz/useUpdateUserInfoAsync';
import useUserLoginLogout from '@/hooks/biz/useUserLoginLogout';
import { GLOBAL_MODALS, globalModals } from '@/state/globalSettings';
import { isLoggedIn, updateUserInfoMeta } from '@/state/userInfo';
import useTheme from '@/theme/useTheme';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { memo, useState } from 'react';
// import registerBanner from './register_banner.png';
import registerBanner from './desktop_login_register_banner.png';
import loginBannerMobile from './mobile_login_banner.png';
import registerBannerMobile from './mobile_register_banner.png';
import Loading from '@/components/ui/Loading';
import { getStaticAsset } from '@/utils/staticAsset';
import useBindEmailOrLogin from '@/hooks/useBindEmailOrLogin';

const UserDropdown = dynamic(() => import('./userDropdown'), {
  ssr: false,
});

const LoginForm = dynamic(() => import('./LoginForm'), {
  ssr: false,
  loading: () => <Loading size={120} />,
});

const RegisterForm = dynamic(() => import('./RegisterForm'), {
  ssr: false,
  loading: () => <Loading size={120} />,
});

const ResetPasswordForm = dynamic(() => import('./ResetPassword'), {
  ssr: false,
  loading: () => <Loading size={120} />,
});

declare const window: Window &
  typeof globalThis & {
    Telegram: any;
  };

const UserWidget: React.FC = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { login } = useUserLoginLogout();

  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isLogin = useAtomValue(isLoggedIn);
  const { isTemporaryLogin } = useBindEmailOrLogin();
  const [{ isUserLoading, userMetaData }] = useUpdateUserInfoAsync();

  const [modals, setModals] = useAtom(globalModals);
  const handleClickOpen = (formType: GLOBAL_MODALS) => {
    setModals({
      [formType]: true,
    });
  };

  const handleClose = () => {
    setModals({
    });
  };

  const open =
    modals[GLOBAL_MODALS.REGISTER] ||
    modals[GLOBAL_MODALS.LOGIN] ||
    modals[GLOBAL_MODALS.RESET_PASSWORD];

  const refCode = useReferralCode();
  const router = useRouter();

  const handleLogin = () => {
    handleClickOpen(GLOBAL_MODALS.LOGIN);
  };

  const mobileBanner = modals[GLOBAL_MODALS.LOGIN]
    ? loginBannerMobile
    : registerBannerMobile;

  return (
    <Box>
      {isLogin || isTemporaryLogin ? (
        <Grid display={'flex'} gap={2} alignItems={'center'}>
          <UserDropdown />
        </Grid>
      ) : (
        <Grid
          gap={2}
          display={'flex'}
          sx={{
            animation: `${slideFromTop} 0.5s ease-in-out`,
          }}
        >
          <WrappedButton
            variant={'plain'}
            color={'primary'}
            onClick={handleLogin}
            sx={{
              backgroundColor: theme.palette.grey['900'],
              '&:hover': {
                background: theme.palette.grey['800'],
                boxShadow: theme.palette.boxShadow.elevation7,
                filter: 'none',
              },
            }}
          >
            {t('Login')}
          </WrappedButton>
          <WrappedButton
            variant={'contained'}
            onClick={() => handleClickOpen(GLOBAL_MODALS.REGISTER)}
            sx={{
              color: theme.palette.grey['900'],
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                background: theme.palette.primary.dark,
                boxShadow: theme.palette.boxShadow.elevation7,
              },
            }}
          >
            {t('Register')}
          </WrappedButton>
        </Grid>
      )}
      <WrappedModal
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            background: theme.palette.grey['900'],
            padding: 0,
          },
        }}
        sxs={{
          wrapperSX: {
            width: isMdUp ? 800 : '90vw',
            [theme.breakpoints.up('sm')]: {
              padding: theme.spacing(0),
              overflowY: 'visible',
            },
          },
          closeSX: {
            color: theme.palette.grey['1000'],
            [theme.breakpoints.down('sm')]: {
              top: 20,
              right: 20,
              position: 'absolute',
            },
          },
          headerSX: {
            height: 'auto',
            [theme.breakpoints.down('sm')]: {
              padding: 0,
            },
          },
          bodyWrapperSX: {
            paddingBottom: 0,
          },
        }}
        disableAnimation
        showTitle={isSmallDown}
        titleComp={
          isSmallDown ? (
            <Box
              sx={{
                width: '100vw',
                position: 'relative',
                aspectRatio: '375/150',
                margin: '-8px -40px -8px -16px',
                padding: theme.spacing(0, 2),
                [theme.breakpoints.down('sm')]: {
                  margin: 0,
                },
              }}
            >
              <Image
                src={mobileBanner}
                fill
                alt="register"
                placeholder="blur"
              ></Image>
            </Box>
          ) : null
        }
      >
        <Box
          display="flex"
          height="100%"
          flexDirection={isMdUp ? 'row' : 'column'}
          alignItems="stretch"
          gap={[theme.spacing(2), 0]}
        >
          <Box
            flex={1}
            sx={{
              padding: theme.spacing(5, 3),
              maxHeight: 'calc(100vh - 64px)',
              overflowY: 'auto',
              [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0),
                // marginTop: theme.spacing(7),
              },
            }}
          >
            {modals[GLOBAL_MODALS.LOGIN] || modals[GLOBAL_MODALS.REGISTER] ? (
              <WrappedTabs
                sxs={{
                  tabsWrapperSX: {
                    marginBottom: 1,
                  },
                }}
                currentTab={modals[GLOBAL_MODALS.LOGIN] ? 'login' : 'register'}
                onChange={(e, value) => {
                  setModals({
                    [value === 'login'
                      ? GLOBAL_MODALS.LOGIN
                      : GLOBAL_MODALS.REGISTER]: true,
                  });
                }}
              >
                {[
                  {
                    title: t('Login'),
                    name: 'login',
                    content: <LoginForm />,
                  },
                  {
                    title: t('Register'),
                    name: 'register',
                    content: (
                      <RegisterForm
                        onSuccess={(data) => {
                          setModals({
                          });
                          login(data);
                          if (refCode) {
                            router.push('/casino');
                          }
                        }}
                      />
                    ),
                  },
                ]}
              </WrappedTabs>
            ) : null}
            {modals[GLOBAL_MODALS.RESET_PASSWORD] && <ResetPasswordForm />}
          </Box>

          {isMdUp && (
            <Box
              flex={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              position={'relative'}
              sx={{
                marginTop: theme.spacing(0),
                marginLeft: 2,
                marginRight: theme.spacing(-8),
                marginBottom: modals[GLOBAL_MODALS.REGISTER] ? -2 : -4,
                zIndex: -1,
                alignItems: 'flex-start',
                minWidth: '50%',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  width: `calc(100% - ${theme.spacing(4)})`,
                  right: theme.spacing(4),
                  height: `calc(100% - ${theme.spacing(
                    modals[GLOBAL_MODALS.REGISTER] ? 2 : 4,
                  )})`,
                  top: 0,
                  background: '#b5651b',
                  zIndex: -1,
                  borderRadius: 2,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              ></Box>
              <Image
                src={registerBanner}
                width={430}
                height={500}
                alt="register"
                placeholder="blur"
              ></Image>
            </Box>
          )}
        </Box>
      </WrappedModal>
    </Box>
  );
};

export default memo(UserWidget);
