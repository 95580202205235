// useCurrencyRate.js
import { fetchCurrencyRateTable } from '@/apis/exchange';
import { useQuery } from '@tanstack/react-query';

const useCurrencyRate = () => {
  const { data, isSuccess: queryIsSuccess } = useQuery(
    ['currencyRate'],
    fetchCurrencyRateTable,
    {
      enabled: true,
      refetchInterval: 1000 * 60,
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60,
      initialData: {}
    },
  );

  return { currencyRateTable: data, isSuccess: queryIsSuccess };
};

export default useCurrencyRate;
