// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const ActionGlobal03ActionAtLine: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" sx={propSx}  {...restProps} iconName="i-action-global03-action-at-line"><title>{"global_03_action_at_line"}</title><g id="控件" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="icon/01_global/03_action/at/48/01_line"><rect id="bg" x="0" y="0" width="48" height="48" /><g transform="translate(1.000000, 1.000000)" fill="#000000" fillRule="nonzero" id="形状"><path d="M39.6270304,13.7973894 C36.299296,7.79910403 29.9840941,4 23,4 C12.5065898,4 4,12.5065898 4,23 C4,33.4934102 12.5065898,42 23,42 C28.2262263,42 33.0998697,39.8783641 36.6356122,36.232273 L36.9940772,35.8503471 L36.9940772,35.8503471 L37.0996915,35.7292583 L37.0996915,35.7292583 C37.5550664,35.2031684 38.3439385,35.1289197 38.889489,35.5607895 L39.9930058,36.4343577 C40.5184338,36.8502701 40.6363003,37.5902336 40.2873597,38.1447412 L40.1861955,38.2842477 L40.1861955,38.2842477 L40.134972,38.343201 C35.8164736,43.1661067 29.6398215,46 23,46 C10.2974508,46 0,35.7025492 0,23 C0,10.2974508 10.2974508,0 23,0 C31.4522941,0 39.098831,4.60000954 43.1248091,11.8568884 C44.9194794,15.0918054 45.9114768,18.718757 45.9943449,22.4855091 L46,23 C46,27.418278 42.418278,31 38,31 C35.819835,31 33.843359,30.1279035 32.4003925,28.713531 C30.4706243,31.883656 26.9824615,34 23,34 C16.9248678,34 12,29.0751322 12,23 C12,16.9248678 16.9248678,12 23,12 C28.9787016,12 33.8433652,16.7697636 33.9962926,22.7115925 L34,23 C34,25.209139 35.790861,27 38,27 C40.1421954,27 41.8910789,25.3160315 41.9951047,23.1996403 L42,23 C42,19.7326343 41.1759579,16.5893516 39.6270304,13.7973894 Z M23,16 C19.1340068,16 16,19.1340068 16,23 C16,26.8659932 19.1340068,30 23,30 C26.8659932,30 30,26.8659932 30,23 C30,19.1340068 26.8659932,16 23,16 Z" /></g></g></g></SvgBase>);
};
const MemoActionGlobal03ActionAtLine = React.memo(ActionGlobal03ActionAtLine);
MemoActionGlobal03ActionAtLine.displayName = 'ActionGlobal03ActionAtLine';
export default MemoActionGlobal03ActionAtLine;