import { Theme } from '@mui/material/styles';

export default function Tab(theme: Theme) {
  return {
    MuiTab: {
      // defaultProps: {
      //   disableFocusRipple: true,
      //   disableRipple: true,
      // },
      styleOverrides: {
        root: {
          height: 36,
          minHeight: 36,
          textTransform: 'none',
          color: theme.palette.primary.light,
          border: theme.palette.border.normal,
          borderRadius: theme.shape.borderRadius,
          ['&:hover']: {
            background: theme.palette.grey[800],
          },
          ['&.Mui-disabled']: {
            color: theme.palette.grey[400],
          },
          ['&.Mui-selected']: {
            background: theme.palette.primary.light,
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.grey[900],
          },
        },
      },
    },
  };
}
