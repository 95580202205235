import { grey } from '@mui/material/colors';

export const paletteColors = {
  textShadow: {
    text: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  boxShadow: {
    elevation8:
      '0px 5px 5px -3px rgba(0, 0, 0, 0.20), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);',
    elevation7:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);',
    glow: '0px 0px 18.1px 0px rgba(255, 227, 129, 0.20) inset',
  },
  primary: {
    main: '#FDCB35',
    dark: '#F0DA66',
    light: '#FDCB35',
    contrastText: '#FFFFFF',
  },
  text: {
    white: '#FFFFFF',
    primary: '#F3F3FC',
    secondary: 'rgba(255, 255, 255, 0.70)',
    disabled: 'rgba(255,255,255,0.38)',
    desc: '#EEEEFB',
    help: 'rgba(255, 255, 255, 0.45)',
  },
  secondary: {
    main: '#2C2C35',
    dark: '#70707C',
    light: '#A9A9B7',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#F44336',
    dark: '#D32F2F',
    light: '#E57373',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FB8C00',
    dark: '#EF6C00',
    light: '#FFB74D',
    contrastText: '#000000',
  },
  info: {
    main: '#29B6F6',
    dark: '#0288D1',
    light: '#4FC3F7',
    contrastText: '000000',
  },
  success: {
    main: '#66BB6A',
    dark: '#388E3C',
    light: '#81C784',
    contrastText: '#000000',
  },
  action: {
    active: 'rgba(255,255,255,0.56)',
    selected: 'rgba(255, 255, 255, 0.16)',
    hover: 'rgba(255,255,255,0.08)',
    disabledBackground: 'rgba(255,255,255,0.12)',
    focus: 'rgba(255,255,255,0.12)',
    disabled: 'rgba(255,255,255,0.38)',
  },
  grey: {
    '50': '#FFFFFF',
    '100': '#F5F5FF',
    '200': '#D0D0DA',
    '300': '#A9A9B7',
    '400': '#8A8A98',
    '500': '#70707C',
    '600': '#5B5B65',
    '700': '#40404A',
    '800': '#2C2C35',
    '900': '#1E1E24',
    '950': '#16161B',
    '980': '#121217',
    A100: grey.A100,
    A200: grey.A200,
    A400: grey.A400,
    A700: grey.A700,
    '1000': '#0F0F13',
    '1100': '#000000',
    background: '#121212',
  },
  green: {
    '500': '#4CAF50',
  },
  bg: {
    main: '#181A20',
    secondary: '#1E2329',
    hover: '#2B3139',
    form: '#26262d',
    surface: '#26262d',
  },
  border: {
    normal: '1px solid rgba(255,255,255,0.15)',
    primary: '1px solid #EEA433',
  },
};

export const palette = (mode: 'light' | 'dark'): CustomThemeOptions => ({
  palette: paletteColors,
});

export default palette;
