import useTheme from '@/theme/useTheme';
import requestIdleCallback from '@/utils/requestIdleCallback';
import { getStaticAsset } from '@/utils/staticAsset';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, ModalProps, Stack, SxProps, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';

const ModalWrapper = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  width: 500,
  background: theme.palette.bg.secondary,
  border: theme.palette.border.normal,
  borderRadius: theme.spacing(1),
  maxWidth: 'calc(100vw - 32px)',
  outline: 'none',
  ['&:focus-visible']: {},

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    maxWidth: '100%',
    overflowY: 'auto',
    borderRadius: 0,
    minHeight: '60%',
    maxHeight: '100%',
    bottom: '0px',
    transform: 'none',
    top: 'auto',
    left: '0',
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 3),
    maxHeight: 'calc(100vh - 64px)',
  },
}));

const CloseButtonSX: SxProps<Theme> = {
  cursor: 'pointer',
  color: 'rgba(255, 255, 255, 0.56)',
  zIndex: (theme) => theme.zIndex.modal + 1,
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  '&:hover': {
    color: (theme) => theme.palette.grey[50],
  },
};

const ImgWrapper = styled(Box)(({ theme }) => ({
  ['& img']: {
    width: '50%',
    aspectRatio: '181/50',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const ModalHeaderWrapper = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing(0),
  width: '100%',
  padding: theme.spacing(4, 0, 3),
  background: theme.palette.bg.secondary,
  height: '40px',
  zIndex: theme.zIndex.modal + 1,
  fontSize: theme.typography.pxToRem(24),
  fontWeight: 700,
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 2, 3),
  },
  [theme.breakpoints.up('sm')]: {
    boxShadow: 'none',
  },
}));

const ModalBodyWrapper = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  transition: 'max-height 0.3s ease',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 2, 5),
  },
}));

export type WrappedModalProps = Omit<ModalProps, 'title'> & {
  children: React.ReactNode;
  showCloseIcon?: boolean;
  showTitle?: boolean;
  title?: React.ReactNode;
  titleComp?: React.ReactNode;
  sxs?: {
    wrapperSX?: SxProps<Theme>;
    closeSX?: SxProps<Theme>;
    bodyWrapperSX?: SxProps<Theme>;
    headerSX?: SxProps<Theme>;
  };
  disableAnimation?: boolean;
};

const WrappedModal: React.FC<WrappedModalProps> = ({
  children,
  title,
  titleComp,
  showTitle = true,
  showCloseIcon = true,
  sxs: { wrapperSX = {}, closeSX = {}, bodyWrapperSX, headerSX = {} } = {},
  open,
  disableAnimation = false,
  disableScrollLock = false,
  ...props
}) => {
  const { theme } = useTheme();
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(undefined);
  const [allowScroll, setAllowScroll] = useState(true);
  const setHeightMethod = (height: number) => {
    setAllowScroll(false);
    setTimeout(() => {
      setHeight(height);
      setTimeout(() => {
        setAllowScroll(true);
      }, 300);
    }, 0);
  };

  useEffect(() => {
    let observer: MutationObserver;
    requestIdleCallback(() => {
      if (!contentRef.current) return;
      if (!open) return;
      const input = contentRef.current.querySelector('input');
      if (input) {
        const ariaHidden = input.getAttribute('aria-hidden');
        if (ariaHidden !== 'true') {
          input.focus();
        }
      }
      if (disableAnimation) return;
      const initialHeight = contentRef.current.scrollHeight;
      setHeight(initialHeight);
      observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'style'
          ) {
            setHeightMethod(contentRef.current.scrollHeight);
          }

          if (mutation.type === 'childList') {
            setHeightMethod(contentRef.current.scrollHeight);
          }
        }
      });
      observer.observe(contentRef.current, {
        childList: true,
        subtree: true,
        attributes: true,
      });
    });
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [open]);

  return (
    <Modal open={open} {...props}>
      <ModalWrapper
        sx={wrapperSX}
        style={{
          overflowY: disableAnimation
            ? undefined
            : allowScroll
              ? 'auto'
              : 'hidden',
        }}
      >
        {showTitle ? (
          <ModalHeaderWrapper sx={headerSX}>
            {title || titleComp || (
              <ImgWrapper>
                <Image
                  width={100}
                  height={50}
                  alt="logo"
                  src={getStaticAsset('/assets/images/logo/moonclub.svg')}
                />
              </ImgWrapper>
            )}
            {showCloseIcon ? (
              <CloseIcon
                sx={{
                  ...CloseButtonSX,
                  [theme.breakpoints.up('sm')]: {
                    top: '6px',
                    right: '2px',
                  },
                  [theme.breakpoints.down('sm')]: {
                    top: '12px',
                    right: '24px',
                  },
                  ...closeSX,
                }}
                onClick={(e) => props.onClose?.(e, 'backdropClick')}
              />
            ) : null}
          </ModalHeaderWrapper>
        ) : null}
        <div
          style={{
            height: height,
            transition: 'height 0.3s ease',
          }}
        >
          <ModalBodyWrapper ref={contentRef} sx={bodyWrapperSX}>
            {children}
          </ModalBodyWrapper>
        </div>
      </ModalWrapper>
    </Modal>
  );
};

export default WrappedModal;
