import React, { PropsWithChildren, useEffect } from 'react';
import { merge } from 'lodash-es';
// import { useUIKitUpdater } from '@cmc/common/contexts/UIKitContext';
// import {} from '@crownbit/hooks';
import { IProps } from './types';

const Component: React.ForwardRefRenderFunction<
  any,
  PropsWithChildren<IProps>
> = (props, ref) => {
  const {
    children,
    iconType = 'Filled',
    iconName,
    viewBox: propViewBox,
    forceShow = false,
    fontSize = 20,
    height,
    width,
    sx,
    ...rest
  } = props;
  const viewBox = propViewBox || '0 0 24 24';
  const svgDefaultProps = {
    fontSize,
    viewBox,
    version: '1.1',
    xmlns: 'http://www.w3.org/2000/svg',
    height: '1em',
    width: '1em',
    className: 'c-i',
  };

  const mergedProps = merge({}, svgDefaultProps, rest);
  // const uikitUpdater = useUIKitUpdater();
  const iconId = `#${iconName}`;

  // useEffect(() => {
  //   if (iconName && uikitUpdater.addIcon) {
  //     uikitUpdater.addIcon(
  //       iconName,
  //       <symbol id={iconName} key={iconName}>
  //         {children as unknown as any}
  //       </symbol>,
  //     );
  //   }
  // }, []);

  let _children: any = children;
  // if (!iconName || !uikitUpdater || !uikitUpdater.addIcon || forceShow) {
  // _children = children;
  // }

  return (
    <svg
      style={{
        userSelect: 'none',
        display: 'inline-block',
        flexShrink: 0,
        lineHeight: '1em',
        verticalAlign: 'middle',
        ...sx,
      }}
      {...mergedProps}
    >
      {_children}
    </svg>
  );
};
export type { IProps } from './types';
export default React.forwardRef(Component);
