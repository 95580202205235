// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyMma: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-mma"><g id="Property 1=sport-mma"><path id="Vector" d="M4.07974 22.6592L4.18312 10.3979L7.0463 10.421L7.82743 17.5549H7.84179L8.74353 10.4355L11.6038 10.4616L11.5005 22.7229L9.65389 22.7084L9.72856 13.9503L8.56835 22.7026L7.01471 22.6882L6.00097 13.9156L5.92343 22.6766L4.07974 22.6592ZM12.497 22.7345L12.6032 10.4702L15.4607 10.4963L16.2447 17.6302H16.259L17.1636 10.5108L20.0239 10.5368L19.9177 22.8011L18.074 22.7837L18.1487 14.0256L16.9827 22.775L15.4291 22.7605L14.4153 13.9909L14.3407 22.749L12.497 22.7345ZM20.179 22.8011L22.4736 10.5571L24.9634 10.5803L27.0512 22.8648L24.8916 22.8445L24.6906 21.4345L22.5684 21.4171L22.3415 22.8184L20.179 22.8011ZM24.2656 18.4408L23.697 14.2775H23.6797L23.0422 18.4264L24.2656 18.4408ZM4 4H27.1429V5.71429H4V4ZM4 26.2857H27.1429V28H4V26.2857Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyMma = React.memo(BetbyMma);
MemoBetbyMma.displayName = 'BetbyMma';
export default MemoBetbyMma;