// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyOverwatch: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-overwatch"><g id="Property 1=sport-overwatch"><path id="Vector" d="M15.8682 4.00129C18.6307 3.9599 21.3696 4.91321 23.5152 6.64437L21.2487 9.27556C20.194 8.45574 18.9439 7.87894 17.6302 7.62623C15.2397 7.15104 12.6623 7.76614 10.7494 9.27556L8.48297 6.64437C10.5573 4.97034 13.1975 4.01841 15.8682 4.00129ZM7.74512 7.29093C7.78302 7.25479 7.82093 7.21865 7.85884 7.18251L10.1253 9.81369C9.46868 10.4438 8.90268 11.1772 8.47504 11.9808C7.1071 14.4892 7.1411 17.6963 8.56231 20.1771L13.2209 15.679L15.5547 10.648C15.5835 13.12 15.5466 15.7896 15.5548 18.2626L10.8552 22.799C11.7027 23.4457 12.6778 23.9276 13.7076 24.2148C16.2366 24.9311 19.0906 24.3951 21.1707 22.7792L16.5029 18.2626L16.5064 10.6561L18.8368 15.679L23.4557 20.1401C24.0653 19.0494 24.426 17.8239 24.5082 16.582C24.6962 14.0756 23.6991 11.5349 21.8728 9.81369L24.1393 7.18251C25.9912 8.89319 27.2933 11.1947 27.7728 13.6781C28.4251 16.9223 27.6552 20.4207 25.6902 23.0736C23.6715 25.8653 20.406 27.7027 16.9656 27.9589C13.7905 28.2284 10.5383 27.1631 8.1471 25.0719C5.56017 22.8535 4.00244 19.4994 4.00102 16.0849C3.95671 12.7954 5.35696 9.5343 7.74512 7.29093Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyOverwatch = React.memo(BetbyOverwatch);
MemoBetbyOverwatch.displayName = 'BetbyOverwatch';
export default MemoBetbyOverwatch;