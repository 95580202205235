// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbySkijumping: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-skijumping"><g id="Property 1=sport-skijumping"><path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M15.7695 10.4968H10.5726C9.79982 10.4968 9.17334 9.87029 9.17334 9.09749C9.17334 8.3247 9.79982 7.69823 10.5726 7.69823H19.9011C20.6738 7.69823 21.3003 8.3247 21.3003 9.09749C21.3003 9.42976 21.1845 9.73497 20.9911 9.97497C20.9457 10.0434 20.8936 10.1088 20.8348 10.1702L8.38822 23.1777C7.86559 23.7239 6.99914 23.743 6.45296 23.2204C6.44569 23.2134 6.43851 23.2064 6.4314 23.1993L6.40984 23.1777C5.87188 22.6398 5.86231 21.7706 6.38828 21.2209L12.6153 14.7133C12.4649 14.2322 12.5802 13.6861 12.9613 13.305L15.7695 10.4968ZM21.8799 7.18493C21.1513 6.45633 21.1513 5.27504 21.8799 4.54645C22.6085 3.81785 23.7898 3.81785 24.5184 4.54645C25.247 5.27504 25.247 6.45633 24.5184 7.18493C23.7898 7.91353 22.6085 7.91353 21.8799 7.18493ZM7.54984 27.8749C7.10367 28.1325 6.53315 27.9796 6.27555 27.5334C6.01795 27.0872 6.17082 26.5167 6.617 26.2591L24.3996 15.9924C24.7494 15.7959 24.9402 15.0776 24.7358 14.7309C24.4741 14.2871 24.6218 13.7152 25.0656 13.4536C25.5094 13.1919 26.0813 13.3396 26.343 13.7834C27.0769 15.0283 26.5774 16.909 25.3229 17.6136L7.54984 27.8749Z" fill="white" /></g></SvgBase>);
};
const MemoBetbySkijumping = React.memo(BetbySkijumping);
MemoBetbySkijumping.displayName = 'BetbySkijumping';
export default MemoBetbySkijumping;