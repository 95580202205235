// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const CasinoBaccarat: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="19" height="21" viewBox="0 0 19 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-casino-baccarat"><path d="M9.7318 18.7466L2.39964 7.96991L0.21785 11.1771L0.154056 11.2821C-0.156412 11.8509 0.0137072 12.5947 0.562344 12.9885L11.3479 20.7549L11.4458 20.8206C12.0157 21.1575 12.7472 21.0131 13.1172 20.4662L16.4983 15.5L11.6371 19.0048C10.9992 19.4642 10.1486 19.346 9.74031 18.7466H9.7318Z" fill="currentColor" fillOpacity="0.7" /><path d="M18.7828 11.0414L11.6293 0.531615C11.2422 -0.0371919 10.4724 -0.168455 9.91105 0.234085L3.3189 4.98143C2.75751 5.38397 2.61291 6.17593 2.99993 6.74473L10.1535 17.2545C10.5405 17.8233 11.3103 17.9546 11.8717 17.5521L18.4638 12.8047C19.0252 12.4022 19.1698 11.6102 18.7828 11.0414ZM14.1088 8.15362C14.1088 8.15362 14.1088 8.16675 14.1045 8.1755L13.2582 11.2077C13.2029 11.4002 13.0838 11.5621 12.9264 11.6759C12.7691 11.7896 12.5777 11.8509 12.382 11.8377L9.31988 11.619C9.31988 11.619 9.30712 11.619 9.29861 11.619C8.51606 11.5402 7.83558 11.0589 7.48259 10.3326C7.1466 9.6369 7.16361 8.82307 7.52937 8.14925C7.708 7.82109 7.94616 7.54981 8.23962 7.33979C8.68193 7.02039 9.19229 6.88475 9.68564 6.92413C9.82173 6.43408 10.1194 5.98778 10.5618 5.66838C10.8552 5.45836 11.1827 5.31834 11.5442 5.26146C12.2842 5.13895 13.037 5.3971 13.5559 5.96153C14.1002 6.55222 14.3086 7.3748 14.113 8.158L14.1088 8.15362Z" fill="currentColor" fillOpacity="0.7" /></SvgBase>);
};
const MemoCasinoBaccarat = React.memo(CasinoBaccarat);
MemoCasinoBaccarat.displayName = 'CasinoBaccarat';
export default MemoCasinoBaccarat;