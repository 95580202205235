import configs from '@/configs';
import getLanguageSpecificUrl from './getLanguageSpecificUrl';
import qs from 'querystring';

const baseUrl = configs.env.endpoints.domain;

export default function getCanonicalUrl(pathname, lang, keepSearchParams) {
  const path = getLanguageSpecificUrl(pathname, lang);
  const urlObj = new URL(`${baseUrl}${path}`);
  const lastChar = urlObj.pathname.slice(-1);
  const trailingSlash = lastChar === '/' ? '' : '/';

  const search = resolveQueryString(keepSearchParams, urlObj.search);
  const url = `${urlObj.origin}${urlObj.pathname}`.toLowerCase();

  return `${url}${trailingSlash}${search}`;
}

function resolveQueryString(keepSearchParams, search) {
  if (
    !keepSearchParams ||
    (Array.isArray(keepSearchParams) && !keepSearchParams.length)
  ) {
    return '';
  }

  if (keepSearchParams === true) {
    return search;
  }

  const searchParams = qs.parse(search.substr(1));
  const filteredSearchParams = Object.keys(searchParams).reduce((acc, curr) => {
    if (keepSearchParams.includes(curr)) {
      acc[curr] = searchParams[curr];
    }
    return acc;
  }, {});
  const queryString = qs.stringify(filteredSearchParams);

  return queryString ? `?${queryString}` : '';
}
