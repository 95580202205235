// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyFifaCyber: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-fifa-cyber"><g id="Property 1=sport-fifa-cyber"><path id="Vector" d="M30.9827 21.5554H27.9715V17.2941H26.3806V14.8023H27.9715V12.3227C27.9715 11.7383 27.7402 11.5111 27.1598 11.5111H26.5917C26.1331 11.5111 25.8612 11.7667 25.8287 12.2253C25.826 12.2712 25.8269 12.3152 25.8278 12.3599C25.8282 12.3824 25.8287 12.4051 25.8287 12.4282V21.5513H22.7972V11.8763C22.7972 10.2002 23.8361 9.08826 25.5122 9.02332C26.478 8.9868 27.448 8.99492 28.4138 9.0355C29.9032 9.09638 30.9909 10.2408 30.9949 11.7302C31.001 14.144 31.0003 16.5579 30.9995 18.9717C30.9992 19.7761 30.999 20.5805 30.999 21.3849L30.9971 21.404L30.9939 21.4321C30.99 21.4665 30.9855 21.5064 30.9827 21.5554Z" fill="white" /><path id="Vector_2" d="M4.05996 14.0313H7.4527V16.5474H4.06402V21.5554H1V11.856C1 10.1759 2.10792 9.03147 3.79211 9.01118C4.89333 8.99713 5.9926 9.00059 7.09262 9.00404C7.58173 9.00558 8.07098 9.00712 8.56062 9.00712C8.6012 9.00712 8.64179 9.01118 8.69454 9.01929V11.5232H4.91626C4.30346 11.5232 4.0559 11.7708 4.0559 12.3877C4.05996 12.9273 4.05996 13.4633 4.05996 14.0313Z" fill="white" /><path id="Vector_3" d="M14.0921 21.5595H17.1561V16.5556H20.5286V14.0151H17.1399V12.3756C17.1399 11.7871 17.3996 11.5233 17.9921 11.5233H21.7907V9.00717H21.5878C21.0709 9.00717 20.5544 9.00672 20.0381 9.00627C19.0059 9.00537 17.9744 9.00447 16.941 9.00717C16.7543 9.00717 16.5636 9.01935 16.3769 9.05182C14.993 9.29937 14.0921 10.3708 14.0921 11.779V21.5595Z" fill="white" /><path id="Vector_4" d="M9.7984 21.5511H12.8218V9.01904H9.7984V21.5511Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyFifaCyber = React.memo(BetbyFifaCyber);
MemoBetbyFifaCyber.displayName = 'BetbyFifaCyber';
export default MemoBetbyFifaCyber;