import { TIME_MAPS } from '@/constants/common';
import { NextPageContext } from 'next';
import Cookies from 'universal-cookie';
import { addToDate } from './time';
import configs from '@/configs';
import { isLocalDev } from '@/utils/checkRuntime';

interface CookieOptions {
  domain?: string;
  secure?: boolean;
  sameSite?: 'lax' | 'strict' | 'none';
}

export const defaultCookieOptions: CookieOptions = {
  // domain: isLocalDev ? '*' : configs.env.endpoints.domain,
  secure: true,
  sameSite: 'lax',
};

class Cookie {
  cookie: Cookies;

  constructor(...args) {
    this.cookie = new Cookies(...args);
  }

  set(
    key,
    value,
    ttl = 14,
    period = TIME_MAPS.DAY,
    options: CookieOptions = defaultCookieOptions,
  ) {
    this.cookie.set(key, value, {
      ...defaultCookieOptions,
      ...options,
      path: '/',
      expires: addToDate(true, ttl, period),
    });
  }

  get(key) {
    return this.cookie.get(key);
  }

  delete(key) {
    this.set(key, '', -1);
  }

  remove(key) {
    this.cookie.remove(key, { path: '/' });
  }
}

export default Cookie;

export const cookie = new Cookie();

let cookies;

/**
 *
 * @param req
 * @param key
 * @param fallback
 */
export const getCookieFromReq: <T>(
  req: NextPageContext['req'] | undefined,
  key: string,
  fallback?: T,
) => T = (req, key, fallback) => {
  if (req) {
    cookies = new Cookie(req.headers.cookie);
  } else {
    cookies = new Cookie();
  }
  return cookies.get(key) || fallback;
};
