import { Box, Stack } from '@mui/material';
import { useAtom } from 'jotai';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';

import promotionMenuBox from '/public/assets/images/promotion/promotion-menu_box.png';

import GlowBox from '@/components/style/glowBox';
import {
  appSidebarMobileOpenState,
  appSidebarOpenState,
} from '@/state/globalSettings';
import Image from 'next/image';
import LocalizedLink from '@/components/ui/LocalizedLink';

const Wrapper = styled(GlowBox)(({ theme }) => ({
  position: 'relative',
  height: '44px',
  alignItems: 'center',

  [':hover']: {
    background: theme.palette.bg.hover,
  },
}));

const PromotionsBanner = ({ onClick }: { onClick?: () => void }) => {
  const [sidebarOpened] = useAtom(appSidebarOpenState);
  const [_, setSidebarMobileOpenState] = useAtom(appSidebarMobileOpenState);
  const [t] = useTranslation();
  const bannerItems = [
    {
      title: t('Promotions'),
      linkTitle: '/promotions',
      image: promotionMenuBox,
      imageStyle: {
        display: 'flex',
        width: '48px',
        height: 'auto',
      },
      wrapperStyle: {
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingY: sidebarOpened ? 0.5 : 0,
        paddingX: sidebarOpened ? null : 0,
        backgroundImage: `url('/assets/images/promotion/promotion-menu-bg.png')`,
        backgroundSize: 'cover',
      },
    },
  ];
  return (
    <Stack gap={2}>
      {bannerItems.map((item) => {
        return (
          <LocalizedLink
            key={item.linkTitle}
            href={item.linkTitle}
            onClick={() => {
              setTimeout(() => {
                onClick?.();
              });
            }}
          >
            <Wrapper
              direction={'row'}
              sx={{
                border: sidebarOpened ? undefined : 'none',
                boxShadow: sidebarOpened ? undefined : 'none',
                ...(item.wrapperStyle ?? {}),
              }}
            >
              <Box
                sx={{
                  display: sidebarOpened ? 'block' : 'none',
                }}
              >
                {item.title}
              </Box>
              <Box>
                <Image
                  src={item.image}
                  style={item.imageStyle || {}}
                  alt={item.title}
                />
              </Box>
            </Wrapper>
          </LocalizedLink>
        );
      })}
    </Stack>
  );
};

export default PromotionsBanner;
