// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyWot: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-wot"><g id="Property 1=sport-wot"><path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M22.5759 9.09163H17.4742L17.4739 23.7123L15.7929 25.375L14.1116 23.7123V9.09163H9.00962L10.604 7.47293L12.2715 5.78036H19.2697L20.9816 7.47293L22.5759 9.09163ZM20.9232 10.6737H22.5817L22.5811 18.6212L20.0148 21.1589L18.8222 20.0006L17.6414 18.8541L19.3182 17.1973V10.6737H20.9232ZM12.2679 17.1973L13.9441 18.8541L12.764 20.0006L11.5714 21.1589L9.00411 18.6212V10.6737H12.2679V17.1973ZM12.0199 4.88904L7.88987 9.01725V19.0239L15.7708 26.758L23.6515 19.0239V9.01755L19.5216 4.88904H12.0199ZM7.13187 8.51929L11.5245 4.13085L11.6545 4H19.8865L20.0164 4.13085L24.4094 8.51929L24.5412 8.65135V19.3934L24.4077 19.5241L16.0817 27.6951L15.7708 28L15.4596 27.6951L7.13386 19.5242L7 19.3935V8.65143L7.13187 8.51929Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyWot = React.memo(BetbyWot);
MemoBetbyWot.displayName = 'BetbyWot';
export default MemoBetbyWot;