// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonHistory: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-history"><path d="M10.832 2.5C6.69036 2.5 3.33203 5.85833 3.33203 10H0.832031L4.0737 13.2417L4.13203 13.3583L7.4987 10H4.9987C4.9987 6.775 7.60703 4.16667 10.832 4.16667C14.057 4.16667 16.6654 6.775 16.6654 10C16.6654 13.225 14.057 15.8333 10.832 15.8333C9.2237 15.8333 7.76536 15.175 6.71536 14.1167L5.53203 15.3C6.89036 16.6583 8.75703 17.5 10.832 17.5C14.9737 17.5 18.332 14.1417 18.332 10C18.332 5.85833 14.9737 2.5 10.832 2.5ZM9.9987 6.66667V10.8333L13.5654 12.95L14.1654 11.9417L11.2487 10.2083V6.66667H9.9987Z" fill="white" fillOpacity="1" /></SvgBase>);
};
const MemoMoonHistory = React.memo(MoonHistory);
MemoMoonHistory.displayName = 'MoonHistory';
export default MemoMoonHistory;