// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyLeagueoflegends: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-leagueoflegends"><g id="Property 1=sport-leagueoflegends"><path id="Vector" d="M5.21753 4L8.02177 8.53125V23.4512L5.03294 28H22.5536L27.1429 22H15.0599V4H5.21753ZM8.81513 6H13.049V24H23.0857L21.5579 26H8.75032L10.0327 24.0488V7.9668L9.88341 7.72656L8.81513 6ZM17.0708 6.19141V8.23242C21.0945 9.14542 24.1089 12.725 24.1089 17C24.1089 18.052 23.9243 19.061 23.5885 20H25.6975C25.97 19.046 26.1197 18.04 26.1197 17C26.1197 11.618 22.2106 7.13541 17.0708 6.19141ZM6.01089 10.6953C4.9984 12.1283 4.31766 13.807 4.08641 15.625C4.0291 16.075 4 16.534 4 17C4 17.561 4.04228 18.1124 4.12372 18.6504C4.38614 20.3624 5.04968 21.9447 6.01089 23.3047V10.6953Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyLeagueoflegends = React.memo(BetbyLeagueoflegends);
MemoBetbyLeagueoflegends.displayName = 'BetbyLeagueoflegends';
export default MemoBetbyLeagueoflegends;