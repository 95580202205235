import { useAtomValue } from 'jotai';
import { isLoggedIn, userBonusInfoState } from '@/state/userInfo';

const useShouldUseRewardsBanner = () => {
  const { allBonusPacks, loaded } = useAtomValue(userBonusInfoState);
  const isLogin = useAtomValue(isLoggedIn);
  return !isLogin || !loaded
    ? true
    : allBonusPacks.filter((p) => p.leftTimes >= 1).length > 0;
};

export default useShouldUseRewardsBanner;
