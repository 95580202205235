// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyFormula1: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-formula1"><g id="Property 1=sport-formula-1"><path id="Vector" d="M8.84615 19.6923V27.0769C8.84615 27.5867 8.43288 28 7.92308 28C7.41328 28 7 27.5867 7 27.0769V4.92308C7 4.41328 7.41328 4 7.92308 4C8.43288 4 8.84615 4.41328 8.84615 4.92308H24.5385C25.0483 4.92308 25.4615 5.33635 25.4615 5.84615V18.7692C25.4615 19.279 25.0483 19.6923 24.5385 19.6923H8.84615ZM12.5385 6.76923V10.4615H8.84615V14.1538H12.5385V17.8462H23.6154V6.76923H12.5385ZM16.2308 6.76923H19.9231V10.4615H16.2308V6.76923ZM12.5385 10.4615H16.2308V14.1538H12.5385V10.4615ZM19.9231 10.4615H23.6154V14.1538H19.9231V10.4615ZM16.2308 14.1538H19.9231V17.8462H16.2308V14.1538Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyFormula1 = React.memo(BetbyFormula1);
MemoBetbyFormula1.displayName = 'BetbyFormula1';
export default MemoBetbyFormula1;