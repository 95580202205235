// Source: https://gist.github.com/paullewis/55efe5d6f05434a96c36
// References:
//  - https://developers.google.com/web/updates/2015/08/using-requestidlecallback
//  - https://developer.mozilla.org/en-US/docs/Web/API/Background_Tasks_API#Getting_the_most_out_of_idle_callbacks
//    - Use idle callbacks for tasks which don't have high priority.
//    - Avoid making changes to the DOM within your idle callback.

function requestIdleCallbackShim(cb) {
  return setTimeout(function () {
    const start = Date.now();
    cb({
      didTimeout: false,
      timeRemaining: function () {
        return Math.max(0, 50 - (Date.now() - start));
      },
    });
  }, 1);
}

function cancelIdleCallbackShim(id) {
  clearTimeout(id);
}

const useWindow = typeof window === 'object' && 'requestIdleCallback' in window;

const requestIdleCallback = useWindow
  ? window.requestIdleCallback
  : requestIdleCallbackShim;
export default requestIdleCallback;
