// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonMenuCollapse: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-menu-collapse"><g id="menu_collapse" clipPath="url(#clip0_417_97410)"><path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M4.66669 6.49992C4.66669 5.99366 5.0771 5.58325 5.58335 5.58325H6.50002C7.00628 5.58325 7.41669 5.99366 7.41669 6.49992V17.4999C7.41669 18.0062 7.00628 18.4166 6.50002 18.4166H5.58335C5.0771 18.4166 4.66669 18.0062 4.66669 17.4999V6.49992Z" fill="white" /><path id="Vector_2" fillRule="evenodd" clipRule="evenodd" d="M19.3333 11.5417C19.3333 11.0354 18.9229 10.625 18.4167 10.625H12.9167C12.4104 10.625 12 11.0354 12 11.5417V12.4583C12 12.9646 12.4104 13.375 12.9167 13.375H18.4167C18.9229 13.375 19.3333 12.9646 19.3333 12.4583V11.5417Z" fill="white" /><path id="Vector_3" d="M13.9675 7.28238C14.2563 6.99365 14.75 7.19814 14.75 7.60647V16.3934C14.75 16.8017 14.2563 17.0062 13.9675 16.7175L9.57406 12.3241C9.39507 12.1451 9.39507 11.8548 9.57406 11.6758L13.9675 7.28238Z" fill="white" /></g><defs><clipPath id="clip0_417_97410"><rect width="24" height="24" fill="white" /></clipPath></defs></SvgBase>);
};
const MemoMoonMenuCollapse = React.memo(MoonMenuCollapse);
MemoMoonMenuCollapse.displayName = 'MoonMenuCollapse';
export default MemoMoonMenuCollapse;