import baseFetch, { JavaAPIResponse } from '@/helper/http/fetch';
import { CustomAxiosRequestConfig } from '@/helper/http/interceptors/types';
import configs from '@/configs';
import { z } from 'zod';

export const baseURL = configs.env.endpoints.baseAPI;
// export const baseURL = '/';

const javaAPIResponseSchema = z.object({
  status: z.object({
    code: z.string(),
    elapsed: z.string(),
    message: z.string(),
    timestamp: z.string(),
  }),
});

export const requestWithZod = <TData>(
  config: CustomAxiosRequestConfig & {
    schema: z.ZodType<TData>;
  },
): Promise<JavaAPIResponse<TData>> => {
  return baseFetch({
    withAuthHeader: true,
    baseURL,
    timeout: 10e3 * 10,
    ...config,
  }).then(({ data }) => {
    const schemaWrapper = javaAPIResponseSchema.merge(
      z.object({
        data: config.schema,
      }),
    );
    const result = schemaWrapper.safeParse(data);
    if (result.success === true) {
      return result.data;
    } else {
      console.error(result.error);
      console.log(config);
      // toast.error('validation error');
      return data;
    }
  });
};

const request = <T = any>(
  config: CustomAxiosRequestConfig,
): Promise<JavaAPIResponse<T>> => {
  return baseFetch({
    withAuthHeader: true,
    baseURL: `${baseURL}`,
    timeout: 10e3 * 10,
    ...config,
  }).then(({ data }) => data);
};

export default request;
