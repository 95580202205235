// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const CasinoRelease: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-casino-release"><path d="M17.6157 0.0152194L15.4798 0.265044C13.3631 0.509872 11.5025 1.45171 10.0992 2.85073L6.76088 6.19213L4.11274 5.90171L0 10.0082L4.03154 10.5141C5.07456 13.1685 6.86393 15.0172 9.48084 15.9634L9.99298 20.0012L14.0995 15.8884L13.809 13.2403L17.1505 9.90202C18.5501 8.49863 19.4913 6.63807 19.7324 4.56327L19.7361 4.52143L19.986 2.38543C19.9953 2.31048 20.0003 2.22429 20.0003 2.13685C20.0003 0.957058 19.0441 0.000854492 17.8643 0.000854492C17.7769 0.000854492 17.6901 0.00585098 17.6051 0.0164685L17.6157 0.0152194ZM13.8372 8.41557C12.4575 8.41557 11.3389 7.29698 11.3389 5.91732C11.3389 4.53767 12.4575 3.41908 13.8372 3.41908C15.2168 3.41908 16.3354 4.53767 16.3354 5.91732C16.3354 5.92107 16.3354 5.92544 16.3354 5.92981C16.3354 7.30947 15.2168 8.42806 13.8372 8.42806V8.41557Z" fill="currentColor" fillOpacity="0.7" /><path d="M7.36749 17.4839C5.15842 16.7176 3.44962 15.0088 2.69952 12.8521L2.68328 12.7997C2.68328 12.7997 0.0226488 14.2549 0.906403 19.2483C5.89977 20.1289 7.36749 17.4839 7.36749 17.4839Z" fill="currentColor" fillOpacity="0.7" /></SvgBase>);
};
const MemoCasinoRelease = React.memo(CasinoRelease);
MemoCasinoRelease.displayName = 'CasinoRelease';
export default MemoCasinoRelease;