import { z } from 'zod';
import { SGameCategoriesSchema, SGameProvidersSchema } from '@/types/api';
import { numberConvertable } from '@/constants/utils';

export const SliveWInBetsPeriodSchema = z.enum([
  'latest',
  'day',
  'week',
  'month',
]);

export const wsBalanceChangeSchema = z.object({
  balance: z.string().refine(numberConvertable),
  category: z.number(),
  coin: z.string(),
  command: z.number(),
  decimals: z.number(),
  eventType: z.enum([
    'CREATE', // 下注，
    'REFUND', // 资金退还
    'CANCEL', // 下注，
    'SUBMIT', // 下注，
    'ONE', // 下注，
    'DEPOSIT',
    'WITHDRAW',
    'HOUSE_PAY', //资金还返
  ]),
  frozenBalance: z.string().refine(numberConvertable),
  ts: z.string().refine(numberConvertable),
  uid: z.string().refine(numberConvertable),
  version: z.string().refine(numberConvertable),
});

/**
 * public enum BetProviderSourceEnum {
 *     ALL(-1),
 *     SELF(0),
 *     BETBY(1),
 *     HUB88(2);
 *     private Integer source;
 *
 *     BetProviderSourceEnum(Integer source) {
 *         this.source = source;
 *     }
 *
 *     public Integer getSource() {
 *         return source;
 *     }
 * }
 */
export const SGameListItemSchema = z.object({
  name: z.string(),
  thumbUrl: z.string(),
  gameId: z.string(),
  slug: z.string().optional(),
  gameSlug: z.string().optional(),
  gameName: z.string().optional(),
  disabled: z.boolean(),
  source: z
    .union([z.literal(-1), z.literal(1), z.literal(2), z.literal(0)])
    .or(z.string()),
  status: z.number(),
  category: SGameCategoriesSchema,
  product: z.string(),
  provider: SGameProvidersSchema,
  platforms: z.array(z.enum(['GPL_DESKTOP', 'GPL_MOBILE', 'GPL_TABLET'])),
  blockedCountries: z.array(z.string()),
  releaseDate: z.string(),
  demoSupport: z.boolean(),
  enabled: z.boolean(),
});

export const SWithdrawPreCheckSchema = z.object({
  success: z.boolean(),
  message: z.string().optional(),
  code: z
    .union([z.literal('40009'), z.literal('40010')])
    .refine(numberConvertable)
    .optional(),
});

export const SPaginationSchema = <T extends z.ZodType<any>>(schema: T) =>
  z.object({
    currentPage: z.number(),
    pageSize: z.number(),
    total: z.string(),
    records: z.array<T>(schema),
  });

export const SAnchoredData = <T extends z.ZodType<any>>(
  schema: T,
  listName = 'list',
) => {
  return z.object({
    [listName]: z.array(schema),
    prevId: z.string().optional(),
    nextId: z.string().optional(),
  });
};

export type TInferSchemaType<T extends z.ZodType<any>> = z.infer<T>;
