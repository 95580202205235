// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyMotorports: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-motorports"><g id="Property 1=sport-motorsport"><path id="Vector" d="M25.8469 13C24.5709 8.76424 20.6555 5.71429 16 5.71429C10.303 5.71429 5.71429 10.2815 5.71429 16C5.71429 18.1257 6.51352 19.8131 7.73809 20.1384C9.42249 20.5859 15.2485 22.3419 19.6885 23.7546C19.9266 23.8304 20.1802 23.9139 20.495 24.0195C20.6666 24.0771 21.2584 24.2772 21.3229 24.2989C21.5877 24.3881 21.8005 24.459 22.0049 24.5259C23.6248 25.0558 24.6843 25.3058 25.4416 25.2948C26.112 25.2851 26.2857 25.1386 26.2857 24.5714V21.39C23.3616 20.7317 21.9186 20.3652 16.7467 18.9855C14.8542 18.4807 13.8571 17.6168 13.8571 16C13.8571 14.2075 15.0595 13 16.8571 13H25.8469ZM26.0652 13.8571H16.8571C15.5337 13.8571 14.7143 14.6801 14.7143 16C14.7143 17.141 15.4067 17.741 16.9676 18.1573C22.0166 19.5042 23.4981 19.8819 26.2857 20.5113V16C26.2857 15.2642 26.2097 14.5475 26.0652 13.8571ZM19.1687 25.3882C14.753 23.9833 8.94939 22.234 7.29796 21.7953C5.14176 21.2225 4 18.8119 4 16C4 9.33296 9.35805 4 16 4C22.6419 4 28 9.33296 28 16V24.5714C28 26.1529 27.0113 26.9866 25.4664 27.0089C24.4575 27.0235 23.2691 26.7432 21.4719 26.1552C21.262 26.0865 21.0448 26.0142 20.7755 25.9235C20.7094 25.9012 20.119 25.7015 19.9496 25.6447C19.6426 25.5417 19.397 25.4609 19.1687 25.3882Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyMotorports = React.memo(BetbyMotorports);
MemoBetbyMotorports.displayName = 'BetbyMotorports';
export default MemoBetbyMotorports;