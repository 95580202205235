// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const RushStar: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-rush-star"><path d="M10.491 1.66663C5.89102 1.66663 2.16602 5.39996 2.16602 9.99996C2.16602 14.6 5.89102 18.3333 10.491 18.3333C15.0993 18.3333 18.8327 14.6 18.8327 9.99996C18.8327 5.39996 15.0993 1.66663 10.491 1.66663ZM14.0243 15L10.4993 12.875L6.97435 15L7.90768 10.9916L4.79935 8.29996L8.89935 7.94996L10.4993 4.16663L12.0993 7.94163L16.1993 8.29163L13.091 10.9833L14.0243 15Z" fill="white" fillOpacity="0.45" /></SvgBase>);
};
const MemoRushStar = React.memo(RushStar);
MemoRushStar.displayName = 'RushStar';
export default MemoRushStar;