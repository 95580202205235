import { isServer } from '@/configs';
import { useScroll } from 'ahooks';
import { ReactNode, useCallback, useEffect } from 'react';
import { AppToolBar } from './styles';
import { useRouter } from 'next/router';
import { useAtomValue, useSetAtom } from 'jotai/index';
import uiState from '@/state/ui';
import BannerImage2Mobile from '@/components/system/AppBar/HomepageBG/homepage_banner-2_mobile.png';
import BannerImage2Pc from '@/components/system/AppBar/HomepageBG/homepage_banner-2.jpg';
import useMediaQuery from '@mui/material/useMediaQuery';

export interface AppBarToolsProps {
  children?: ReactNode;
}

export const AppBarTools = ({ children }: AppBarToolsProps) => {
  const scrollPosition = useScroll(isServer ? undefined : document);
  const { route } = useRouter();

  const shouldShowBGBanner = route === '/' || route === '/casino';
  const { isHeaderTransparent } = useAtomValue(uiState);
  const set = useSetAtom(uiState);

  const handleHeaderTransparent = useCallback(() => {
    if (shouldShowBGBanner && !scrollPosition?.top) {
      set((prev) => {
        prev.isHeaderTransparent = true;
        return prev;
      });
    } else {
      set((prev) => {
        prev.isHeaderTransparent = false;
        return prev;
      });
    }
  }, [shouldShowBGBanner, scrollPosition]);

  useEffect(() => {
    handleHeaderTransparent();
  }, [handleHeaderTransparent]);

  return (
    <AppToolBar
      sx={{
        background: isHeaderTransparent ? 'transparent' : undefined,
      }}
    >
      {children}
    </AppToolBar>
  );
};

export default AppBarTools;
