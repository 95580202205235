import MoonLogo from '@/components/biz/MoonLogo';
import LocalizedLink from '@/components/ui/LocalizedLink';
import { COOKIE_KEY_APP_SIDE_BAR_OPEN_STATUS } from '@/constants/cookieStorageKeys';
import {
  appSidebarMobileOpenState,
  appSidebarOpenState,
} from '@/state/globalSettings';
import { cookie } from '@/utils/cookies';
import { MoonMenuCollapse, MoonMenuExpand, MoonMLogo } from '@crownbit/icons';
import { NavigateNext, NavigateBefore } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import { SideBarWrapper } from './styles';

export interface AppDrawerHeaderProps {
  isMobile?: boolean;
}

export const AppSideBarDrawerHeader = ({
  isMobile = false,
}: AppDrawerHeaderProps) => {
  const { t } = useTranslation();
  const [sidebarMobileOpenState, setSidebarMobileOpenState] = useAtom(
    appSidebarMobileOpenState,
  );
  const [appSidebarOpen, setAppSidebarOpen] = useAtom(appSidebarOpenState);

  const toggleDrawerOpen = () => {
    setAppSidebarOpen(!appSidebarOpen);
    cookie.set(COOKIE_KEY_APP_SIDE_BAR_OPEN_STATUS, !appSidebarOpen);
  };

  const handleHeaderIconClick = () => {
    !isMobile ? toggleDrawerOpen() : setSidebarMobileOpenState(false);
  };

  return (
    <SideBarWrapper
      sx={{
        justifyContent: 'center',
      }}
    >
      <Box mb={2.5}>
        {(appSidebarOpen && (
          <MoonLogo
            sx={{
              padding: '6px 0',
            }}
          />
        )) || (
          <LocalizedLink
            href={'/casino'}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <MoonMLogo
              width={'30px'}
              fontSize={'30px'}
              style={{
                color: '#E6BC471A',
                fontSize: '30px',
                width: '30px',
                height: '30px',
              }}
            />
          </LocalizedLink>
        )}
      </Box>
    </SideBarWrapper>
  );
};

export default AppSideBarDrawerHeader;
