const scrollBodyToPosition = (element: HTMLElement, position = 0) => {
  const bodyRect = document.body.getBoundingClientRect();
  const elemRect = element.getBoundingClientRect();
  const offset = elemRect.top - bodyRect.top;

  // Scroll to the element with padding
  window.scrollTo({
    top: offset - position,
    behavior: 'smooth', // Optional: define smooth scrolling
  });
};

export default scrollBodyToPosition;
