export const typography = (palette: CustomThemeOptions['palette']) => {
  return {
    fontFamily: [
      'Work Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    subtitle1: {
      fontSize: 16,
    },
    body1: {
      color: '#F3F3FC',
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
    },
    h4: {
      fontSize: 24,
      fontWeight: 500,
    },
    secondary: {
      fontSize: 12,
      color: palette.text.secondary,
    },
  };
};

export default typography;
