// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyOlympics: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-olympics"><g id="Property 1=sport-olympics"><path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M16.9714 7.19966C18.0041 6.91584 18.9014 6.26095 19.4912 5.36038C20.1084 7.72408 18.6939 10.1665 16.3321 10.8155L15.8745 10.9413L15.0395 11.1707C14.0068 11.4545 13.1098 12.1094 12.5197 13.01C11.9027 10.6464 13.3171 8.20408 15.6788 7.55489L16.9714 7.19966ZM16.9607 2.82952C17.9934 2.5457 18.8905 1.89086 19.4805 0.990234C20.0975 3.35386 18.6832 5.79614 16.3214 6.44533L15.8627 6.57136L15.0288 6.80056C13.9961 7.08438 13.0988 7.73906 12.509 8.63964C11.8919 6.27601 13.3063 3.83373 15.668 3.18467L16.9607 2.82952ZM20.9396 16.1767H11.0588L20.9412 16.1763V17.588H20.9396V16.1767ZM10 15.1175H22V18.6469H19.9973L12.0031 18.6472L10 18.6469V15.1175ZM12.3208 20.0589H19.6796L17.059 31.7059H14.9413L12.3208 20.0589Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyOlympics = React.memo(BetbyOlympics);
MemoBetbyOlympics.displayName = 'BetbyOlympics';
export default MemoBetbyOlympics;