import { Components, Theme } from '@mui/material/styles';

export default function Misc(theme: Theme): Components<Theme> {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: theme.typography.pxToRem(14),
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 1501,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: theme.palette.bg.main,
          background:
            'var(--soft-gold, linear-gradient(180deg, #FED74D 0%, #EEA433 100%))',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: theme.spacing(1),
          border: '1px solid transparent',
          paddingInlineStart: theme.spacing(2),
        },
      },
      variants: [
        {
          props: { severity: 'warning' },
          style: {
            borderColor: '#D1C902',
            background: 'rgba(209, 201, 2, 0.10)',
          },
        },
      ],
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: 'transparent',
          boxShadow: 'none',
          ['&::before']: {
            content: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: 'auto',
          ['&.Mui-expanded']: {
            minHeight: 'auto',
          },
        },
        content: {
          margin: 0,
          ['&.Mui-expanded']: {
            margin: 0,
            minHeight: 'auto',
          },
        },
      },
    },

    // MuiTable: {
    //   styleOverrides: {
    //     root: {
    //       background: theme.palette.grey[980],
    //       ['th']: {
    //         fontWeight: theme.typography.fontWeightBold,
    //       },
    //     },
    //   },
    // },
    // MuiTableCell: {
    //   styleOverrides: {
    //     root: {
    //       background: theme.palette.grey[980],
    //     },
    //   },
    // },
    MuiPagination: {
      defaultProps: {},
      styleOverrides: {
        root: {
          '& .MuiPagination-ul': {
            justifyContent: 'flex-start',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          ['::placeholder']: {
            color: theme.palette.grey[400],
          },
          [':hover, :focus-within']: {
            border: '1px solid var(--border-color)',
          },
        },
      },
    },
    MuiSwitch: {},
    MuiTableHead: {
      styleOverrides: {
        root: {
          ['th']: {
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: theme.typography.pxToRem(12),
            backgroundColor: `${theme.palette.bg.main} !important`,
            color: theme.palette.text.help,
            textTransform: 'uppercase',
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.text.white,
            padding: theme.spacing(1.5, 2),
          },
          '& .MuiTableRow-root:last-child td': {
            borderBottom: 0,
          },
          // '& .MuiTableRow-root:last-child': {
          //   borderRadius: 8,
          // },
        },
      },
    },
    // MuiTableRow: {
    //   styleOverrides: {
    //     root: {
    //       ['&:hover']: {
    //         background: theme.palette.grey[900],
    //       },
    //     },
    //   },
    // },
    // MuiSelect: {
    //   styleOverrides: {
    //     root: {
    //       ['& .MuiSelect-select']: {
    //         paddingTop: '9px',
    //         paddingBottom: '9px',
    //       },
    //     },
    //   },
    // },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          fontSize: theme.typography.pxToRem(14),
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: `${theme.palette.grey[700]} !important`,
          },
        },
        popper: {},
        root: {
          '& .MuiInputBase-root': {
            paddingLeft: theme.spacing(2),
            border: theme.palette.border.normal,
            paddingTop: '3px',
            paddingBottom: '3px',
            height: '48px',
            ['input']: {
              height: 30,
              padding: '0px !important',
              fontSize: theme.typography.pxToRem(14),
            },
            ['fieldset']: {
              borderColor: 'transparent',
            },
            '&:hover': {
              ['fieldset']: {
                borderColor: theme.palette.primary.main,
              },
            },
          },
          '& .MuiInputBase-root.Mui-disabled': {
            ['fieldset']: {
              borderColor: 'transparent',
            },
          },
        },
      },
    },
  };
}
