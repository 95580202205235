// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const MoonWinResult: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="33" height="32" viewBox="0 0 33 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-moon-win-result"><path d="M19.0953 13.332L17.5144 16.2319L14.6594 14.8055C14.4868 14.718 14.2832 14.667 14.0676 14.667C13.5854 14.667 13.1626 14.923 12.9285 15.306L12.925 15.312L8.92288 21.9783C8.80682 22.1718 8.73828 22.4057 8.73828 22.6552C8.73828 23.1427 8.99992 23.5687 9.39063 23.8017L9.39663 23.8052C9.58623 23.9242 9.81635 23.9957 10.063 23.9987C10.5482 23.9932 10.9715 23.7292 11.2006 23.3382L11.2041 23.3317L14.5724 17.7254L17.4739 19.1718C17.653 19.2673 17.8656 19.3233 18.0912 19.3233C18.579 19.3233 19.0052 19.0619 19.2384 18.6714L19.2419 18.6654L21.4365 14.6655C20.494 14.495 19.6846 14.0155 19.0998 13.3375L19.0953 13.3325V13.332Z" fill="white" /><path d="M24.7421 10.6664C24.7421 12.1394 23.5475 13.3328 22.0742 13.3328C20.6009 13.3328 19.4062 12.1389 19.4062 10.6664C19.4062 9.19346 20.6009 8 22.0742 8C23.5475 8 24.7421 9.19396 24.7421 10.6664Z" fill="white" /></SvgBase>);
};
const MemoMoonWinResult = React.memo(MoonWinResult);
MemoMoonWinResult.displayName = 'MoonWinResult';
export default MemoMoonWinResult;