import { getUserZenDeskToken } from '@/apis/login';
import { fetchGeoInfo } from '@/helper/fetchGeoInfo';
import useUpdateUserInfoAsync, {
  UPDATE_USER_INFO_MODE,
} from '@/hooks/biz/useUpdateUserInfoAsync';
import { isLoggedIn, updateUserInfoMeta } from '@/state/userInfo';
import { getUserInfoFromLocal } from '@/utils/localStoreHelper';
import { useAtom, useAtomValue } from 'jotai/index';
import { useEffect } from 'react';
import { setGtagUserId } from '@/utils/googleTagManager';

/**
 * handler for user login callbacks & auto user login (auto login)
 */
const useUserLoginCallbackHandler = () => {
  const [userInfo, setUserInfo] = useAtom(updateUserInfoMeta);

  useEffect(() => {
    const localUserInfo = getUserInfoFromLocal();
    if (!localUserInfo) return;
    setUserInfo({
      userMetaData: localUserInfo,
      isUserLoading: false,
    });
  }, []);

  const [, updateUserInfo] = useUpdateUserInfoAsync();
  const isLogin = useAtomValue(isLoggedIn);
  useEffect(() => {
    if (!isLogin) return;
    updateUserInfo([
      UPDATE_USER_INFO_MODE.DETAIL_USER_INFO,
      UPDATE_USER_INFO_MODE.WALLET,
      // UPDATE_USER_INFO_MODE.BONUS_PENDING_CLAIM,
    ]);

    if (userInfo?.userMetaData?.uid) {
      setGtagUserId(userInfo?.userMetaData?.uid || '');
    }

    /**
     * fetch & set zen desk token
     */
    getUserZenDeskToken().then((res) => {
      const { token } = res.data;
      if (token) {
        setTimeout(() => {
          if (
            typeof window !== 'undefined' &&
            typeof window?.zE !== 'undefined'
          ) {
            window?.zE(
              'messenger',
              'loginUser',
              (callback: (token: string) => void) => {
                callback(token);
              },
            );
          }
        }, 1000);
      }
    });
  }, [isLogin]);
};

export default useUserLoginCallbackHandler;
