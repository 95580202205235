import Button from './Button';
import ButtonGroup from './ButtonGroup';
import Pagination from './Pagination';
import Popover from './Popover';
import Tab from './Tab';
import Tabs from './Tabs';
import Tooltip from './Tooltip';
import ToggleButton from './ToggleButton';
import ToggleButtonGroup from './ToggleButtonGroup';
import Misc from './Misc';

export default function componentsOverrides(theme) {
  return Object.assign(
    Button(theme),
    ButtonGroup(theme),
    ToggleButton(theme),
    ToggleButtonGroup(theme),
    Tab(theme),
    Tabs(theme),
    Pagination(theme),
    Tooltip(theme),
    Popover(theme),
    Misc(theme),
  );
}
