import { TGameListItem } from '@/apis/hub88';
import BlockedCountryModal from '@/components/biz/BlockedCountryModal';
import AppCoreContainer from '@/components/system/AppCoreContainer';
import InitializeTelegramMiniApp from '@/components/telegram/InitializeTelegramMiniApp';
import { ConfirmConfig } from '@/components/ui/ConfirmModal/config';
import { THEME_MODE_ENU } from '@/constants/global';
import GlobalConfigContextWrapper from '@/contexts';
import busEvents from '@/helper/busEvents';
import {
  renderMetaCanonicalTags,
  renderMetaDescription,
  renderMetaTitle,
} from '@/helper/seo';
import AmplitudeHeader from '@/helper/seo/AmplitudeHeader';
import setZendeskSettings from '@/helper/setZendeskSettings';
import TmaSDKLoader from '@/helper/telegram/TmaSDKLoader';
import useAppMounted from '@/hooks/biz/useAppMounted';
import useUserLoginLogout from '@/hooks/biz/useUserLoginLogout';
import useNProgress from '@/hooks/ui/useNProgress';
import useVConsole from '@/hooks/useVConsole';
import { betbyBtRenderURL } from '@/pages/sports';
import {
  appGameList,
  appSidebarOpenState,
  globalModals,
} from '@/state/globalSettings';
import { deviceInfoAtom } from '@/state/globalSettings/deviceInfo';
import { store } from '@/state/store';
import GlobalStyle from '@/theme/globalStyle';
import { isProduction, isTelegramMiniApp } from '@/utils/checkRuntime';
import { DeviceInfo } from '@/utils/deviceType';
import gtm from '@/utils/googleTagManager';
import { getStaticAsset } from '@/utils/staticAsset';
import CssBaseline from '@mui/material/CssBaseline';
import * as Sentry from '@sentry/nextjs';
import { useMount } from 'ahooks';
import { Provider, useAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { ConfirmProvider } from 'material-ui-confirm';
import { UserConfig, appWithTranslation, useTranslation } from 'next-i18next';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import 'nprogress/nprogress.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import i18nConfig from '../next-i18next.config';
import './styles.css';

const ControlInviteTgModal = dynamic(
  () => import('@/components/system/InviteTgModal'),
  { ssr: false },
);
const BreadcrumbNav = dynamic(() => import('@/components/ui/BreadcrumbNav'), {
  ssr: false,
});
const BetsBox = dynamic(() => import('@/components/features/common/Bets'), {
  ssr: false,
});

const MediaQueryVisual = dynamic(
  () => import('@/components/ui/Devtools/MediaQueryVisual'),
  { ssr: false },
);

// import {
//   APP_SIDE_BAR_OPEN_STATUS,
//   THEME_MODE,
// } from '@/constants/cookieStorageKeys';

export interface MyAppProps extends AppProps {
  themeMode?: THEME_MODE_ENU;
  appSidebarOpened?: boolean;
  deviceInfo?: DeviceInfo;
  gameList?: TGameListItem[];
  // userInfo?: IUserInfo;
}

const SsrHydrator = ({ hydrateData, children }) => {
  useHydrateAtoms(hydrateData, {
    store: store,
  });
  return <>{children}</>;
};
// export const runtime = 'experimental-edge';

const AppMounted = () => {
  useAppMounted();
  return <></>;
};

const CrownBitApp = (
  props: // userInfo,
  MyAppProps,
) => {
  const {
    Component,
    pageProps,
    themeMode = THEME_MODE_ENU.Dark,
    appSidebarOpened = true,
    deviceInfo = {
      isMobile: false,
      isDesktop: true,
      isTablet: false,
    },
  } = props;

  // const localUserInfo = getUserInfoFromLocal();

  const { pathname, asPath, locale, push } = useRouter();
  const helmetContext = {};
  const { t } = useTranslation();
  const isMobileCasinoPage = pathname?.includes('/casino/mobile-play');
  const { logout } = useUserLoginLogout();

  useMount(() => {
    busEvents.on('userLogout', () => {
      logout();
      push('/');
    });

    if (isTelegramMiniApp) {
      logout();
    }
  });

  // only show bets box in casino page
  const showBetsBox = pathname?.includes('/casino');
  const notLayout =
    pathname?.includes('/thirdLoginCallback') || isMobileCasinoPage;
  useNProgress();

  useVConsole(deviceInfo?.isMobile);

  const AppBox = () => {
    return notLayout ? (
      <Component {...pageProps} />
    ) : (
      <AppCoreContainer appSidebarOpened={appSidebarOpened}>
        {!isTelegramMiniApp && <BreadcrumbNav />}
        <Component {...pageProps} />
        {showBetsBox && <BetsBox />}
        {/*<ControlInviteTgModal />*/}
        <GlobalStyle />
      </AppCoreContainer>
    );
  };

  const { gameList = [] } = pageProps;

  const hydrateData = [
    [deviceInfoAtom, deviceInfo],
    [appSidebarOpenState, Boolean(appSidebarOpened)],
    gameList.length
      ? [appGameList, { loading: false, list: gameList ?? [] }]
      : undefined,
  ].filter(Boolean);

  const AppCoreComponent = () => {
    return (
      <Sentry.ErrorBoundary
        fallback={({ error, componentStack, resetError }) => (
          <div>
            <h1>{t('An error occurred')}</h1>
            <pre>{error?.message}</pre>
            <pre>{componentStack}</pre>
            <button onClick={resetError}>Reset</button>
          </div>
        )}
        onError={(err) => Sentry.captureException(err)}
      >
        <GlobalConfigContextWrapper themeMode={themeMode}>
          <ConfirmProvider defaultOptions={ConfirmConfig}>
            <CssBaseline enableColorScheme />
            <BlockedCountryModal />
            {!isProduction && <MediaQueryVisual />}
            <AppMounted />
            {isTelegramMiniApp ? (
              <InitializeTelegramMiniApp>{AppBox()}</InitializeTelegramMiniApp>
            ) : (
              AppBox()
            )}
          </ConfirmProvider>
        </GlobalConfigContextWrapper>
      </Sentry.ErrorBoundary>
    );
  };

  const getHelmet = () => {
    return (
      <Helmet>
        <meta
          key="meta:viewport"
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, shrink-to-fit=no, viewport-fit=cover"
        />
        {renderMetaTitle(t('The Highest Rewarding Crypto Online Casino'))}
        {renderMetaDescription(
          t(
            'MoonClub Casino rewards money back on every bet, offers up to 25% referral commission, and is available to play via Telegram. Get started with a 125% bonus!',
          ),
        )}
        {renderMetaCanonicalTags({
          pathname: asPath,
          lang: locale,
        })}
        <meta
          key="meta:og:image"
          property="og:image"
          content={getStaticAsset('/assets/images/logo/m-logo-with-bg.svg')}
        />
        <meta
          key="meta:og:image:type"
          property="og:image:type"
          content="image/svg"
        />
        <meta
          key="meta:og:image:width"
          property="og:image:width"
          content="600"
        />
        <meta
          key="meta:og:image:height"
          property="og:image:height"
          content="315"
        />
        <meta property="og:title" content="Moonclub" />
        <meta property="og:site_name" content="Moonclub" />
        {/*<meta*/}
        {/*  property="og:description"*/}
        {/*  content="Experience the future of online gambling with our blockchain casino. Play casino games like slots, poker, and roulette using cryptocurrency and enjoy secure, transparent gaming"*/}
        {/*/>*/}
        <meta property="og:type" content="website" />
        <meta
          key="meta:twitter:card"
          name="twitter:card"
          content="summary_large_image"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="64x64"
          href={getStaticAsset('/assets/images/logo/m-logo-with-bg.svg')}
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin={'anonymous'}
        />
      </Helmet>
    );
  };

  return (
    <>
      <Provider store={store}>
        <SsrHydrator hydrateData={hydrateData}>
          <HelmetProvider context={helmetContext}>
            {getHelmet()}
            <AmplitudeHeader />
            <Script src={betbyBtRenderURL} strategy={'lazyOnload'} />
            {/*Start of moonclub Zendesk Widget script*/}
            <Script
              id="ze-snippet"
              src="https://static.zdassets.com/ekr/snippet.js?key=7429574d-8a72-46f1-ada2-264cad91148b"
              strategy="lazyOnload"
              onLoad={() => {
                setZendeskSettings();
              }}
            />
            <Script id="analytics-script" strategy="afterInteractive">
              {`
        !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdp-eu.customer.io/v1/analytics-js/snippet/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._writeKey=key;analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.15.3"; analytics.load("d500e24253869b296fd6"); analytics.page(); }}();
      `}
            </Script>
            {/* End of moonclub Zendesk Widget script */}
            <link rel="preconnect" href="//www.googletagmanager.com" />
            {gtm.isEnabled && (
              <script dangerouslySetInnerHTML={{ __html: gtm.getInitCode() }} />
            )}
            {isTelegramMiniApp ? (
              <TmaSDKLoader>{AppCoreComponent()}</TmaSDKLoader>
            ) : (
              AppCoreComponent()
            )}
          </HelmetProvider>
        </SsrHydrator>
      </Provider>
    </>
  );
};

// Will disabled Automatic Static Optimization:: https://github.com/vercel/next.js/discussions/13199
// CrownBitApp.getInitialProps = async ({ ctx, router }: AppContext) => {
//   const env = isServer ? process.env : {};
//   const { req } = ctx as NextPageContext;

//   /**
//    * image 404s should be handled in custom server.js
//    * workaround for now
//    */
//   if (ctx.asPath.endsWith('.png') || ctx.asPath.endsWith('.jpg')) {
//     return {
//       notFound: true,
//     };
//   }

//   const deviceInfo = getDeviceTypeFromReq(
//     req ? req.headers?.['user-agent'] : navigator?.userAgent,
//   );

//   const cookies = new Cookie(req ? req.headers.cookie : document.cookie);
//   const appSidebarOpened =
//     cookies.get(COOKIE_KEY_APP_SIDE_BAR_OPEN_STATUS) || true;
//   let themeMode = cookies.get(COOKIE_KEY_THEME_MODE);

//   const gameList = isServer ? await getGameList(!!router.query['prod-list'])
//     .then((res) => res.data.games)
//     .catch((err) => {
//       Sentry.captureMessage('Failed_To_Fetch_Game_List', {
//         extra: {
//           error: JSON.stringify(err),
//         },
//       });
//       return [];
//     }) : [];

//   if (typeof themeMode === 'undefined') {
//     cookies.set(COOKIE_KEY_THEME_MODE, THEME_MODE_ENU.Dark);
//     themeMode = THEME_MODE_ENU.Dark;
//   }

//   // const userInfo = cookies.get(COOKIE_KEY_USER_INFO) || null;
//   return {
//     deviceInfo,
//     appSidebarOpened,
//     themeMode,
//     env,
//     gameList,
//     // userInfo: userInfo,
//   };
// };

// @ts-ignore
export default appWithTranslation(CrownBitApp, i18nConfig as UserConfig);
