// generate by script, don't edit it.
import React from 'react';
import SvgBase from '../SvgBase';
import { IProps } from '../SvgBase/types';
export const BetbyDefaultPlaceholder: React.FC<IProps> = (props: IProps) => {
    const { sx: propSx = {}, ...restProps } = props;
    return (<SvgBase  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" sx={propSx}  {...restProps} iconName="i-betby-default-placeholder"><g id="Property 1=sport-default-placeholder"><path id="Vector" d="M9 23C9 22.4477 9.44772 22 10 22H22C22.5523 22 23 22.4477 23 23C23 23.5523 22.5523 24 22 24H10C9.44772 24 9 23.5523 9 23Z" fill="white" /><path id="Vector_2" d="M9 26C9 25.4477 9.44772 25 10 25H22C22.5523 25 23 25.4477 23 26C23 26.5523 22.5523 27 22 27H10C9.44772 27 9 26.5523 9 26Z" fill="white" /><path id="Vector_3" d="M11 22C11 20.8954 11.8954 20 13 20H19C20.1046 20 21 20.8954 21 22H11Z" fill="white" /><path id="Vector_4" fillRule="evenodd" clipRule="evenodd" d="M21 7V6H11V7H7.33333C7.14924 7 7 7.14924 7 7.33333V8.28571C7 11.1871 9.16233 13.5834 11.9634 13.9511C12.5571 14.7618 13.389 15.387 14.3562 15.7235C14.9021 17.1128 14.7833 18.6944 14 20H18C17.2167 18.6944 17.0979 17.1128 17.6438 15.7235C18.611 15.387 19.443 14.7618 20.0366 13.9511C22.8377 13.5834 25 11.1871 25 8.28571V7.33333C25 7.14924 24.8508 7 24.6667 7H21ZM20.6685 12.7939C20.8826 12.2371 21 11.6323 21 11V8H24V8.28571C24 10.4082 22.5974 12.203 20.6685 12.7939ZM8 8H11V11C11 11.6323 11.1174 12.2371 11.3315 12.7939C9.4026 12.203 8 10.4082 8 8.28571V8Z" fill="white" /></g></SvgBase>);
};
const MemoBetbyDefaultPlaceholder = React.memo(BetbyDefaultPlaceholder);
MemoBetbyDefaultPlaceholder.displayName = 'BetbyDefaultPlaceholder';
export default MemoBetbyDefaultPlaceholder;