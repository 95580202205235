import { useCallback, useEffect, useRef, useState } from 'react';
import { getBetbyJWTToken } from '@/apis/betby';
import initializeBTRenderer from '@/helper/betby/initialize';
import keysFactory from '@/reactQueryKeys/keysFactory';
import { GLOBAL_MODALS, globalModals } from '@/state/globalSettings';
import { userInfoMetaData } from '@/state/userInfo';
import getI18nProps from '@/utils/i18/getI18nProps';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useAtom, useAtomValue } from 'jotai';
import { isRelease } from '@/utils/checkRuntime';
import { Box } from '@mui/material';
import CatchError from '@/components/system/CacthError';
import { isMobile } from 'react-device-detect';

/* eslint-disable-next-line */
export interface SportsPageProps {
  jwtToken: string;
}

const StyledSportsPage = styled(Box)`
  min-height: 100vh;
`;

export const betbyBtRenderURL = isRelease
  ? 'https://moonclub.sptpub.com/bt-renderer.min.js'
  : 'https://ui.invisiblesport.com/bt-renderer.min.js';
// export const runtime = 'experimental-edge';

export function SportsPage(props: SportsPageProps) {
  const { jwtToken } = props;
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const { locale = 'en', pathname, query, reload } = useRouter();

  const { userMetaData } = useAtomValue(userInfoMetaData);
  const btRenderRef = useRef(null);
  const [modals, setModals] = useAtom(globalModals);

  const handleLogin = () => {
    setModals({
      [GLOBAL_MODALS.LOGIN]: true,
    });
  };

  const handleRegister = () => {
    setModals({
      [GLOBAL_MODALS.REGISTER]: true,
    });
  };

  const handleOnRecharge = () => {
    setModals({
      [GLOBAL_MODALS.WALLET]: true,
    });
  };

  const { data, refetch } = useQuery(
    [keysFactory.sports.queryBetJwtToken(), userMetaData?.name, locale],
    () =>
      getBetbyJWTToken({
        update: true,
      }),
    {
      enabled: !!userMetaData?.name && scriptLoaded,
      keepPreviousData: false,
      cacheTime: 0,
      onSuccess: (data) => {
        renderBTRenderer({ token: data.data?.token, locale: locale });
      },
    },
  );

  const renderBTRenderer = useCallback(
    ({
      token,
      locale: locale,
      killBTRenderer = true,
    }: {
      token?: string;
      locale?: string;
      killBTRenderer?: boolean;
    }) => {
      if (typeof window === 'undefined') return;
      if (!window?.BTRenderer) return;

      if (btRenderRef?.current && killBTRenderer) {
        btRenderRef?.current?.kill();
        btRenderRef.current = null;
      }

      btRenderRef.current = initializeBTRenderer({
        onTokenExpired: () =>
          getBetbyJWTToken().then((data) => data.data?.token),
        onSessionRefresh: () => reload(),
        onRecharge: () => handleOnRecharge(),
        jwtToken: token,
        locale: locale,
        onLogin: handleLogin,
        onRegister: handleRegister,
        isMobile: isMobile,
      });
    },
    [scriptLoaded, userMetaData?.name, locale, reload],
  );

  // update betBy router page
  useEffect(() => {
    const btPath = query['bt-path'] || '/';
    btRenderRef?.current?.updateOptions({
      url: `${btPath}`,
    });
  }, [query]);

  useEffect(() => {
    if (!scriptLoaded) return;
    if (!userMetaData?.name) {
      renderBTRenderer({ locale });
    }
  }, [userMetaData?.name, renderBTRenderer, scriptLoaded, locale]);

  useEffect(() => {
    if (window?.BTRenderer) {
      setScriptLoaded(true);
    }

    return () => {
      if (btRenderRef?.current) {
        btRenderRef?.current?.kill();
        btRenderRef.current = null;
      }
    };
  }, []);

  return (
    <>
      <Script
        src={betbyBtRenderURL}
        strategy={'beforeInteractive'}
        onLoad={() => {
          setScriptLoaded(true);
        }}
        onError={(e) => {
          console.error(`Failed to load script ${betbyBtRenderURL}`, e);
        }}
      />
      <StyledSportsPage>
        <div id={'betby'} />
      </StyledSportsPage>
    </>
  );
}

export async function getServerSideProps({ locale, res, req }) {
  return {
    props: {
      ...(await getI18nProps({ locale })),
    },
  };
}

export default CatchError(SportsPage);
